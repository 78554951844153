<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card no-body>
      <template v-slot:header>
        <b-container fuild style="max-width: 2560px;" >
          <b-row no-gutters align-h="between">
            <b-col class="pt-2">
              {{t('Search for a given device by its Account ID, Device ID, Product ID or Name')}}.
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body style="padding: 0px;">
        <paginated-table :rows='rows'
                         :columns='realColumns'
                         :actions="actions"
                         :search="search_options"
                         v-model="search_field_value"
                         :itemsPerPage="query.limit"
                         :updating="updating"
                         :searchRestrictions = "{'device_id' : '^[a-zA-Z0-9\-\._@]+$', 'product_id' : '^[a-zA-Z0-9\-\._@]+$'}"
                         :serverpagination="search_pagination" @prev="handlePrevPage" @next="handleNextPage" @pagination="HandlePagination"
                         @item-edit="handleEdit" @item-remove="handleRemove"/>
      </b-card-body>
      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="reload" v-if="!updating" style="cursor: pointer;display: inline;">
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t('Update now') }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin"/>
          {{ t('Updating...') }}
        </div>
      </template>
    </b-card>
  </div>
</template>
<script>
import { PaginatedTable } from '@/components';
import { AvatarImageFormatter, NameWithIconFormatter, NameWithIconifyFormatter } from '@/components/Tables/Formatters'
import {getDeviceInfo, getProductId} from "@/util/devicesUtils";
import ProductIdFormatter from '../../components/Tables/Formatters/ProductIdFormatter.vue';
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";
import _ from 'lodash';

export default {
    name: 'devices',
    components: {
        PaginatedTable,
        PageTitleCard
    },
    data() {
        return {
            updating: false,
            devices: new Map(),
            query: {
                sort: 'asc',
                limit: 10
            },
            self_query: {},
            currentPage: 1,
            nextPage: 0,
            columns: [
                {
                    prop: 'account_id',
                    label: this.t('Account'),
                    minWidth: 175
                },
                {
                    prop: 'device_id',
                    label: this.t('Device ID'),
                    minWidth: 175
                },
                {
                    prop: 'namewithicon',
                    label: this.t('Name'),
                    minWidth: 200,
                    formatter: NameWithIconifyFormatter
                },
                {
                    prop: 'product',
                    label: this.t('Product'),
                    minWidth: 125,
                    formatter: NameWithIconFormatter
                },
                {
                    prop: 'product_id',
                    label: this.t('Product ID'),
                    minWidth: 125,
                    formatter: ProductIdFormatter
                },
                // {
                //     prop: 'user',
                //     label: '',
                //     width: 75,
                //     formatter: AvatarImageFormatter
                // },
                // {
                //     prop: 'username',
                //     label: 'User',
                //     minWidth: 125,
                // },
            ],
            actions: {
                minWidth: 100,
                label: this.t('Actions'),
                fixed: 'right',
                items: [
                    {type: 'warning', icon: 'now-ui-icons business_badge', event: 'item-edit', action: this.t('Edit')},
                    {type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'item-remove', action: this.t('Remove'), confirm: true, visible_for_permissions: [{actions: ["DELETE"], path: "/devices/.*"}]},
                ]
            },
            search_options: [
                {value: 'account_id', key: 'account_id', label: this.t('Account ID')},
                {value: 'device_id', key: 'device_id', label: this.t('Device ID')},
                {value: 'ns_id', key: 'product_id', label: this.t('Product ID'), isExtended: true,
                    extendedOptions: [
                        {value: 'ns_id', key: 'ns_id', label: this.t('Networksecure ID')},
                        {value: 'subscriber_id', key: 'dns_id', label: this.t('DNS Secure ID')},
                        {value: 'mac_address', key: 'mac', label: this.t('Router Secure ID: MAC')},
                        // HIDE FOR VERSION 30.1
                        // {value: 'serial_number', key: 'sn', label: this.t('Router Secure ID: SN')}
                    ]
                },
                {value: 'name', key: 'name',      label: this.t('Name')}
            ]
        }
    },
    mounted() {
        this.refresh(0)
    },
    computed: {
        realColumns: {
            get(){
                if (this.$store.getters['settings/layout'] == 'desktop'){
                    return this.columns
                }
                else if ( this.$store.getters['settings/layout'] == 'tablet'){
                    return [ this.columns[0], this.columns[2], this.columns[3]]
                }
                else{
                    return [ this.columns[0], this.columns[2]]
                }
            }
        },
        rows(){
            let ret = [];
            let _vm = this
            this.devices.forEach( (value, key) => {
                if(_.get(value, 'product_data.CBEndpoint.cancelled')) {
                    return;
                }

                const deviceConnectivity = value.product_data.EndpointSecure
                  && value.product_data.EndpointSecure.hasOwnProperty('device_connectivity')
                    ? value.product_data.EndpointSecure.device_connectivity
                    : true;

                const deviceDescription = deviceConnectivity
                  ? value.description
                  : `${value.description} (${this.t('UNREACHABLE')})`;

                let item_product = {}
                let product = null
                try{
                    product = Object.entries(value.product_data).filter(element => element[1].provisioned)[0][0]
                    item_product = {
                        name: product,
                        svgicon: _vm.productIconClass(product),
                        size: '34',
                        tooltip: null
                    }
                }
                catch(e){
                    product = null
                    item_product = {}
                }


                const iconType = getDeviceInfo(value.type);
                let item = {
                    row_id: value.device_id,
                    device_id: value.device_id,
                    account_id: value.account_id,
                    name: value.description,
                    namewithicon: {
                        name: deviceDescription,
                        icon: iconType.icon,
                        svgiconoriginal: _vm.deviceIsOriginalIcon(value.type),
                        tooltip: null,
                        iconColor: deviceConnectivity ? '#4cb18e' : '#ccc',
                        textColor: deviceConnectivity ? '' : '#ccc',
                    },
                    product: item_product,
                    product_id: getProductId(value, product)
                }
                ret.push(item)
            })
            let arRet = this.search_pagination
                ? ret
                : Array.from(ret).sort( (a,b) => {
                    try{
                        return a.name.toUpperCase() > b.name.toUpperCase() ? 1: -1
                    }
                    catch(err){
                        return -1
                    }
                })
            return arRet;
        },
        search_field_value: {
            get(){
                if( this.query && this.query.field_value)
                    return { field: 'device_id', value: this.query.field_value}
                else
                    return  { field: 'device_id', value: ''}
            },
            set(newValue){
                this.handleSearch(newValue)
            }
        },
        search_pagination: {
            get(){
                return { prev: !!this.query && !!this.query.prev_id,
                        next: !!this.query && !!this.query.next_id,
                        currentPage: this.currentPage }
            }
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },

        handleEdit(data){
            this.$router.push( {path: '/account_management/accounts/edit/' + encodeURIComponent(data.row.account_id) + '/devices/edit/' + encodeURIComponent(data.row.device_id)})
        },
        handleRemove(data){            
            const dev = this.devices.get(data.row.device_id).product_data
            if(  dev.hasOwnProperty("RouterSecure") && dev.RouterSecure.data.hasOwnProperty("merge_info") && dev.RouterSecure.data.merge_info.merge_state == "group" )
                this.$bvModal.msgBoxConfirm(this.$t('grouped_device_delete_message'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel'),
                    title: this.$t('Remove'),
                    hideHeaderClose: false
                })
                .then(confirmed => {
                    if( confirmed )                       
                        this.handleRemoveOK(data)                        
                })
            else
                this.handleRemoveOK(data)            
        },
        handleRemoveOK(data){           
            this.$store.dispatch('accountManagement/devices/removeDevice', {account_id: data.row.account_id, id: data.row.device_id} )
            .then (res => {
                this.devices = new Map(res);
                this.reload()
            })
            .catch( err => {})
        },
        handlePrevPage(){
            if (this.query){
                ['next_id', 'next_name'].forEach( item => {
                    if ( this.query[item])
                    delete this.query[item]
                });
                if ( this.query.prev_id){
                    this.nextPage = -1
                    this.refresh()
                }
            }
        },
        handleNextPage(){
            if (this.query){
                ['prev_id', 'prev_name'].forEach( item => {
                    if ( this.query[item])
                    delete this.query[item]
                });
                if ( this.query.next_id){
                    this.nextPage = 1
                    this.refresh()
                }
            }
        },
        HandlePagination(value){
            this.currentPage = 1;
            this.self_query.limit = value;
            ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                if ( this.self_query[item])
                   delete this.self_query[item]
            });
            this.reload()
        },
        handleSearch: _.debounce(function (value) {
            this.currentPage = 1
            this.nextPage = 0;
            if ( value){
                this.query.field_operator = 'sw'
                this.query.field_name = value.field
                if ( value.field == 'msisdn')
                    this.query.field_name = 'product_id'
                else if ( value.field == 'name')
                    this.query.field_name = 'description'
                this.query.field_value = value.value;
            }
            else{
                ['field_operator', 'field_name', 'field_value'].forEach( item => {
                        if ( this.query[item])
                            delete this.query[item]
                    });
            };
            ['next_id', 'next_name', 'prev_id', 'prev_name'].forEach( item => {
                if ( this.query[item])
                  delete this.query[item]
            });
            this.refresh();
        }, 500),
        reload(){
            if (this.self_query){
                this.query = this.self_query
                this.nextPage = 0;
                this.refresh()
            }
        },
        refresh(wait){
            let _vm = this
            this.updating = true;
            _vm.devices = []
            this.$store.dispatch('accountManagement/devices/getDevices', { wait: wait, query: this.query})
            .then( res => {
                _vm.devices = new Map(res.items)
                _vm.query = res.query
                _vm.self_query = res.self
                this.currentPage += this.nextPage
                this.updating = false
            })
            .catch( err => {
                this.updating = false
            });
        },
        getImageembedded(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'embedded')
                return 'data:image/' + imgData.format + ';base64,' + imgData.picture_base64
            else
                return null
        },
        getImageurl(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'external')
                return imgData.picture_url
            else
                return null
        },
        getImage(imgData){
            let img = this.getImageembedded(imgData)
            if ( img)
                return img;
            else
                return this.getImageurl(imgData)
        },
        deviceIsOriginalIcon(type){
            // const noorig = ['pc', 'mobile']
            // return !noorig.includes(type)
            return false;
        },
        productIconClass (type) {
            switch (type){
                case 'NetworkSecure': return 'item-networksecure';
                case 'RouterSecure': return 'item-homesecure';
                default: return 'device-other';
            }
        },
    }
}
</script>

