export const getUserImage = (imgData) => {
  let img = getUserImageEmbedded(imgData);
  if (img) return img;
  else return getUserImageUrl(imgData);
}

const getUserImageEmbedded = (imgData) => {
  if (!imgData) return null;
  if (imgData.storage_method == "embedded")
    return (
      "data:image/" + imgData.format + ";base64," + imgData.picture_base64
    );
  else return null;
}

const getUserImageUrl = (imgData) => {
  if (!imgData) return null;
  if (imgData.storage_method == "external") return imgData.picture_url;
  else return null;
}

