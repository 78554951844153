<template>
    <b-card no-body>
        <template v-slot:header>
            <slot name="header">
                <h5 class="title"><i class="now-ui-icons location_compass-05 mr-2"/>{{ t('Location') }}</h5>
                <b-container fuild style="max-width: 2560px;" >
                <b-row no-gutters align-h="between">
                    <!-- <b-col md="12" lg="8" xl="9" class="pt-2">
                        Devices are securized by the settings of the user they belong to.
                    </b-col> -->
                    <b-col sm=""></b-col>
                </b-row>
                </b-container>
            </slot>
        </template>

        <b-card-body >
            <devices-map :devices="[devicelocation]" :selected="devicelocation" v-if="location" style="max-height: 300px;" ref="device_map"/>
            <empty-state v-else />
        </b-card-body>
        
        <template v-slot:footer>
            <b-container fuild>
                <b-row no-gutters>
                    <b-col class="ml-auto" cols="4">
                        <n-button v-if="location" type="primary" @click.native="center" round block :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                {{ t('Center device') }}
                        </n-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>

    </b-card>    
</template>

<script>
import { DevicesMap, EmptyState } from '@/components';

export default {
    name: 'endpointsecure-info',
    components : {
        [DevicesMap.name] : DevicesMap,
        [EmptyState.name] : EmptyState
    },
    props: {
        value: { 
            type: Object,  
            required: true
        },
    },
    data() {
        return {
            device: null,
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( newValue){
                this.device = newValue;
            }
        },
        devicelocation(newValue, oldValue){
            if (newValue)
                setTimeout(() => {
                    this.center();
                }, 500);
        }
    },
    mounted () {
        if ( !!this.value){
            this.device = this.value;
        }
    },
    computed: {
        location: {
            get(){
                try{
                    let r = Object.keys(this.device.product_data.EndpointSecure.location).length
                    return r > 0
                }catch(err){
                    return false
                }
            }
        },
        devicelocation: {
            get () {
                try{
                    let loc = this.device.product_data.EndpointSecure.location

                    let ret = { location: {
                                    lat: loc.latitude,
                                    lng: loc.longitude,
                                    timestamp: loc.timestamp
                                },
                                type: this.device.type,
                                description: this.device.description
                    }
                    return ret
                }catch(err){
                    return {}
                }
            }
        }
    },
    methods: {
        center(){
            try{
                this.$refs.device_map.refresh();            
            }
            catch(err){}
        }
    }
}
</script>