<template>
    <b-card class="card-user" >
        <slot name="header">
            <h5 slot="header" class="title">Authentication</h5>
        </slot>
        <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
            <div style="margin: auto; width: 150px;">
                <avatar  :fullname="name" :image="image" usewords shine-on-hover zoom-on-hover :size="124" />
                <svgicon v-if="user.default_user" class="overlay-icon big icon icon-info" icon="star" width="48" height="48" />
            </div>
        </div>

        <form @submit.prevent="save" >
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                <b-row align-h="center" >
                    <b-col class="mb-2" >
                        <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                            <b-row align-h="start" >
                                <b-col xl="4" lg="12">
                                    <fg-input v-model="user.name"
                                                id="name"
                                                :label="this.t('Full Name')"
                                                name="name"
                                                :placeholder="this.t('Full Name')"
                                                autocapitalize="yes"
                                                v-validate="'required|min:1|max:256'"
                                                :error="getError('name')">
                                    </fg-input>
                                </b-col>

                                <b-col xl="4" lg="12" class="mb-2">
                                    <div class="form-group has-label mb-0"><label>{{t('Birthday')}}</label></div>
                                    <el-date-picker
                                        v-model="user.user_metadata.birthdate"
                                        :clearable="false"
                                        editable
                                        v-validate="{ required: cb_provided }"
                                        value-format="yyyy[-]MM[-]dd[T]HH:mm:ss[Z]"
                                        type="date"
                                        :pickerOptions="pickerOptions"
                                        name="birthday"
                                        id="birthday"
                                        :placeholder="t('Birthday')">
                                    </el-date-picker>
                                    <div class="text-danger invalid-feedback mt-2" style="display: block; white-space: normal; line-height: normal;">
                                       {{ getError('birthday') }}
                                    </div>
                                </b-col>

                                <b-col xl="4" lg="12">
                                    <div class="form-group has-label mb-0"><label>{{t('Profile')}}</label></div>
                                    <el-select class="select-primary"
                                        size="large"
                                        :placeholder="this.t('Profile')"
                                        name="profile"
                                        v-validate="'required'"
                                        :error="getError('profile')"
                                        v-model="user.profile.profile_definition_id">
                                        <el-option v-for="(option, key) in Array.from(profiles)"
                                            class="select-primary"
                                            :label="getProfileLabel(option)"
                                            :value="option[0]"
                                            :key="key">
                                        </el-option>
                                    </el-select>
                                    <div class="text-danger invalid-feedback mt-2" style="display: block; white-space: normal; line-height: normal;">
                                       {{getError('profile')}}
                                    </div>
                                </b-col>

                                <b-col xl="5" lg="6" class="mb-2">
                                    <div class="form-group has-label"><label>{{t('Preferred Language')}}</label></div>
                                    <el-select class="select-primary"
                                        size="large"
                                        placeholder="Language"
                                        v-model="user.user_metadata.language_id"
                                        style="margin-top: -10px;">
                                        <el-option v-for="option in $options.languages"
                                            class="select-primary"
                                            :value="option.key"
                                            :label="option.value"
                                            :key="option.key">
                                        </el-option>
                                    </el-select>
                                </b-col>
                                <b-col v-if='false' xl="7" lg="12" class="mb-2">
                                    <div class="form-group has-label"><label>{{t('Preferred Time Zone')}}</label></div>
                                    <el-select class="select-primary"
                                        size="large"
                                        placeholder="Time Zone"
                                        v-model="user.user_metadata.time_zone"
                                        style="margin-top: -10px;">
                                        <template v-for="option in $options.timezones" >
                                            <el-option v-for="item in option.utc"
                                                class="select-primary"
                                                :value="item"
                                                :label="option.text + ' - ' + item"
                                                :key="item.key">
                                            </el-option>
                                        </template>
                                    </el-select>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" xs="12" sm="6" class="ml-auto">
                        <n-button :disabled="!saveEnabled" native-type="submit" type="primary" id="btn_submit" round block :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/users"}]'>
                            <template v-if="newUser">
                                {{ t('Create') }}
                            </template>
                            <template v-else>
                                {{ t('Save') }}
                            </template>
                        </n-button>
                    </b-col>
                </b-row>
            </b-container>
        </form>

        <template v-slot:footer v-if="!newUser">
            <div class="hr-grey mb-2"/>
            <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin"/>
                {{ t('Updating...') }}
            </div>
        </template>
    </b-card>
</template>

<script>
import languages_list from '../Managers/languages.json'
import timezones_list from '../Managers/timezones.json'

import { Select, Option, DatePicker } from 'element-ui';
//import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import _ from 'lodash'

export default {
    name: 'user-form',
    components: {
        [DatePicker.name]: DatePicker,
        [Option.name]: Option,
        [Select.name]: Select,
    },
    languages: languages_list,
    timezones: timezones_list,
    props: {
        value: {
            type: Object,
        },
        saveEnabled:{
            type:Boolean,
        },
        profiles:{
            type: Map,
            required: true
        },
        defaultprofile: {
            type: String,
            default: ''
        },
        account_id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            user: null,
            newUser: true,
            updating: false,
             pickerOptions: {
                 disabledDate(date) {
                 return date > new Date() ||   date < new Date('1900-1-1');
             }
      },
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( newValue){
                this.user = newValue;
                if ( this.user.user_id)
                    this.newUser = false
            }
        },
        defaultprofile(newValue){
            if ( this.newUser)
                this.user.profile.profile_definition_id = newValue
        }
    },
    created() {
        this.user = { profile: {profile_definition_id: this.defaultprofile, profile_configuration: {}},
                        user_metadata: { language_id: 'en-US', time_zone: 'America/New_York',
                            picture: { format: 'no_picture', storage_method : 'no_picture'}
                        }
            }

    },  
    mounted () {
        if ( !!this.value){
            this.user = this.value;
            if ( this.user.user_id)
                this.newuser = false
        }
        else{
            this.user = { profile: {profile_definition_id: this.defaultprofile, profile_configuration: {}},
                        user_metadata: { language_id: 'en-US', time_zone: 'America/New_York',
                            picture: { format: 'no_picture', storage_method : 'no_picture'}
                        }
            }
        }
    },
    computed: {
        //...mapState('settings',['products']),
        ...mapGetters('accountManagement/accounts',['account']),

        cb_provided() {
            //return this.products.includes('CBEndpoint');
            try {
                return this.account(this.account_id).products.CBEndpoint.provisioned
            } catch (error) {
                return false;
            }
        },
        name() {
            return _.get(this, 'user.name', '');
        },
        image(){
            if ( this.user && this.user.user_metadata && this.user.user_metadata.picture){
                let imgData = this.user.user_metadata.picture
                let img = this.getImageembedded(imgData)
                if ( img)
                    return img;
                else
                    return this.getImageurl(imgData)
            }
            else{
                return null
            }
        },
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save(){
            let isValid = await this.$validator.validateAll();
            if (isValid){
                this.$emit('input', this.user)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        },
        refresh(wait){
            this.updating = true;
            this.$emit('refresh')
            setTimeout( () => {
			    this.updating = false
		    }, 2000);
        },
        getImageembedded(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'embedded')
                return 'data:image/' + imgData.format + ';base64,' + imgData.picture_base64
            else
                return null
        },
        getImageurl(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'external')
                return imgData.picture_url
            else
                return null
        },
        getProfileLabel(option) {
            return option[1].profile_i18n.language_id.slice(0,(option[1].profile_i18n.language_id.indexOf('-'))) == this.$store.getters['settings/getLanguage'] ? option[1].profile_i18n.profile_name : option[0];    
        }

    }

}
</script>
