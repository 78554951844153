<template>  
    <b-card no-body style="margin-bottom:0px">
        <template v-slot:header>
            <b-container fuild style="max-width: 2560px;" >
            <b-row no-gutters align-h="between">
                <b-col md="12" lg="8" xl="9" class="pt-2">
                    <div class="form-group has-label">
                        <label>{{t('RouterSecure ID')}}</label>
                        <span style="padding-top:15x" v-if='errorMessage' class="errorMessage">{{t(errorMessage)}}</span>
                    </div>                
                </b-col>
                <b-col sm=""></b-col>
            </b-row>
           
            </b-container>
        </template>

        <form @submit.prevent="" >
            <b-card-body style="padding-left:40px">
                <b-row v-if="availableInActualVersion">
                    <b-col  cols="6">
                        <div class="form-group has-label"><label>{{t('Serial Number')}}</label></div>
                        <fg-input v-model="itemlist.serial_number"
                                    id="serial_number"
                                    ref="serial_number"
                                    name="serial_number"
                                    :placeholder="t('Serial Number')"
                                    autocapitalize="yes"
                                    v-validate="'max:256'"
                                    :error="getError('serial_number')"
                                    @change="emitchange">
                        </fg-input>
                    </b-col>   
                </b-row>
                <b-row>               
                    <b-col sm="12">
                        <div class="form-group has-label"><label>{{t('MAC address')}}</label></div>                
                        <autocomplete-tag-input
                            name="mac_address"
                            inputType="mac"                   
                            :value='itemlist.mac_address'
                            @change="handleChange($event)"                                         
                            :placeholder="t('MAC address')"
                            :showdropdown=false                                
                        ></autocomplete-tag-input>
                        <p v-if='errorMessageMAC' class="errorMessage pl-0" >{{ t(errorMessageMAC) }}</p>
                    </b-col>              
                </b-row>  
                <div class="mt-2">
                    <h5 v-if="availableInActualVersion"><i>{{ t('one_or_more_id') }}</i></h5>                  
                    <h5 v-else><i>{{ t('one_id') }}</i></h5>   
                </div>    
            </b-card-body>    
        </form >  
    </b-card>
</template>

<script>
import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';

export default {
    name: 'ids-list',      
    components: {        
        'autocomplete-tag-input': AutocompleteTagInput 
    },    
    props: {        
        value: {
            type: Object,
            default: () => { return {} }
        }        
    },
    data() {
        return {  
            errorMessage: undefined,
            errorMessageMAC: undefined,
            itemlist : {
                mac_address : [],
                // serial_number : ''
            },
            // To disable things that are not available in version **30.1**
            availableInActualVersion: false
        }
    },     
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },              
        handleChange(event) {   
           this.itemlist.mac_address = event
           this.emitchange()
        },
        emitchange(){
            this.$emit('input', this.itemlist);            
            
            // HIDE FOR VERSION 30.1
            // if( this.itemlist.serial_number.trim() == "" )
            //     this.errorMessage = "bad_ids"                  
            // else if( this.itemlist.mac_address.length <= 0 )    
            if( this.itemlist.mac_address.length <= 0 )    
                this.errorMessageMAC = "required_field"
            else{
                this.errorMessage = undefined
                this.errorMessageMAC = undefined
            }
        },
        mounted(){            
        },
        async validateAll(){
            // HIDE FOR VERSION 30.1
            // if( this.itemlist.serial_number.trim() == "" ){
            //     this.errorMessage = "bad_ids"                  
            //     return false;
            // } else if( this.itemlist.mac_address.length <= 0 ){
            if( this.itemlist.mac_address.length <= 0 ){
                this.errorMessageMAC = "required_field"
                return false;
            } else{
                this.errorMessage = undefined
                this.errorMessageMAC = undefined
            }

            this.errorMessage = undefined
            this.errorMessageMAC = undefined
            let valid = await this.$validator.validateAll();
            return valid;
        }
    },
    watch: {
        value(newValue){
            if(newValue) 
                this.itemlist = newValue             
        },        
    },
   
}
</script>

<style lang="scss" >
.el-input-group{
    background-color: transparent;
}
</style>
