<template>
    <b-card class="card-user" >
        <slot name="header">
            <h5 slot="header" class="title">{{t('Device Info')}}</h5>
        </slot>
        <div class="author">
            <div class="block block-one"></div>
            <div class="block block-two"></div>
        </div>
        <form @submit.prevent="save" >
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                <b-row align-h="center" >
                    <b-col class="col-12 mb-2">
                        <fg-input v-model="device.description"
                                    id="name"
                                    :label="this.t('Device Name')"
                                    name="name"
                                    :placeholder="this.t('Device Name')"
                                    autocapitalize="yes"
                                    v-validate="'required|min:4'"
                                    :error="getError('name')">
                        </fg-input>
                    </b-col>
                    <b-col class="col-6 mb-3">
                        <div class="form-group has-label"><label>{{t('Product')}}</label></div>
                        <el-select class="select-primary mb-3"
                            @change="onChangeProduct()"
                            name="Product"
                            size="large"
                            :placeholder="this.t('Product')"
                            v-model="productName"
                            :disabled="!newDevice"
                            v-validate="'required'"
                            :error="getError('Product')"
                            style="margin-top: -10px;">
                            <el-option v-for="product in productList"
                                class="select-primary"
                                :value="product"
                                :label="product"                                
                                :key="product">
                            </el-option>
                        </el-select>
                        <p class="errorMessage pl-0" v-if="getError('Product')">{{ getError('Product') }}</p>
                        <fg-input v-if="isNetworkSecureNewDevice"
                            class="mt-2"
                            v-model="ns_id"
                            id="ns_id"
                            :label="this.t('NetworkSecure ID')"
                            name="NetworkSecureID"
                            :placeholder="this.t('NetworkSecure ID')"
                            key="network-secure-id-input"
                            v-validate="modelValidations.networkSecureIdInput"
                            :error="getError('NetworkSecureID')">
                        </fg-input>
                    </b-col>
                    <b-col class="col-6 mb-3">
                        <div class="form-group has-label"><label>{{t('User')}}</label></div>
                        <el-select class="select-primary"
                            size="large"
                            placeholder="User"
                            v-model="user_id"
                            v-validate="'required'"
                            :error="getError('User')"
                            style="margin-top: -10px;">
                            <template slot="prefix" v-if="user_id">
                                <avatar  :fullname="getUserName(user_id)" :image="getUserImage(user_id)" 
                                            usewords shine-on-hover zoom-on-hover :size="24" class="mr-2 prefix"/>      
                            </template>
                            <el-option v-for="user in arrayusers"
                                class="select-primary"
                                :value="user.user_id"
                                :label="'       ' + user.name"
                                :key="user.user_id">
                            </el-option>
                        </el-select>
                    </b-col>
                    <b-col class="col-6 mb-3" v-if="isDNSecureDevice">
                        <b-row>
                            <b-col>
                                <div class="form-group has-label"><label>{{t('DNSecureIdOrIp')}}</label></div>
                                <el-select class="select-primary"
                                    name="DNSecureIdOrIp"
                                    size="large"
                                    :placeholder="this.t('DNSecureIdOrIp')"
                                    v-model="dnsIdOrIp"
                                    v-validate="'required'"
                                    style="margin-top: -10px;">
                                    <el-option v-for="option in dnsIdOrIpList"
                                        class="select-primary"
                                        :value="option"
                                        :label="t(option)"                                
                                        :key="option">
                                    </el-option>
                                </el-select>
                            </b-col>
                        </b-row>    
                        <b-row v-if="this.dnsIdOrIp == 'dns_ip'">
                            <b-col lg="4" md="4" sm="12" class="ml-auto mt-2">
                                <n-button @click.native="handleNewIp"
                                        type="primary"
                                        size="md" round block
                                        :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                    <i slot="label"><svgicon class="icon" icon="icon-new" /></i>
                                    {{t('New IP')}}
                                </n-button>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="col-6 mb-3" v-if="isDNSecureDevice">
                        <div v-if="this.dnsIdOrIp == 'dns_id'">
                            <fg-input v-model="dns_id"
                                        id="dns_id"
                                        :label="this.t('DNSSecure ID')"
                                        name="DNSSecureID"
                                        :placeholder="this.t('DNSecure ID')"
                                        v-validate="modelValidations.dnSecureIdInput"
                                        key="dns-secure-id-input"
                                        :error="getError('DNSSecureID')">
                                    </fg-input>
                        </div>
                        <div v-else-if="dnsIdOrIp == 'dns_ip'">
                            <dnsecure-info v-model="this.device" ref="dnsSecureInfo" @ipChange="handleIpChange"/>
                        </div>
                    </b-col>

                    <b-col class="col-6 mb-3">
                        <div class="form-group has-label"><label>{{t('Type of device')}}</label>
                        </div>
                        <el-select class="select-primary select-filterable-icons"                            
                            size="large"
                            :placeholder="this.t('Type of device')"
                            v-model="devicetype"
                            value-key="key"
                            style="margin-top: -10px;"
                            clearable
                            filterable
                            :disabled="!newDevice && productName === 'RouterSecure' && devicetype && devicetype.key === 'mng_router'"
                            @change="deviceTypeChange">
                            <template slot="prefix" v-if="devicetype && devicetype.icon || devicetype && devicetype.iconify">
                                <svgicon v-if="devicetype.icon" :original="devicetype.original" class="mr-2 prefix device-form-icon icon" 
                                         :icon="devicetype.icon" 
                                         width="24" height="24" style="fill: none;width: 24px; height:24px;"/>
                                <iconify v-if="devicetype.iconify && showIconify" ref="deviceType" :icon="devicetype.iconify" selected />
                            </template>
                            <el-option-group v-for="group in devicetypes"
                                :key="group.label"
                                :label="t(group.label)">
                                <el-option v-for="option in group.options"
                                    class="select-primary"
                                    :value="option"
                                    :label="t(option.name)"
                                    :disabled="option.hasOwnProperty('disabled') && option.disabled === productName"
                                    :key="option.key"
                                >
                                    <svgicon v-if="option.icon" :original="option.original" class="device-form-icon icon" 
                                        :icon="option.icon" width="30" height="30" />
                                    <iconify v-else-if="option.iconify" :icon="option.iconify" />
                                    <span class="device-name-with-icon">{{ t(option.name) }}</span>
                                </el-option>
                            </el-option-group>
                        </el-select>
                        <div  class="pt-2" v-if="productName === 'RouterSecure' && devicetype && devicetype.key != 'mng_router'">
                            <label class="ml-2">{{t('Reset device type to')}}<a class="ml-1 link-without-href" @click="setDefault('type')">{{ t('automatic router detection')}} </a></label>
                        </div>   
                    </b-col>
                    <b-col class="col-6 mb-3">
                        <div class="form-group has-label">
                            <label>{{t('Platform')}}</label>                            
                        </div>
                        <el-select class="select-primary"
                            size="large"
                            :placeholder="this.t('Platform')"
                            v-model="deviceplatform"
                            value-key="key"
                            style="margin-top: -10px;">
                            <template slot="prefix" v-if="deviceplatform.icon">
                                <div class="mt-1">
                                    <i class="isp-icon device-form-icon icon mr-2 prefix" :class="deviceplatform.icon"></i>  
                                </div>
                            </template>
                            <el-option v-for="option in deviceplatforms"
                                class="select-primary"
                                :value="option"
                                :label="'       ' + t(option.name)"
                                :key="option.key">
                            </el-option>
                        </el-select>
                        <div class="pt-2" v-if="productName === 'RouterSecure' && devicetype && devicetype.key != 'mng_router'">
                            <label class="ml-2">{{t('Reset device platform to')}}<a class="ml-1 link-without-href" @click="setDefault('platform')">{{ t('automatic router detection')}} </a></label>
                        </div>
                    </b-col>

                    
                </b-row>
                <b-row align-h="center"  v-if="productName === 'RouterSecure' && this.newDevice && devicetype && devicetype.key == 'mng_router'"  >
                    <b-col xl="10" lg="12">                        
                        <ids-list v-model="id_list" ref="validateIDs" />
                    </b-col>
                </b-row>   

                <b-row align-h="center"  v-if="productName === 'RouterSecure' && this.newDevice && devicetype && devicetype.key == 'mng_router'" >
                    <b-col xl="10" lg="12">                        
                        <attributes-list v-model="attr_list" />
                    </b-col>
                </b-row>   

                <b-row>
                    <b-col lg="2" md="3" sm="12" class="ml-auto mt-2">
                        <n-button :disabled="!saveEnabled" native-type="submit" type="primary" id="btn_submit" round block :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                            <template v-if="newDevice">
                                {{ t('Create') }}
                            </template>
                            <template v-else>
                                {{ t('Save') }}
                            </template>
                        </n-button>
                    </b-col>
                </b-row>

            </b-container>
        </form>
    </b-card>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Iconify } from "@/components";
import AttributesList from './components/AttributesList.vue';
import IdsList from './components/IdsList.vue';
import DNSecureInfo from './DNSecureInfo'

 const DEVICE_ROUTER = { key: 'mng_router', iconify: 'mdi:router-wireless', name: 'Manageable Router', original: true }
 const DEVICE_OTHER =  { key: "other", iconify: "tabler:devices", name: "Other", original: true}

export default {
    name: 'device-form',
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        Iconify,
        [IdsList.name] : IdsList,
        AttributesList,
        [DNSecureInfo.name] : DNSecureInfo  
    },
    props: {
        value: { 
            type: Object,  
        },
         saveEnabled:{
            type:Boolean,
        },
        users: {
            type: Map,
            default: () => new Map()
        },
        products: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            devicetypes: [
                {   label: 'IOT Devices',
                    options: [
                        { key: 'health_monitor', iconify: 'healthicons:blood-pressure-outline', name: 'Health Monitor', original: true},
                        { key: 'ipcam', iconify: 'fluent:camera-dome-20-regular', name: 'IP Camera', original: true},
                        { key: 'ip_phone', iconify: 'carbon:phone-ip', name: 'IP Phone', original: true},
                        { key: 'pos', iconify: 'fontisto:shopping-pos-machine', name: 'Point of Sale', original: true},
                        { key: 'sensor', iconify: 'ic:sharp-sensors', name: 'Sensor', original: true},
                        { key: 'weather', iconify: 'fluent:weather-partly-cloudy-day-20-regular', name: 'Weather', original: true}
                    ]
                },
                {   label: 'Multimedia',
                    options: [
                        { key: 'audio', iconify: 'oi:audio-spectrum', name: 'Audio', original: true},
                        { key: 'av', iconify: 'mdi:theater', name: 'AV', original: true},
                        { key: 'mediacenter', iconify: 'fluent:device-meeting-room-remote-16-regular', name: 'Media Center', original: true},
                        { key: 'media_player', iconify: 'mdi:disc-player', name: 'Media Player', original: true},
                        { key: 'mic', iconify: 'mdi:microphone', name: 'Microphone', original: true},
                        { key: 'radio', iconify: 'mdi:radio', name: 'Radio', original: true},
                        { key: 'scanner', iconify: 'ic:round-scanner', name: 'Scanner', original: true},
                        { key: 'speaker', iconify: 'fluent:speaker-1-28-regular', name: 'Speaker', original: true},
                        { key: 'streaming', iconify: 'fluent:video-clip-20-filled', name: 'Streaming', original: true},
                    ]
                },
                {   label: 'Personal Devices',
                    options: [
                        { key: 'ebook_reader', iconify: 'fluent:immersive-reader-24-regular', name: 'Ebook Reader', original: true},
                        { key: 'console', iconify: 'la:gamepad', name: 'Game Console', original: true},
                        { key: 'laptop', iconify: 'ic:baseline-laptop-mac', name: 'Laptop', original: true},
                        { key: 'pc', iconify: 'mono-icons:computer', name: 'PC', original: true},
                        { key: 'printer', iconify: 'ph:printer', name: 'Printer', original: true},
                        { key: 'projector', iconify: 'ph:projector-screen', name: 'Projector', original: true},
                        { key: 'mobile', iconify: 'zmdi:smartphone-iphone', name: 'Smartphone', original: true},
                        { key: 'watch', iconify: 'mdi:watch', name: 'Smart watch', original: true},
                        { key: 'tablet', iconify: 'mdi:tablet-android', name: 'Tablet', original: true},
                        { key: 'television', iconify: 'zmdi:tv', name: 'Television', original: true},
                    ]
                },
                {   label: 'Smart Appliances',
                    options: [
                        { key: 'alarm', iconify: 'ic:outline-alarm-on', name: 'Alarm', original: true},
                        { key: 'baby_monitor', iconify: 'mdi:baby-face-outline', name: 'Baby Monitor', original: true},
                        { key: 'doorbell', iconify: 'mdi:doorbell', name: 'Doorbell', original: true},
                        { key: 'electric', iconify: 'icons8:electrical', name: 'Electric', original: true},
                        { key: 'fire_alarm', iconify: 'ant-design:fire-outlined', name: 'Fire Alarm', original: true},
                        { key: 'light_bulb', iconify: 'teenyicons:bulb-off-outline', name: 'Light Bulb', original: true},
                        { key: 'scale', iconify: 'ion:scale-sharp', name: 'Scale', original: true},
                        { key: 'sleep_tech', iconify: 'mdi:chat-sleep-outline', name: 'Sleep Tech', original: true},
                        { key: 'smart_appliance', iconify: 'ic:outline-widgets', name: 'Smart Appliance', original: true},
                        { key: 'smart_cleaner', iconify: 'mdi:robot-vacuum', name: 'Smart Cleaner', original: true},
                        { key: 'smart_fridge', iconify: 'mdi:fridge-outline', name: 'Smart Fridge', original: true},
                        { key: 'smart_lock', iconify: 'fluent:lock-closed-24-regular', name: 'Smart Lock', original: true},
                        { key: 'smart_washer', iconify: 'mdi:dishwasher', name: 'Smart Washer', original: true},
                        { key: 'sprinkler', iconify: 'mdi:sprinkler-variant', name: 'Sprinkler', original: true},
                        { key: 'touch_panel', iconify: 'fluent:panel-bottom-contract-20-filled', name: 'Touch Panel', original: true},
                        { key: 'raspberry', iconify: 'la:raspberry-pi', name: 'Raspberry Pi', original: true},
                        { key: 'toy', iconify: 'whh:teddybear', name: 'Toy', original: true},
                    ]
                },
                {   label: 'WiFi & Networking',
                    options: [
                        { key: 'cable_box', iconify: 'iconoir:cable-rounded', name: 'Cable Box', original: true},
                        { key: 'circuit_board', iconify: 'mdi:raspberry-pi', name: 'Circuit Board', original: true},
                        { key: 'controller', iconify: 'teenyicons:game-controller-retro-outline', name: 'Controller', original: true},
                        { key: 'gateway', iconify: 'fluent:router-24-regular', name: 'Gateway', original: true},                        
                        { key: 'modem', iconify: 'whh:routeralt', name: 'Modem', original: true},
                        { key: 'nas', iconify: 'mdi:nas', name: 'NAS', original: true},
                        { key: 'network', iconify: 'iconoir:network-alt', name: 'Network', original: true},
                        { key: 'router', iconify: 'tabler:router', name: 'Router', original: true },
                        { key: 'switch', iconify: 'clarity:network-switch-line', name: 'Switch', original: true},
                    ]
                },
                {   label: 'Others',
                    options: [
                        { key: 'remote', iconify: 'mdi:remote-tv', name: 'Remote', original: true},
                        { key: 'robot', iconify: 'mdi:robot-excited-outline', name: 'Robot', original: true},
                        { key: 'other', iconify: 'tabler:devices', name: 'Other', original: true}
                    ]
                },
            ],
            devicesRoutertypes : [
                {   label: 'WiFi & Networking',
                    options: [                        
                        { key: 'mng_router', iconify: 'mdi:router-wireless', name: 'Manageable Router', original: true },                        
                    ]
                }
            ],        

            deviceplatforms: [
                { key: 'android', name: 'Android', icon: 'android'},
                { key: 'ios', name: 'iOS', icon: 'appleinc'},
                { key: 'macos', name: 'MacOS', icon: 'appleinc'},
                { key: 'windows', name: 'Windows', icon: 'windows8'},
                //{ key: 'linux', name: 'Unix/Linux', icon: 'tux'},
                { key: 'other', name: 'Other'},
            ],
            modelValidations: {
              networkSecureIdInput: {
                required: this.isNetworkSecureNewDevice,
                max: 128,
                regex: '^[a-zA-Z0-9-._@/ ]+$'
              },
              dnSecureIdInput: {
				required: this.isDNSecureDevice,
                max: 128,
                regex: '^[a-zA-Z0-9\-\._@\+:]+$'
              }
            },
            device: null,
            newDevice: true,
            updating: false,
            productName: null,
            ns_id: '',
            dns_id: '',
            showIconify: true,
            nameIconify: null,
            alldevicetypes : [],
            id_list : {},
            attr_list : [],
            type_mode : '',
            platform_mode : '',
            device_id : '',
            dns_type:'',
            dns_ip: []
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( newValue){
                this.device = newValue;
                const productProvisionedIndex = Object.values(this.value.product_data).findIndex(product => product.provisioned === true)
                if (productProvisionedIndex > -1) {
                    this.productName = Object.keys(this.value.product_data)[productProvisionedIndex];
                }
                if ( this.device.device_id){
                    this.newDevice = false
                    if( this.device.type === "mng_router" )
                        this.devicetypes = this.devicesRoutertypes;
                    if( this.productName == 'RouterSecure' && !this.newDevice && this.device.type != "mng_router" ){                        
                        this.type_mode = this.device.product_data.RouterSecure.data.configuration.device_identification.device_type_mode
                        this.platform_mode = this.device.product_data.RouterSecure.data.configuration.device_identification.device_platform_mode
                        this.device_id = this.device.device_id                    
                    }
                    if (this.device.product_data && this.device.product_data.DNSecure && this.device.product_data.DNSecure.subscriber_info 
                        && this.device.product_data.DNSecure.subscriber_info.ips) {
                        this.dns_type = 'dns_ip';
                        this.dns_ip = this.device.product_data.DNSecure.subscriber_info.ips;
                    }
                    if (this.device.product_data && this.device.product_data.DNSecure && this.device.product_data.DNSecure.subscriber_info 
                        && this.device.product_data.DNSecure.subscriber_info.subscriber_id) {
                        this.dns_id = this.device.product_data.DNSecure.subscriber_info.subscriber_id;
                    }                
                }
            }
        },
    },
    created() {
        this.device = { description: '', platform: 'other', type: null, user_id: null};
        this.dns_type = 'dns_id';
    },
    mounted () {
        this.alldevicetypes = _.cloneDeep(this.devicetypes);
        if (this.value){
            this.device = this.value;
            this.productName = Object.keys(this.value.product_data)[0];
            if ( this.device.device_id){
                this.newDevice = false
                if( this.device.type === "mng_router" )
                    this.devicetypes = this.devicesRoutertypes;

                if( this.productName == 'RouterSecure' && !this.newDevice && this.device.type != "mng_router" ){
                    this.type_mode = this.device.product_data.RouterSecure.data.configuration.device_identification.device_type_mode
                    this.platform_mode = this.device.product_data.RouterSecure.data.configuration.device_identification.device_platform_mode
                    this.device_id = this.device.device_id                    
                }                
            }
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        onChangeProduct(){            
            if( this.productName === "RouterSecure" ){
                this.devicetypes = this.devicesRoutertypes;                
                this.device.type = DEVICE_ROUTER.key;
                this.deviceTypeChange(DEVICE_ROUTER);
     
            } else {                         
                if( this.device.type === "mng_router" ){
                    this.devicetypes =  this.alldevicetypes;  
                    this.device.type = DEVICE_OTHER.key
                    this.deviceTypeChange(DEVICE_OTHER);
                }
            }            
        },
        async save(){
            let isValid = await this.$validator.validateAll();
            if (isValid){
                 if( this.device.type === null ){
                    this.devicetypes =  this.alldevicetypes;  
                    this.device.type = DEVICE_OTHER.key
                    this.deviceTypeChange(DEVICE_OTHER);
                }
                
                this.device.user_id = this.user_id
                  this.device.product_data = {};
                  this.device.product_data[this.productName] = {
                    provisioned: this.products[this.productName].provisioned
                  };
                if (this.productName == 'NetworkSecure' && this.newDevice) {
                    this.device.product_data.NetworkSecure.ns_id = this.ns_id;
                }
                if (this.productName == 'DNSecure') {
                    this.device.product_data.DNSecure.subscriber_info = {};
                    if (this.dns_type == 'dns_id') {
                        this.device.product_data.DNSecure.subscriber_info.subscriber_id = this.dns_id;
                        this.device.product_data.DNSecure.subscriber_info.ips = null;
                    } else if (this.dns_type == 'dns_ip') {
                        this.device.product_data.DNSecure.subscriber_info.subscriber_id = null;
                        this.device.product_data.DNSecure.subscriber_info.ips = this.dns_ip;
                    }
                }
                if (this.productName == 'RouterSecure' && this.newDevice) {                                       
                    let isValidRS = await this.$refs.validateIDs.validateAll();
                    if( !isValidRS ) return 

                    this.device.product_data.RouterSecure.data = {};
                    this.device.product_data.RouterSecure.data.configuration = {};

                    let rsids = [];
                    this.id_list.serial_number && rsids.push({type : "serial_number", value : this.id_list.serial_number })
                    this.id_list.mac_address.forEach(mvalue => {
                        rsids.push({type: "mac_address", value:mvalue})
                    })
                    this.device.product_data.RouterSecure.data.configuration.identifiers = rsids;  

                    let attributes = [];
                    this.attr_list.forEach (item => {
                        item.data.forEach(mvalue => {
                            attributes.push({name: item.type, value:mvalue})
                        })
                    })
                    this.device.product_data.RouterSecure.data.configuration.attributes = attributes; 
                }
                this.device.type = this.nameIconify ? this.nameIconify : this.device.type;
                                
                const dt = this.device.type
                const dp = this.device.platform
                if (this.productName == 'RouterSecure' && !this.newDevice && this.device.type != "mng_router" ){                                           
                    if( this.type_mode == "auto" ) delete this.device.type
                    if( this.platform_mode == "auto" ) delete this.device.platform
                }
                
                this.$emit('input', this.device)                   
                if (this.productName == 'RouterSecure' && !this.newDevice && this.device.type != "mng_router" ){ 
                    const tm = this.type_mode
                    const pm = this.platform_mode                    
                    this.devicetype = this.getModeObj("type", dt)
                    this.deviceplatform = this.getModeObj("platform", dp)                   
                    this.type_mode = tm
                    this.platform_mode = pm                   
                    this.device = _.cloneDeep(this.device)
                }
                
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)                  
                
            }
        },
        getImageembedded(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'embedded')
                return 'data:image/' + imgData.format + ';base64,' + imgData.picture_base64
            else
                return null
        },
        getImageurl(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'external')
                return imgData.picture_url
            else
                return null
        },
        getImage(imgData){
            let img = this.getImageembedded(imgData)
            if ( img)
                return img;
            else
                return this.getImageurl(imgData)
        },
        getUserName(id){
            try{
                return this.users.get(id).name
            }catch(err){
                return null
            }
        },
        getUserImage(id){
            try{
                return this.getImage(this.users.get(id).user_metadata.picture)
            }catch(err){
                return null
            }
        },
        deviceTypeChange(icon) {            
            this.showIconify = false;
            this.nameIconify = icon.key;
            setTimeout(() => {
                this.showIconify = true;            
            }, 100)            
        },
        getModeObj(mode, opt){            
            let allModes = []
            
            if( mode == "type")
                for (const group of this.devicetypes) 
                    for (const type of group.options)
                        allModes.push(type)
            else
                allModes = this.deviceplatforms

            return allModes.filter(option => option.key == opt)[0];                                            
        },
        setDefault(mode){
            this.$bvModal.msgBoxConfirm(this.t('reset-text-' + mode), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
                .then(confirmed => {
                    if(confirmed) {                        
                        this.$store.dispatch('routerSecure/devices/resetAttrRSNetworkDevice', {account : this.device.account_id, device : this.device_id, mode : mode})
                        .then( res => {                                                        
                            this.$store.commit("setSuccess", this.t('device-' + mode) + " " + this.t("reset"))
                           
                            this["device" + mode] = this.getModeObj(mode, res)
                            this[mode + "_mode"] = "auto"
                                                        
                            if( mode == "type")
                               this.deviceTypeChange(this.devicetype)
                        })
                    }
                })
                .catch(() => {
                }) 
        },
        handleIpChange(data) {
            this.dns_ip = data;
        },
        handleNewIp(){            
            this.$refs.dnsSecureInfo.handleNewIp();
        }
        
    },
    computed:{
        isNetworkSecureNewDevice() {
          return this.productName === 'NetworkSecure' && this.newDevice;
        },
        isDNSecureDevice() {
          return this.productName === 'DNSecure';
        },
        devicetype: {
            get() {
                let ret = null
                let allTypes = []
                for (const group of this.devicetypes) {
                    for (const type of group.options) {
                        allTypes.push(type)
                    } 
                }
                if ( this.device) {
                    ret = allTypes.filter(option => option.key == this.device.type)[0];
                }           
                return ret;
            },
            set(newValue) {
                if ( this.device){                    
                    this.device.type = newValue.key
                    this.type_mode = "custom"
                }
            }
        },
        deviceplatform: {
            get() {
                let ret = null
                if ( this.device)
                    ret = this.deviceplatforms.filter( item => item.key == this.device.platform)[0];
                
                return !!ret ? ret : this.deviceplatforms.filter( item => item.key == 'other')[0];
            },
            set(newValue) {
                if ( this.device){
                    this.device.platform = newValue.key
                    this.platform_mode = "custom"
                }
            }
        },
        defaultuser: {
            get() {
                let ret = null;
                if ( !this.users || this.users.size == 0)
                    return null;

                this.users.forEach( (value, key) => {
                    if (value.default_user)
                        ret = value
                });
                return !!ret ? ret : this.users.values().next().value
            }
        },
        user_id:{
            get(){
                if ( this.device){
                    if ( this.device.user_id)
                        return this.device.user_id
                    else
                        return !!this.defaultuser ? this.defaultuser.user_id : null
                }
                else
                    return null

            },
            set(newValue){
                if ( this.device)
                    this.device.user_id = newValue
            }
        },
        arrayusers() {
            let ret =  []
            if (this.users){         
                Array.from(this.users).forEach (item => {
                    ret.push(item[1])
                });
            }
            return ret
        },
        productList() {
          return Object.keys(this.products).filter(
            product => product != 'EndpointSecure' && this.products[product].provisioned
          );
        },
        dnsIdOrIp: {
            get() {
                return this.dns_type;
            },
            set(value) {
                this.dns_type = value;
            }
        },
        dnsIdOrIpList() {
          return ['dns_id', 'dns_ip'];
        }
    }
}
</script>
<style lang="scss" >
.prefix {
  margin-top: 5px;
  margin-left: 10px;
}
.form-group .el-input__inner{
    padding-left: 46px;
}
.device-form-icon.icon {
    font-size: 1.5rem;
    color: #333333;
}
.select-filterable-icons {
    .el-input {
        input.el-input__inner {
            text-indent: 2em;
        }
    }
}
span.device-name-with-icon {
    vertical-align: -webkit-baseline-middle;
}
a.link-without-href {
    cursor: pointer;
}
</style>
