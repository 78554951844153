<template>
    <b-container class="form-group">
        <b-row v-for="(network, index) in networks" :key="index">
            <b-col class="col-xs-12 col-sm-12 col-md-10 col-lg-10 pr-0">
                <fg-input v-model="network.ip_begin" @input="debounceInput"
                            :id="'ip_begin_' + index"
                            :label="t('IPv4 Address / IPv6 Range')"
                            labelClasses="smalllabel"
                            :name="getName('IPv4 Address / IPv6 Range', index)"
                            :placeholder="t('e.g. 10.0.0.0 or 2001:db8:a::/64')"
                            v-validate="{ required: true, regex: $options.ipv4v6regex}"
                            :error="getError(getName('IPv4 Address / IPv6 Range', index))">
                </fg-input>
				
				<div>
					<p class='help-text'>{{t('ipNetworkTitle')}}</p>
				</div>
            </b-col>           
            <b-col class="mt-3 ml-auto col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <n-button type="primary" round icon @click.native="handleRemove(index)">
                    <i class="isp-icon bin icon-danger" style="font-size: 1.2rem;"></i>
                </n-button>
            </b-col>
		</b-row>
        <b-modal id="modal-remove-ip"
            ref="modal-remove-ip"
            :title="t('warning')"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            @ok="removeNetwork.ok"
            @cancel="removeNetwork.cancel"
        >
            {{removeNetwork.message}}
        </b-modal>
    </b-container>
</template>

<script>
import _ from 'lodash';
import { ipv6RegExp, ipv4v6RegExp }  from "@/util/regexConsts";

export default {
    name: 'ip-networks',
    props: {
        value: {
            type: Array,
            required: true
        },
    },
    ipv6regex: ipv6RegExp,
    ipv4v6regex: ipv4v6RegExp,
    data() {
        return {
            networks: [],
            isIPv6: [],
            removeNetwork: {
                index: undefined,
                ok: () => {},
                message: '',
                cancel: () => {}
            }
        }
    },
    watch: {
        value(newValue) {
            if ( newValue){
                this.networks = newValue
            }
        },
        networks:{
            deep: true,
            handler(){
                let ret = []
                let _vm = this
                this.networks.forEach( (item) => {
                    ret.push( item && _vm.$options.ipv6regex.test(item.ip_begin))
                })
                this.isIPv6 = ret
            }
        }
    },
    mounted () {
        if ( !!this.value){
            this.networks = this.value
        }
    },
    methods: {
        getName(name, index){
            let idx = index+1
            return name + ' ' + idx
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        handleNew(){            
            this.networks.push( { ip_begin:''})
        },
        handleRemove(index){
            this.removeNetwork = {
                index: index,
                message: this.t('are_you_sure_to_delete_device_ip'),
                ok: this.emitRemove,
                cancel: this.resetRemoveIp
            };
            this.$refs['modal-remove-ip'].show();
        },
        emitRemove() {
            this.networks.splice(this.removeNetwork.index ,1)
            this.$emit('input', this.networks)
        },
        resetRemoveIp() {
            this.removeNetwork = {
                index: undefined,
                ok: () => {},
                message: '',
                cancel: () => {}
            };
        },
        debounceInput: _.debounce(function (e) {
            this.$validator.validateAll()
            .then( isValid => {
                if (isValid){
                    this.$emit('input', this.networks)
                    setTimeout(() => {
                        this.$validator.reset();
                    }, 100)
                }
            })
            .catch( err => {
                console.error('Validation error-> ', err)
            })            
        }, 500),
        async handleInput(){
            let isValid = await this.$validator.validateAll();
            if (isValid){
                this.$emit('input', this.networks)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        }
    }
}

</script>
