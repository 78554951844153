<template>
    <b-card id="id-frame"> 
            <account-title :id="account_id" close @refresh="refreshUrl">
                <div slot="content" style="padding: 10px;">
                    <template v-if="!!!manager_id">
                        You are impersonating the account <b>{{account_id}}</b>.
                    </template>
                    <template v-else>
                        You are impersonating the account <b>{{account_id}}</b> with the manager <b>{{manager_name}}</b>.
                    </template>
                </div>
            </account-title>
        <iframe class="iframe" id="sso_iframe" name="sso_iframe" :class="classname" ref="iframe"/>
    </b-card>
</template>

<script>
import AccountTitle from './AccountTitle.vue';

export default {
    name: 'frame-loader',
    components: {
        AccountTitle
    },
    props : {
        classname: {
            type: String
        },
        debug : {
            type: Boolean,
            default: false
        },
        account_id : {
            type: String,
            required: true
        },
        manager_id : {
            type: String,
            default: null
        },
        entity: {
            type: String,
            default: null
        },
        account_type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            updating: false,
            manager_name: null,
            url: null
        }
    },
    methods: {
        refreshUrl(wait){
            let _vm = this
            this.updating = true
            if (!!this.account_id && !! this.entity){
                const _vm = this
                this.$store.dispatch('accountManagement/managers/getSSOAccountManager', { entity: this.entity, id: this.id, type: this.account_type})
                .then( res => {
                    _vm.url = res
                    _vm.updating = false
                })
                .catch( err => {
                    _vm.updating = false
                    _vm.$router.replace( {name: 'Account Management / Accounts / Edit', params: { id: this.account_id}})
                });
            }
            else{
                this.updating = false
                if ( !!this.account_id)
                    this.$router.replace( {name: 'Account Management / Accounts / Edit', params: { id: this.account_id}})
                else
                    this.$router.replace('/')
            }
        },
        refreshManager(wait){
            let _vm = this
            this.updating = true
            if (this.account_id && this.manager_id){
                this.$store.dispatch('accountManagement/managers/getAccountManager', { account_id: this.account_id, manager_id: this.manager_id})
                .then( res => {
                    _vm.manager_name = res.identity.name; 
                    _vm.updating = false
                })
                .catch( err => {
                    _vm.updating = false
                    _vm.$router.replace( {name: 'Account Management / Accounts / Manager / Edit', 
                                    params: { id: _vm.manager_id, account_id: _vm.account_id}})
                });
            }
        }
    },
    computed: {
        id(){
            if (!!this.manager_id)
                return this.manager_id
            else
                return this.account_id
        },
    },
    watch: {
        url(newValue){
            this.$store.commit('settings/startLoading', true)
            setTimeout( () => {
                this.$refs.iframe.src = this.url
                this.$refs.iframe.onload = () => {
                    console.log('loading ' + this.url + '...')
                }
                this.$store.commit('settings/finishLoading', true)
            }, 500);
        }
    },
    created(){
        this.refreshUrl(0)
        this.refreshManager()
    },
};
</script>

<style lang="scss" scoped>
.iframe {
    margin-top: -30px;
    height: calc(100vh - 95px);
    overflow-y: scroll;
    width: 100%;
    border: 0px;
}
</style>
