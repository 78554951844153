<template>
    <b-card class="px-4 pb-0" >
        <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between __header">
            <h4 class="mb-0 mr-4" style="display: flex;">
                <span style="font-size: 0.85rem;">{{ t(help_text) }}</span>                 
            </h4>

            <div class="d-flex align-items-center">
                <div>
                    <n-button :disabled="!flags" @click.native="saveFlags" type="primary"   class="button-router" round block size="sm"  :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                        {{t('Save')}}
                    </n-button>
                </div>
                <div style="margin-left:10px">
                <n-button :disabled="!flags || !changes" 
                            @click.native="resetFlags" 
                            type="primary"
                            class="button-router" 
                            round block size="sm"
                            :visible_for_permissions='[{actions: ["DELETE"], path: "/accounts/.*/devices/.*/" + endpoint}]'>
                    {{t('Reset')}}
                </n-button>  
                </div>     
            </div>
            </div>

        </template>

            <b-container fuild style="width: 100%; padding: 0px;" >

                <b-row v-if="flags">
                    <b-col :xl="6" :lg="12" :md="12" :sm="12" v-for="(item, index) in flags"  :key="index" style="overflow:hidden;">
                        <b-row no-gutters class="align-items-center" >

                            <b-col cols="2" class="ml-1 float-right" v-if="showMode=='Features'">
                                <n-switch
                                    v-model="flags[index].custom_value" @input="updateValue(index, $event)"/>
                            </b-col>
                            <b-col :xl="3" :lg="3" :md="3" :sm="3" class="ml-1 float-left" v-else >
                                <el-select class="select-primary"
                                    v-model="flags[index].custom_value"
                                    size="small"
                                    @input="updateValue(index, $event)"
                                >
                                    <el-option v-for="val in allowedValues"
                                        class="select-primary"
                                        :value="val"
                                        :label="val"
                                        :key="val">
                                    </el-option>
                                </el-select>
                            </b-col>
                            <b-col  :xl="showMode=='Features' ? 4 : 3"  :lg="showMode=='Features' ? 9 : 3" :md="showMode=='Features' ? 9 :3 " :sm="showMode=='Features' ? 9 : 3"  class="has-label pt-2 float-left" >
                                <el-tooltip
                                    :content='item.description != "" ? item.description : item.name'
                                    placement="top"
                                    effect="light"
                                    :disabled="(item.description || item.name).length < (showMode === 'Features' ? 74 : 44)"
                                >
                                    <div :class="showMode=='Features' ? 'm_features' : 'm_logs'" style="white-space: nowrap;display:grid;">
                                        <label v-if="item.description">{{ t(item.description) }}</label>
                                        <label>{{ item.name }}</label>
                                    </div>
                                </el-tooltip>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>        
    </b-card>
</template>

<script>
import { Switch } from '@/components';

export default {
    name: 'routersecure-router-flags',
    components: {
        [Switch.name]: Switch
    },
    data() {
        return {
          flags : undefined,
          allowedValues : [],
          changes: false
        }
    },
    props: {        
        showMode:{
            type : String,
            required: true,
            validator: function (value) {
                return ['Features', 'Logs'].indexOf(value) !== -1
            }
        },
        account:{
            type : String,
            default : ''
        },
        device:{
            type : String,
            default : ''
        },
    },
    computed: {
        endpoint: {
            get(){
                switch(this.showMode){
                case 'Features':
                    return "feature-flags"
                case 'Logs':
                    return "log-flags"
                default:
                    console.log("unkonwn showMode: " + this.showMode)
                }
            }
        },
        help_text: {
            get(){
                switch(this.showMode){
                case 'Features':
                    return "features_help"
                case 'Logs':
                    return "logs_help"
                default:
                    return ""
                }
            }
        }
    },
    methods: {
        updateValue(key, value) {            
            this.flags[key].custom_value = value     
            this.changes = true
        },
        saveFlags(){
            let nItems = {
                custom : {}
            }

            this.flags.forEach(item =>{
                nItems.custom[item.name] =  this.showMode == "Features" ? (item.custom_value ? "1" : "0") : item.custom_value;
            })

            this.$store.dispatch('routerSecure/devices/set' + this.showMode, {account: this.account, device : this.device, data: nItems})
            .then(res => {     
                this.$store.commit("setSuccess", this.$t("save_success"))
                this.changes = false
            })
            .catch(err => {})
        },
        resetFlags(){
            this.refresh(true)
        },
        refresh(reset){
            this.$store.dispatch('routerSecure/devices/get' + this.showMode, {account : this.account, device : this.device})
            .then( res => {
                this.flags = []
                let customflags = false;
                res.flags.forEach(item => {
                    this.flags.push({
                        name : item.name,
                        default_value : this.showMode == "Features" ? (item.default_value == "0" ? false : true) : item.default_value,
                        custom_value : this.showMode == "Features" ? (item.custom_value == "0" ? false : true) : item.custom_value,
                        description : item.description
                    })
                    customflags |= item.isCustom
                })
                this.allowedValues = res.allowed_values

                if( reset && customflags ){
                    this.$store.dispatch('routerSecure/devices/resetFlags', {account : this.account, device: this.device, mode: this.showMode})
                    .then( res => {
                        this.$store.commit("setSuccess", this.t("values reset"))
                        this.refresh(false)
                    })
                    .catch(err => {
                        this.refresh(false)
                    })
                }
                else{
                    this.changes = false
                }
            })
            .catch(err => {
                this.flags = undefined
            })
        }
    },
    mounted() {
        if( this.showMode != ""  ){
            this.refresh();
        }
    }
}
</script>

<style scoped>
.title{
    text-transform: uppercase;
    opacity: 0.7!important
}
.bottom-border {
  width: 100%;
  background-color: black;
}
.card {
  margin-bottom: 10px;
}
.card-header {
  padding-top: unset;
}
.card-body {
  padding: 0 15px;
  margin: 0;
}
.__header {
  min-height: 50px;
}
.m_logs{
    padding: 10px
}
.m_features{
    padding: 0px
}
.card-body {
   display: flex;
}
</style>
