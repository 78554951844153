<template>
    <b-card no-body >
        <template v-slot:header>
            <b-container fuild style="max-width: 2560px; padding: 0px;" >           
            <b-row class="align-items-center">
                <b-col class="ml-3">                   
                    <div class="form-group has-label"><label>{{t('List of attributes')}}</label></div>
                </b-col>
                <b-col cols="4" md="4" lg="3" xl="2"  class="ml-auto mt-2">
                    <n-button type="primary"  @click.native="showItemEdition"  round block  :visible_for_permissions='[{actions: ["PATCH"], path: "/accounts/.*/devices/.*"}]'>
                        <i><svgicon class="icon" icon="icon-new"/></i>
                       {{ t('New') }}
                    </n-button>
                </b-col>
            </b-row>
            </b-container>
        </template>

        <b-card-body style="padding: 0px;">
            <paginated-table :rows='itemlist'
                            :columns='columns'
                            :actions="actions"
                            :updating="updating"
                            :serverpagination="null"
                            @removeItem="removeItem"
                            @editItem="editItem"
                            :showEmptyState=false
                            :showHeadOnEmpty=true />
        </b-card-body>


        <b-modal id="modal-new" ref="modal" :title="modalTitle" @ok="newItemok" @show="resetItem" :ok-title="t('accept')" :cancel-title="t('cancel')">
            <form class="form-group attributes-list-form" @submit.prevent="newItem">
                <b-row>
                    <b-col xl="12" class="mb-2">
                        <div class="mt-1"><label>{{t('Name')}}:</label></div>
                        <fg-input
                            v-model="type"
                            :disabled="!newAttr"
                            v-validate="{required: true, max:256}"
                            name="Type"
                            :error="getError('Type')"
                            class="no-border no-height form-control-lg"
                            :placeholder="t('Name')"
                            addon-left-icon="now-ui-icons business_badge"
                            autocapitalize="none"
                            style="font-size:1rem;"
                         />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col xl="12" class="mb-2">
                        <div class="mt-1"><label>{{t("Value")}}:</label></div>
                        <autocomplete-tag-input
                            name="attr_value"
                            inputType="attribute"
                            :value='dataInType'
                            @change="handleChange($event)"
                            :placeholder="t('Value')"
                            :showdropdown=false
                            :error="getError('attr_value')"
                            v-validate="'required'"
                        ></autocomplete-tag-input>
                        <p class="errorMessage pl-0" v-if="getError('attr_value')">{{ getError('attr_value') }}</p>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </b-card>
</template>

<script>
import { PaginatedTable } from '@/components';
import { ListFormatter } from "@/components/Tables/Formatters";
import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';

export default {
    name: 'attributes-list',
    components: {
        PaginatedTable,
        AutocompleteTagInput
    },
    props: {
        value: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            modalTitle: '',
            newAttr : true,
            dataInType: [],
            type: '',
            updating: false,
            itemlist : [],
            columns: [
                {
                    prop: 'type',
                    label: this.$t("Name"),
                    minWidth: 130
                },
                {
                    prop: 'data',
                    label: this.$t("Value"),
                    minWidth: 350,
                    formatter: ListFormatter,
                }
            ],
            actions: {
                minWidth: 100,
                label: this.t('Actions'),
                fixed: 'right',
                items: [
                    {   type: 'warning', icon: 'now-ui-icons business_badge', event: 'editItem', action: this.t('Edit'),
                    visible_for_permissions: [{ actions: ["PATCH"],  path: "/accounts/.*/devices/.*"} ] },
                    {   type: 'danger', icon: 'now-ui-icons ui-1_simple-remove', event: 'removeItem', action: this.t('Remove'), confirm: false,
                    visible_for_permissions: [{ actions: ["PATCH"],  path: "/accounts/.*/devices/.*"}] }
                ]
            }
        }
    },
    mounted() {
        this.refresh(0)
    },
    methods: {
        handleChange(event) {
           this.dataInType = event
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        resetItem() {
            this.type = ''
            this.dataInType = []
            this.$validator.reset()
        },
        showItemEdition() {
            this.modalTitle = this.t('New Attribute')
            this.newAttr = true
            this.$refs.modal.show()
        },
        editItem(data){
            this.modalTitle = this.t('Edit Attribute')
            this.newAttr = false
            this.$refs.modal.show()
            this.type = data.row.type
            this.dataInType = _.cloneDeep(data.row.data)
        },
        newItemok( bvModalEvt ) {
            bvModalEvt.preventDefault()
            this.newItem()
        },
        async newItem() {
            let isValidForm = await this.$validator.validateAll();
            if (!isValidForm) return

            let templist;

            if( !this.itemlist )
                templist = []
            else
                templist = _.cloneDeep(this.itemlist)

            let objIndex = templist.findIndex((item => item.type === this.type));
            if(  this.newAttr ){
                if( objIndex >= 0 ){
                    this.$store.commit("setError", this.t(`Attribute name already exists`))
                    return;
                }
                let item = {
                    type : this.type,
                    data : _.cloneDeep(this.dataInType)
                }
                templist.push(item)
            } else
                templist[objIndex].data = _.cloneDeep(this.dataInType)

            this.itemlist = templist
            this.$nextTick(() => {
                this.$refs.modal.hide()
            })
        },
        removeItem(data){
            this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this attribute?'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
            .then(confirmed => {
                if(confirmed) {
                    this.itemlist = this.itemlist.filter(function (item) {
                        return item.type != data.row.type;
                    });
                }
            })
            .catch(() => {
            })
        },
        async refresh(wait){
            if( this.value ){
                let attributes = [], attridx;
                if( this.value.length > 0 && this.value[0].name ){
                    this.value.forEach (item => {
                        attridx = attributes.findIndex( attr => attr.type === item.name )
                        if( attridx >= 0 )
                            attributes[attridx].data.push(item.value);
                        else
                            attributes.push({"type": item.name, "data" : [item.value]})
                    })
                    this.itemlist = attributes
                }
            }
        }
    },
    watch: {
        value(newValue){
            if(newValue){
                if( newValue.length > 0 && newValue[0].name ){
                    let attributes = [], attridx;
                    newValue.forEach (item => {
                        attridx = attributes.findIndex( attr => attr.type === item.name )

                        if( attridx >= 0 )
                            attributes[attridx].data.push(item.value);
                        else
                            attributes.push({"type": item.name, "data" : [item.value]})
                    })
                    this.itemlist = attributes
                }
            }
        },
        itemlist(newValue){
            this.$emit('input', newValue);
        }
    },
}
</script>
<style lang="scss">
.attributes-list-form  {
  .el-input__inner, .el-input__inner:focus {
    color: inherit;
  }
}
</style>

