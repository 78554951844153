<template>
    <b-card no-body>

        <template v-slot:header>
            <slot name="header">
                <b-container fuild style="width: 100%; padding: 0px;">
                <b-row class="align-items-center">
                    <b-col class="ml-3">
                        <h4 class="mb-0 mr-4" style="display: flex;">
                            <span style="font-size: 0.85rem;">
                                {{ t('Check main parameters for the RouterSecure configuration and send actions to update or troubleshooting the router agent')}}
                            </span>                 
                        </h4>
                    </b-col>
                </b-row>
                </b-container>
            </slot>
        </template>

        <b-card-body >
            <b-container fuild style="width: 100%; padding: 0px;">
                <b-row>
                    <b-col md="12" xl="7">
                        <b-row>
                            <b-col sm="10" >
                                <label class="prefix">{{t('RouterSecure ID')}}:</label>
                                <div class="space_left" v-for="(item, index) in ids" :key="index">
                                    <label>{{t(item.type)}}:</label>
                                    <label class="ml-2">{{item.value}}</label>                              
                                </div>
                                <div class="space_left" v-if="serial_number">
                                    <label>{{t('Router Serial Number')}}:</label>
                                    <label class="ml-2">{{ serial_number }}</label>                              
                                </div>

                            </b-col>
                        </b-row>
                        <b-row v-if="router_model" class="mt-3 mb-2">
                            <b-col sm="10">
                                <label class="prefix">{{t('Router Model')}}:</label>
                                <div class="space_left" >
                                    <label>{{router_model}}</label>
                                </div>               
                            </b-col>
                        </b-row>  
                        <b-row class="mt-3 mb-2">
                            <b-col sm="10">
                                <label class="prefix">{{t('Router Status')}}:</label>
                                <div class="space_left" >
                                    <device-status-label :device="device" />
                                </div>               
                            </b-col>
                        </b-row>                          

                        <b-row class="my-3">
                            <b-col sm="10">
                                <div class="hr-grey"/>
                            </b-col>
                        </b-row>

                        <b-row class="mt-3 mb-2">
                            <b-col sm="12" >
                                <label class="prefix">{{t('Agent')}}:</label>
                                <div class="space_left" v-if="firmware_version">
                                    <label>{{t('Router Firmware Version')}}:</label>
                                    <label class="ml-2">{{firmware_version}}</label>
                                </div>
                            </b-col>
                            <b-col sm="6" >                               
                                <div class="space_left">
                                    <label>{{t('Router Agent Version')}}:</label>
                                    <label class="ml-2" v-if="current_agent_version_id">{{current_agent_version_id}}</label>
                                    <div v-else>
                                        <i class="now-ui-icons loader_refresh spin"/>
                                        <label class="ml-2 reset_message">{{t('Waiting for Agent response')}}</label>
                                    </div>
                                </div>
                                <div class="space_left" >
                                    <label>{{t('Router Agent target Version')}}:</label>
                                    <label class="ml-2">{{target_agent_package}}</label>    
                                </div>
                                <div class="space_left" >
                                    <label>{{t('Operation mode')}}:</label>
                                    <label class="ml-2">{{ t(operational_mode)}}</label>    
                                </div>
                            </b-col>
                            <b-col class="my-auto" sm="4">
                                <n-button native-type="submit" @click.native="showAgent" type="primary"
                                    :disabled="!current_agent_version_id"
                                    class="button-router" round block
                                    :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                    {{ t('Update') }}
                                </n-button>  
                            </b-col>
                        </b-row>    
                        
                        <b-row class="my-3">
                            <b-col sm="10">
                                <div class="hr-grey"/>
                            </b-col>
                        </b-row>

                        <b-row class="mt-3 mb-2" v-if="mac_addresses.length > 0">
                            <b-col sm="10" >
                                <label class="prefix">{{t('Router MACs')}}:</label>
                                <div class="space_left" v-for="mac in mac_addresses" :key="mac">
                                    <label>{{mac}}</label>
                                </div>
                            </b-col>
                        </b-row>

                    </b-col>

                    <b-col v-if="showStatus">
                        <b-row class="my-3 d-xl-table-cell" v-if="mac_addresses.length > 0">
                            <b-col sm="10">
                                <div class="hr-grey"/>
                            </b-col>
                        </b-row>

                        <b-row class="border rounded" id="status_table">
                            <b-col>
                                <b-row class="mt-2">
                                    <b-col sm="6">
                                        <label class="prefix">{{t('Status')}}</label>
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" v-if="device.product_data.RouterSecure.data.configuration">
                                    <b-col sm="7" class="space_between_buttons">
                                        <div class="space_left" v-if="device.product_data.RouterSecure.data.configuration.enabled">
                                            <label class="mr-2">
                                                <i class="table-icon now-ui-icons ui-1_check icon icon-success"/>
                                            </label>
                                            {{ t('Enabled')}}
                                        </div>
                                        <div class="space_left" v-else>
                                            <label class="mr-2">
                                                <i class="table-icon now-ui-icons ui-1_simple-remove icon icon-danger"/>
                                            </label>
                                            {{ t('Disabled')}}
                                        </div>
                                    </b-col>
                                    <b-col sm="4">
                                        <n-button @click.native="changeStatus" type="primary" class="button-router" 
                                                round block :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                            <span v-if="device.product_data.RouterSecure.data.configuration.enabled">{{t('Deactivate')}}</span>
                                            <span v-else>{{t('Activate')}}</span>
                                        </n-button>  
                                    </b-col>
                                </b-row>

                                <b-row class="my-2" v-if="device.product_data.RouterSecure.data.configuration">
                                    <b-col sm="10" lg="10" xl="12" class="mx-1">
                                        <div class="hr-grey"/>
                                    </b-col>
                                </b-row>

                                <b-row align-v="center" v-if="device.product_data.RouterSecure.data.status">
                                    <b-col sm="7" class="space_between_buttons" >
                                        <div class="space_left">
                                            <label class="mr-2">{{t('Reboot Loop')}}</label>                            
                                            <label class="align-baseline">
                                                <label v-if="reboot_loop">{{t('ON')}}</label>
                                                <label v-else>{{t('OFF')}}</label>
                                            </label>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" >
                                        <n-button @click.native="resetRebootLoop" type="primary" class="button-router" 
                                                round block  :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>                                
                                            {{t('Reset')}}
                                        </n-button>                              
                                    </b-col>
                                </b-row>

                                <b-row v-if="reset_reboot_loop_message">
                                    <b-col>
                                        <div class="space_left">
                                            <i class="now-ui-icons loader_refresh spin"/>
                                            <label class="ml-2 reset_message"> {{ t(this.reset_reboot_loop_message)}} </label>
                                        </div>
                                    </b-col>
                                </b-row> 

                                <b-row class="my-2" v-if="device.product_data.RouterSecure.data.status">
                                    <b-col sm="10" lg="10" xl="12" class="mx-1">
                                        <div class="hr-grey"/>
                                    </b-col>
                                </b-row>

                                <b-row align-v="center" v-if="device.product_data.RouterSecure.data.status">
                                    <b-col sm="7" class="space_between_buttons" >
                                        <div class="space_left">
                                            <label class="mr-2">{{t('Safe Mode')}}</label>                            
                                            <label class="align-baseline">
                                                <label v-if="safe_mode">{{t('ON')}}</label>
                                                <label v-else>{{t('OFF')}}</label>
                                            </label>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" >
                                        <n-button  @click.native="resetSafeMode" type="primary" class="button-router" 
                                                round block  :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                            {{t('Reset')}}
                                        </n-button>  
                                    </b-col>
                                </b-row>

                                <b-row v-if="reset_safe_mode_message">
                                    <b-col>
                                        <div class="space_left">
                                            <i class="now-ui-icons loader_refresh spin"/>
                                            <label class="ml-2 reset_message"> {{ t(this.reset_safe_mode_message)}} </label>
                                        </div>
                                    </b-col>
                                </b-row> 
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

            </b-container>
        </b-card-body>

        <b-modal
            id="modal-update"
            ref="modal"
            :title="t('Set Target Version')"
            scrollable
            @ok="handleUpdate"
            @show="resetModal"
            @cancel="resetModal" :ok-title="t('accept')" :cancel-title="t('cancel')">

            <div class="authpages">
                <form class="form-group" @submit.prevent="newItem">               
                    <b-row>
                        <b-col xl="12" class="mb-2">
                            <div class="mt-1"><label>{{t('Available Versions')}}:</label></div>
    
                            <el-select class="select-primary"  
                                v-model="agentVersion"
                                name="AgentVersion"
                                size="large"
                                :placeholder="this.t('Select Version')"
                                :error="getError('AgentVersion')"
                            >
                                <el-option v-for="version in versionList"
                                    class="select-primary"
                                    :value="version.key"
                                    :label="version.value"
                                    :key="version.key">
                                </el-option>
                            </el-select>
                            <p class="errorMessage pl-0" v-if="getError('AgentVersion')">{{ getError('AgentVersion') }}</p>                                
                        </b-col>
                    </b-row>
                </form>
            </div>            
        </b-modal>  
    </b-card>
</template>


<script>
import _ from 'lodash';
import DeviceStatusLabel from './components/DeviceStatusLabel';

const FREQUENCY = 30000

export default {
    name: 'routersecure-router-info',   
    components: {
        [DeviceStatusLabel.name]: DeviceStatusLabel,
    },   
    props: {
        value: {
            type: Object,
            required: true 
        },
        operational_mode: {
            type: String,
            default: 'security_mode',
            validator: operational_mode => ['security_mode', 'analytics_mode'].includes(operational_mode)
        }
    },
    data() {
        return {   
            versionList : [],
            agentVersion : '',
            device: {
              product_data: {
                RouterSecure: {
                  data : {}
                }
              }
            },
            target_agent_package_id : '',

            // To disable things that are not available in version **30.1**
            availableInActualVersion: false,

            reset_safe_mode_message: '',
            reset_reboot_loop_message: '',

            timer_noAgent: null,
            timer_action: null,
        }
    },
    
    watch: {
        value(newValue) {             
            if (newValue){
                this.device = newValue
                this.refresh();
                this.reset_reboot_loop_message = this.reset_safe_mode_message = ''
            }
        },
        reset_reboot_loop_message(newValue){
            this.actionTriggered(newValue)
        },
        reset_safe_mode_message(newValue){
            this.actionTriggered(newValue)
        },
    },
    mounted () {        
        if (this.value){         
            this.device = this.value           
            this.refresh()
        }
    },
    methods: {
        actionTriggered(newValue){
            if (newValue){
                if ( !this.timer_action){
                    this.timer_action = setTimeout( () => {
                        if (this.timer_action){
                            clearTimeout(this.timer_action)
                            this.timer_action = null
                        }
                        this.$emit('refresh')
                    }, FREQUENCY);
                }
            }
            else{
                if (this.timer_action){
                    clearTimeout(this.timer_action)
                    this.timer_action = null
                }
            }
        },
        refresh(){
            let _vm = this;

            if( this.device.device_id ){
                this.$store.dispatch('routerSecure/devices/getDeviceTargetAgent', this.device.device_id)
                .then( res => {                                
                    _vm.target_agent_package_id = res  
                })
                .catch(err => {})                             
            }

            try{
                if(!this.device.product_data.RouterSecure.data.configuration ){
                    this.device.product_data.RouterSecure.data["configuration"] = {
                        identifiers : [],
                    };
                }
            }
            catch(err){}
            this.updateAgent()

            if (!this.current_agent_version_id){
                this.timer_noAgent = setTimeout( () => {
                    if (this.timer_noAgent){
                        clearTimeout(this.timer_noAgent)
                        this.timer_noAgent = null
                    }
                    this.$emit('refresh')
                }, FREQUENCY);
            }
        },
        showAgent(){
            this.updateAgent();
            this.$refs.modal.show();
        },
        updateAgent() {
            let _vm = this
            if( !!this.router_model ){
                this.$store.dispatch('routerSecure/devices/getAgentPackages', 
                            {router_model : this.router_model, firmware_version : this.firmware_version})
                .then( res => {      
                    _vm.versionList = []
                    res.forEach(item => {                                  
                        _vm.versionList.push({
                            key : item.key,
                            value : item.value + 
                                (item.time ? " - " + this.toLocaleString(this.datetimeFromISO(item.time)) : ''),
                            version: item.value
                        })
                        if (item.default) {
                            _vm.versionList.unshift({
                                key : '',
                                value : this.t('default') + " (" +  item.value + ")",
                                version: item.value
                            })
                        }
                    })                   
                })
                .catch(err => {})          
            }  
        },
        resetModal() {     
            this.agentVersion = '';
            if(this.target_agent_package_id != "" )
                this.agentVersion = this.target_agent_package_id;
        },
        async handleUpdate(bvModalEvt) {      
            this.target_agent_package_id = this.agentVersion
            this.actionTriggered(this.current_agent_version_id)
            this.current_agent_version_id = ''
            let data = { command: "update_target_agent", value: this.target_agent_package_id }
            this.$emit('command', data)
        },
        resetSafeMode(){
            let data = { command: "safe_mode" }
            this.$emit('command', data)
            this.reset_safe_mode_message = "Reset action successfully sent"
        },
        resetRebootLoop(){                        
            let data = { command: "reboot_loop" }
            this.$emit('command', data)
            this.reset_reboot_loop_message = "Reset action successfully sent"
        },    
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        changeStatus(){
            let data = {
                device_id: this.device.device_id,
                account_id: this.device.account_id,
                product_data: {
                    RouterSecure : {
                        data : {
                            configuration : {
                                enabled : !this.device.product_data.RouterSecure.data.configuration.enabled
                            }
                        }
                    }
                }
            };
            this.$emit('input', data)
        },
    },
    beforeDestroy() {
        if (this.timer_noAgent){
            clearTimeout(this.timer_noAgent)
            this.timer_noAgent = null
        }
    },
    computed: {
        ids : {
            get(){
                return _.get(this.device, 'product_data.RouterSecure.data.configuration.identifiers', [])
            }
        },
        current_agent_version_id : {
            get(){
                let ret = null
                try{
                    ret = this.device.product_data.RouterSecure.data.status.current_agent_version_id
                }
                catch(e){
                    ret = null
                }

                if (!ret){
                    if (!this.timer_noAgent){
                        this.timer_noAgent = setTimeout( () => {
                            if (this.timer_noAgent){
                                clearTimeout(this.timer_noAgent)
                                this.timer_noAgent = null
                            }
                            this.$emit('refresh')
                        }, FREQUENCY);
                    }
                }
                else{
                    if (this.timer_noAgent){
                        clearTimeout(this.timer_noAgent)
                        this.timer_noAgent = null
                    }
                }
                return ret
            },
            set(newValue){
                try{
                    this.device.product_data.RouterSecure.data.status.current_agent_version_id = newValue
                }
                catch(e){
                }

            }
        },
        router_model: {
            get(){
                try{
                    return this.device.product_data.RouterSecure.data.status.router_model
                }
                catch(e){
                    return null
                }
            }
        },
        firmware_version: {
            get(){
                try{
                    return this.device.product_data.RouterSecure.data.status.firmware_version
                }
                catch(e){
                    return null
                }
            }
        },
        serial_number: {
            get(){
                try{
                    return this.device.product_data.RouterSecure.data.status.serial_number
                }
                catch(e){
                    return null
                }
            }
        },
        target_agent_package: {
            get(){
                if (!this.versionList || this.versionList.length == 0)
                    return this.t('default')

                let agent = this.versionList.filter(item => {
                    return item.key === this.target_agent_package_id
                })
    
                if( agent && agent.length > 0 ){
                    if( !this.target_agent_package_id)
                        return this.t('default') + " (" +  agent[0].version + ")"
                    else
                        return agent[0].version
                } else
                    return this.t('default')
            }
        },
        safe_mode: {
            get(){
                try{
                    return this.device.product_data.RouterSecure.data.status.safe_mode
                }
                catch(e){
                    return false
                }
            }
        },
        reboot_loop: {
            get(){
                try{
                    return this.device.product_data.RouterSecure.data.status.reboot_loop
                }
                catch(e){
                    return false
                }
            }
        },
        mac_addresses: {
            get(){
                try{
                    let macs = this.device.product_data.RouterSecure.data.status.mac_addresses
                    return macs ? macs : []
                }
                catch(e){
                    return []
                }
            }
        },
        showStatus:{            
            get(){
                return this.versionList.length > 0 && 
                        (this.device.product_data.RouterSecure.data.configuration || this.device.product_data.RouterSecure.data.status)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .prefix {
      padding-right: 10px; 
      margin-left: 15px;
      text-transform: uppercase;
      opacity: 0.7!important;
      margin-bottom: 15px;
    }
    .space_left {
        margin-left: 15px;
    }
    .button-router{
        min-width: 120px; 
        max-width: 20%;float:left;
    }
    #modal-update .modal-dialog {
        max-width: 800px !important;
        max-height: 90%;
    }

    .space_between_buttons {
        padding-top: 13px;
    }

    .text-allot {
        color: #f96332 !important;
    }
    .separator {
        height: 1px;
        background-color: #e7e7e7;
        margin: 5px 20px;
        margin-bottom: 10px;
    }
    
    .reset_message{
        opacity: 0.7!important;
    }

#modal-update .modal-body {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }    
}

@media (max-width: 1200px) {
  #status_table {
      border: none ! important;
  }
}
</style>
