<template>
    <b-card no-body>
        <template v-slot:header>
            <slot name="header">
                <b-container fuild style="max-width: 2560px;" >
                <b-row no-gutters align-h="between">
                    <b-col md="12" lg="8" xl="9" class="pt-2">
                        {{t('Devices are securized by the settings of the user they belong to.')}}
                    </b-col>
                    <b-col sm=""></b-col>
                    <b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">
                        <n-button @click.native="handleNew"
                                type="primary"
                                size="md" round block :visible_for_permissions='[{actions: ["PATCH"], path: "/accounts/.*"}]'>
                                <i slot="label" >
                                    <svgicon class="icon" icon="icon-new" />
                                </i>
                            {{t('New')}} 
                        </n-button>
                    </b-col>
                </b-row>
                </b-container>
            </slot>
        </template>

        <b-card-body style="padding: 0px;">
            <paginated-table 
                :rows='rows' 
                :columns='realColumns' 
                :actions="actions" 
                :search="['name']"
                :searchText="'Name'"
                @item-edit="handleEdit"
                @item-remove="handleRemove"
                :serverpagination="search_pagination" 
                @prev="handlePrevPage"
                @next="handleNextPage"
                @pagination="HandlePagination"
                v-model="search_field_value"
                :updating="updating"
            />
        </b-card-body>

    </b-card>
</template>

<script>
import { PaginatedTable } from '@/components';
import { AvatarImageFormatter, NameWithIconFormatter, NameWithIconifyFormatter } from '@/components/Tables/Formatters'
import ProductIdFormatter from '../../components/Tables/Formatters/ProductIdFormatter.vue';
import {getDeviceInfo, getProductId} from "@/util/devicesUtils";
import {getUserImage} from '@/util/usersUtils';

export default {
    name: 'account-devices',
    components: {
        PaginatedTable
    },
    props: {
        account: {
            type: Object,
            default: null,
            required: true
        },
        account_users: {
            type: Map,
            default: () => new Map(),
        },
        account_user: {
            type: Object,
            default: null,
        },
        layout: {
            type: String,
            default: 'desktop',
            validator: value => {
                return [ 'mobile', 'tablet', 'desktop'].includes(value);
            }
        }
    },
    data() {
        return {
            columns: [
                {
                    prop: 'namewithicon',
                    label: this.t('Name'),
                    minWidth: 200,
                    formatter: NameWithIconifyFormatter
                },
                {
                    prop: 'product',
                    label: this.t('Product'),
                    minWidth: 125,
                    formatter: NameWithIconFormatter
                },
                {
                    prop: 'product_id',
                    label: this.t('Product ID'),
                    minWidth: 125,
                    formatter: ProductIdFormatter
                },
                {
                    prop: 'user',
                    label: '',
                    width: 75,
                    formatter: AvatarImageFormatter
                },
                {
                    prop: 'username',
                    label: this.t('User'),
                    minWidth: 125,
                },
            ],
            actions: {
                minWidth: 100,
                label: this.t('Actions'),
                fixed: 'right',
                items: [
                    {type: 'warning', icon: 'now-ui-icons business_badge', event: 'item-edit', action: this.t('Edit')},
                    {type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'item-remove', action: this.t('Remove'), confirm: true, visible_for_permissions: [{actions: ["DELETE"], path: "/accounts/.*/devices"}]},
                ]
            },
            query: {
                sort: "asc",
                limit: 10,
            },
            self_query: {},
            currentPage: 1,
            nextPage: 0,
            account_devices: new Map(),
            updating: false
        }
    },
    mounted() {
        this.refresh();
    },
    computed: {
        realColumns: {
            get(){
                if (this.layout == 'desktop'){
                    return this.columns
                }
                else if ( this.layout == 'tablet'){
                    return [ this.columns[0], this.columns[1]]
                }
                else{
                    return [ this.columns[0]]
                }
            }
        },
        rows() {
            const devices = [];

            if (this.account_user !== null || this.account_users.size > 0) {
                this.account_devices.forEach((device, key) => {
                    if (_.get(device, "product_data.CBEndpoint.cancelled")) {
                        return;
                    }
                    if (this.account_user === null || this.account_user.user_id === device.user_id) {
                        const user = this.account_user 
                            ? this.account_user
                            : this.account_users.get(device.user_id)
                                ? this.account_users.get(device.user_id)
                                : this.defaultuser;
                        const image = getUserImage(user.user_metadata.picture);
                        const name = user.name;
    
                        const deviceConnectivity =
                            device.product_data.EndpointSecure 
                            && device.product_data.EndpointSecure.hasOwnProperty("device_connectivity")
                                ? device.product_data.EndpointSecure.device_connectivity
                                : true;
    
                        const deviceDescription = deviceConnectivity
                            ? device.description
                            : `${device.description} (${this.t("UNREACHABLE")})`;
    
                        let item_product = {}
                        let product = null
                        try{
                            product = Object.entries(device.product_data).filter(element => element[1].provisioned)[0][0]
                            item_product = {
                                name: product,
                                svgicon: this.productIconClass(product),
                                size: '34',
                                tooltip: null
                            }
                        }
                        catch(e){
                            product = null
                            item_product = {}
                        }
    
                        const iconType = getDeviceInfo(device.type);
                        let merge_state = ""
                        if(
                            device.product_data 
                            && device.product_data.hasOwnProperty("RouterSecure")
                            && device.product_data.RouterSecure.data
                            && device.product_data.RouterSecure.data.hasOwnProperty("merge_info")
                        )  
                            merge_state = device.product_data.RouterSecure.data.merge_info.merge_state
    
                        const item = {
                            row_id: device.device_id,
                            device_id: device.device_id,
                            account_id: device.account_id,
                            name: device.description,
                            namewithicon: {
                            name: deviceDescription,
                            icon: iconType.icon,
                            svgiconoriginal: this.deviceIsOriginalIcon(device.type),
                            tooltip: null,
                            iconColor: deviceConnectivity ? "#4cb18e" : "#ccc",
                            textColor: deviceConnectivity ? "" : "#ccc",
                            },
                            product: item_product,
                            product_id: getProductId(device, product),
                            user: { image: image, name: name, tooltip: null },
                            username: name,
                            merge_state: merge_state
                        };
                        devices.unshift(item);
                    }
                });
            }
            return devices;
        },
        defaultuser: {
            get() {
                let ret = null;
                if (!this.account_users || this.account_users.size == 0) return null;

                this.account_users.forEach((value, key) => {
                    if (value.default_user) ret = value;
                });
                return !!ret ? ret : this.account_users.values().next().value;
            },
        },
        search_field_value: {
            get() {
                if (this.query && this.query.field_value) {
                    return { field: "device_id", value: this.query.field_value };
                } else {
                    return { field: "device_id", value: "" };
                }
            },
            set(newValue) {
                this.handleSearch(newValue);
            },
        },
        search_pagination: {
            get() {
                return {
                    prev: !!this.query && !!this.query.prev_id,
                    next: !!this.query && !!this.query.next_id,
                    currentPage: this.currentPage,
                };
            },
        }
    },
    methods: {
        handleNew(){
            this.$emit('create', { type: 'devices'})
        },
        handleEdit(data){
            this.$emit('edit', { type: 'devices', id: data.row.device_id})
        },
        handleRemove(data){
            const dev =  this.account_devices.get(data.row.device_id);
            if(  dev.merge_state == "group" )
                        this.$bvModal.msgBoxConfirm(this.$t('grouped_device_delete_message'), {
                            okTitle: this.$t('accept'),
                            cancelTitle: this.$t('cancel'),
                            title: this.$t('Remove'),
                            hideHeaderClose: false
                        })
                .then(confirmed => {                        
                    if( confirmed )
                        this.$store
                            .dispatch('accountManagement/devices/removeAccountDevice', { account_id: data.row.account_id, id: data.row.device_id })
                            .then(res => {
                                this.account_devices = new Map(res);
                                delete this.self_query.prev_id
                                delete this.self_query.next_id
                                this.reload();
                            })
                            .catch(err => {
                                this.$store.commit('setError', err, { root: true });
                            });
                })
            else
                this.$store
                    .dispatch('accountManagement/devices/removeAccountDevice', { account_id: data.row.account_id, id: data.row.device_id })
                    .then(res => {
                        this.account_devices = new Map(res);
                        delete this.self_query.prev_id
                        delete this.self_query.next_id
                        this.reload();
                    })
                    .catch(err => {
                        this.$store.commit('setError', err, { root: true });
                    });
        },
        handlePrevPage() {
            if (this.query) {
                ["next_id", "next_name"].forEach((item) => {
                    if (this.query[item]) delete this.query[item];
                });
                if (this.query.prev_id) {
                    this.nextPage = -1;
                    this.refresh();
                }
            }
        },
        handleNextPage() {
            if (this.query) {
                ["prev_id", "prev_name"].forEach((item) => {
                    if (this.query[item]) delete this.query[item];
                });
                if (this.query.next_id) {
                    this.nextPage = 1;
                    this.refresh();
                }
            }
        },
        HandlePagination(value) {
            this.currentPage = 1;
            this.self_query.limit = value;
            ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
                if (this.self_query[item]) delete this.self_query[item];
            });
            this.reload();
        },
        refresh() {
            this.updating = true;
            if (this.account) {
                this.$store
                    .dispatch("accountManagement/devices/getAccountDevices", {
                        id: this.account.account_id,
                        query: this.query
                    }).then(res => {
                        this.$emit('update-entities', {type: 'devices', values: this.account_devices} );
                        this.account_devices = new Map(res.items);
                        this.query = res.query;
                        this.self_query = res.self;
                        this.currentPage += this.nextPage;
                        this.updating = false;
                    }).catch(err => {
                        this.$store.commit('setError', err, { root: true });
                        this.updating = false;
                    });
            }
        },
        reload() {
            if (this.self_query) {
                this.query = this.self_query;
                this.nextPage = 0;
                this.refresh();
            }
        },
        handleSearch: _.debounce(function (value) {
            this.currentPage = 1;
            this.nextPage = 0;
            if (value) {
                this.query.field_operator = "sw";
                this.query.field_name = value.field;
                this.query.field_value = value.value;
            } else {
                ["field_operator", "field_name", "field_value"].forEach((item) => {
                    if (this.query[item]) delete this.query[item];
                });
            }
            ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
                if (this.query[item]) delete this.query[item];
            });
            this.refresh();
        }, 500),
        productIconClass (type) {
            switch (type){
                case 'NetworkSecure': return 'item-networksecure';
                case 'RouterSecure': return 'item-homesecure';
                default: return 'device-other';
            }
        },
        deviceIsOriginalIcon(type) {
            return false;
        },
    },
}
</script>
