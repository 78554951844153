<template>
    <b-card no-body>
        <template v-slot:header>
            <slot name="header">
                <h5 class="title"><i class="tim-icons icon-double-right mr-2"/>{{t('Device configuration')}}</h5>
            </slot>
        </template>

        <b-card-body >
            <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                <b-row align-h="start" >
                    <b-col xl="8" lg="10">
                        <label class="col-form-label RS-conf-label">{{t('MAC')}}:</label>
                        <span class="form-control-static">{{ deviceHasStatus && device.product_data.RouterSecure.data.status.mac_addresses ? device.product_data.RouterSecure.data.status.mac_addresses.join(', ') : "" }}</span>
                    </b-col>
                </b-row>
                <b-row align-h="start" >
                    <b-col xl="8" lg="10">
                        <label class="col-form-label RS-conf-label">{{t('IP')}}:</label>
                        <span class="form-control-static">{{ deviceHasStatus && device.product_data.RouterSecure.data.status.ip_addresses ? device.product_data.RouterSecure.data.status.ip_addresses.join(', ') : "" }}</span>
                    </b-col>
                </b-row>
                <b-row align-h="start" >
                    <b-col xl="8" lg="10">
                        <label class="col-form-label RS-conf-label">{{t('Last seen')}}:</label>
                        <span class="form-control-static">{{ deviceHasStatus && device.product_data.RouterSecure.data.status.active_timestamp ? toLocaleString(datetimeFromISO(device.product_data.RouterSecure.data.status.active_timestamp)) : "" }}</span>
                    </b-col>
                </b-row>
                <b-row align-h="start" >
                  <b-col xl="8" lg="10">
                    <label class="col-form-label RS-conf-label">{{t('Status')}}</label>
                    <device-status-label :device="device" />
                  </b-col>
                </b-row>
            </b-container>

            <b-container fuild style="width: 100%; margin-top: 50px; padding: 0px;" class="form-group">
                <b-row>
                    <b-col sm="4" class="has-label pt-2" >
                        <h5 style="white-space: nowrap;">
                            {{ t('LAN Protection') }}
                        </h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="9" md="9" xl="6" class="has-label pt-2" >
                        <h5 style="white-space: nowrap; margin-left: 2em;">
                            {{ t('Block Shell Access') }}
                        </h5>
                    </b-col>
                    <b-col sm="2" class="ml-1 float-left" >
                        <n-switch
                            v-model="block_remote_shell_access"
                        />
                    </b-col>
                </b-row>
                <b-row v-if="false">
                    <!-- Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1 -->
                    <b-col sm="9" md="9" xl="6" class="has-label pt-2" >
                        <h5 style="white-space: nowrap; margin-left: 2em;">
                            {{ t('Weak Telnet Password') }}
                        </h5>
                    </b-col>
                    <b-col sm="2" class="ml-1 float-left" >
                        <n-switch
                            v-model="device.product_data.RouterSecure.data.configuration.enable_telnet_credentials_scan"
                            @input="toggle($event, 'enable_telnet_credentials_scan')"
                        />
                    </b-col>
                </b-row>
                <b-row v-if="false">
                    <!-- Hidden because HomeSecure does not support enable/disable of this subservices in ASM 30.1 -->
                    <b-col sm="9" md="9" xl="6" class="has-label pt-2" >
                        <h5 style="white-space: nowrap; margin-left: 2em;">
                            {{ t('Brute Force / Port Scan Detection') }}
                        </h5>
                    </b-col>
                    <b-col sm="2" class="ml-1 float-left">
                        <n-switch
                            v-model="device.product_data.RouterSecure.data.configuration.enable_device_scan_detection"
                            @input="toggle($event, 'enable_device_scan_detection')"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="9" md="9" xl="6" class="has-label pt-2" >
                        <h5 style="white-space: nowrap; margin-left: 2em;">
                            {{ t('Limit access to other devices') }}
                        </h5>
                    </b-col>
                    <b-col sm="2" class="ml-1 float-left" >
                        <n-switch
                            v-model="block_device_access_detection"
                        />
                    </b-col>
                </b-row>
            </b-container>
        </b-card-body>
    </b-card>
</template>


<script>
import _ from 'lodash';
import { Switch } from '@/components';
import { mapActions }   from 'vuex';
import DeviceStatusLabel from './components/DeviceStatusLabel';

export default {
    name: 'routersecure-info',
    components: {
        [Switch.name]: Switch,
        [DeviceStatusLabel.name]: DeviceStatusLabel,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        }
    },
    methods: {
		...mapActions('accountManagement/devices', ['partialUpdateAccountDevice']),
        partialUpdate(value, name){
             let device = {
                device_id: this.device.device_id,
                product_data: {
                    RouterSecure: {
                        data: {
                            configuration: {}
                        }
                    }
                }
            }
            device.product_data.RouterSecure.data.configuration[name] = value;
            this.partialUpdateAccountDevice(device);
        },
        toggle(isEnabled, name) {
           this.partialUpdate(isEnabled, name)
        }
    },
    computed: {
        device: {
            get() {
                return this.value;
            },
            set(data) {}
        },
        deviceHasStatus: {
            get() {
                return !!(this.device && this.device.product_data && this.device.product_data.RouterSecure
                    && this.device.product_data.RouterSecure.data && this.device.product_data.RouterSecure.data.status)
            }
        },
        block_remote_shell_access: {
            get(){
                return !!!this.device.product_data.RouterSecure.data.configuration.allow_remote_shell_access
            },
            set(data){
               this.device.product_data.RouterSecure.data.configuration.allow_remote_shell_access = !!!data
               this.partialUpdate(!!!data, "allow_remote_shell_access")
            }
        },
        block_device_access_detection: {
            get(){                
                return this.device.product_data.RouterSecure.data.configuration.enable_device_access_detection
            },
            set(data){
               this.device.product_data.RouterSecure.data.configuration.enable_device_access_detection = data
               this.partialUpdate(data, "enable_device_access_detection")
            }
        }      
    }
}
</script>

<style lang="scss">
    .RS-conf-label {
        font-weight: bold;
        margin-right: 1em;
    }
</style>
