<template>
    <b-card class="card-user" no-body>
        <div class="author" >
            <div class="block block-one" ></div>
            <div class="block block-three" ></div>
            <div class="block block-four" ></div>
        </div>

        <b-card-body style="margin-top: -40px;max-height: 75px;min-height: 75px;">
            <div style="display: flex;max-width: 2560px;max-height: 60px;" >
                <fg-input v-model="id"
                        id="id"
                        v-validate="'required|min:6'"
                        name="Account ID"
                        :error="getError('username')"
                        class="no-border form-control-lg"
                        placeholder="Account ID"
                        addon-left-icon="now-ui-icons users_single-02"
                        autocapitalize="none"
                        style="font-size:1rem;max-width: 70%; margin: 10px auto 20px auto;"
                        readonly />
                    <n-button v-if="remove" v-b-modal.modal-delete class="mt-3" style="margin-left: auto; min-width: 120px; max-width: 20%;z-index: 1;"
                            type="primary"
                            size="md" round block
                            :visible_for_permissions="visible_for_permissions_remove_account">
                        <i slot="label" class="now-ui-icons ui-1_simple-remove"/>
                            {{ t('Remove') }} 
                    </n-button>
                    <n-button v-if="goto" class="mt-3" style="margin-left: auto; min-width: 120px; max-width: 20%;z-index: 1;"
                            type="primary"
                            size="md" round block
                            @click.native="handleGoTo"
                            :visible_for_permissions="visible_for_permissions_go_to_account">
                            {{ t('Go to Account') }} 
                    </n-button>
                    <n-button v-if="close" class="mt-3" style="margin-left: auto; min-width: 120px; max-width: 20%;z-index: 1;"
                            type="primary"
                            size="md" round block
                            @click.native="handleClose">
                            {{ t('Close') }} 
                    </n-button>
            </div>

            <b-modal id="modal-delete" ref="modal" 
                      @ok="handleDelete" :title="t('Remove Account')" :ok-title="t('accept')" :cancel-title="t('cancel')">
                      {{ t('changes_are_local') }}
            </b-modal>                        

        </b-card-body>

        <slot name="content" />

        <template v-slot:footer>
            <div class="hr-grey mb-2"/>
            <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin"/>
                {{ t('Updating...') }}
            </div>
        </template>

    </b-card>
</template>

<script>

export default {
    name: 'account-title',
    props: {
        id: { 
            type: String,
            required: true
        }, 
        remove: { 
            type : Boolean, 
            default: false
        },
        goto: { 
            type : Boolean, 
            default: false
        },
        close: { 
            type : Boolean, 
            default: false
        },
        visible_for_permissions_remove_account: { 
            type: Array, 
            default: function () { return [] }
        },
        visible_for_permissions_go_to_account: { 
            type: Array, 
            default: function () { return [] }
        }, 
        type: { 
            type : String, 
            default: ''
        }
    },
    data() {
        return {
            updating: false,
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        handleDelete(){
            this.$emit('remove', this.id)
        },
        refresh(wait){
            this.updating = true;
            this.$emit('refresh')
            setTimeout( () => {
			    this.updating = false
		    }, 2000);
        },
        handleGoTo(){
            //this.$router.push({ name: 'Account Management / Accounts / Edit', params: {id: this.id}})
            this.$router.push({
                path:
                "/account_management/accounts/edit/" +
                encodeURIComponent(this.id),
                query: { type: this.type },
            });
        },
        handleClose(){
            window.close();
        }
    },
}
</script>
