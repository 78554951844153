<template>
  <b-card no-body>
    <template v-slot:header>
      <slot name="header"></slot>

      <b-container fuild style="max-width: 2560px;">
        <b-row no-gutters align-h="between">
          <b-col md="12" lg="8" xl="9" class="pt-2">
            {{ userText }} {{ t('hold a set of devices with a common security configuration given by their profile.') }}
          </b-col>
          <b-col sm=""></b-col>
          <b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">
            <n-button @click.native="handleNew"
                      type="primary"
                      size="md" round block :visible_for_permissions='[{actions: ["PATCH"], path: "/accounts/.*"}]'>
              <i slot="label">
                <svgicon class="icon" icon="icon-new"/>
              </i>
              {{ t('New') }}
            </n-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-card-body style="padding: 0px;">
      <paginated-table :rows='rows'
                       :columns='realColumns'
                       :actions="actions"
                       :search="['name']"
                       :searchText="'Name'"
                       @item-edit="handleEdit"
                       @item-remove="handleRemove"
                       :serverpagination="search_pagination"
                       @prev="handlePrevPage"
                       @next="handleNextPage"
                       @pagination="HandlePagination"
                       v-model="search_field_value"
                       :updating="updating"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {PaginatedTable} from '@/components';
import {AvatarImageFormatter, ProfileFormatter} from '@/components/Tables/Formatters';
import {getUserImage} from '@/util/usersUtils';

export default {
  name: 'account-users',
  components: {
    PaginatedTable
  },
  props: {
    account: {
      type: Object,
      default: null,
      required: true
    },
    layout: {
      type: String,
      default: 'desktop',
      validator: value => {
        return ['mobile', 'tablet', 'desktop'].includes(value);
      }
    },
  },
  data() {
    return {
      columns: [
        {
          prop: 'avatar',
          label: '',
          width: 85,
          formatter: AvatarImageFormatter
        },
        {
          prop: 'name',
          label: this.t('Name'),
          minWidth: 185
        },
        {
          prop: 'profile',
          label: this.t('Profile'),
          minWidth: 185,
          formatter: ProfileFormatter
        },
      ],
      actions: {
        minWidth: 100,
        label: this.t('Actions'),
        fixed: 'right',
        items: [
          {type: 'warning', icon: 'now-ui-icons business_badge', event: 'item-edit', action: this.t('Edit')},
          {
            type: 'danger',
            size: 'sm',
            icon: 'now-ui-icons ui-1_simple-remove',
            event: 'item-remove',
            action: this.t('Remove'),
            confirm: true,
            visible_for_permissions: [{actions: ["DELETE"], path: "/accounts/.*/users"}]
          },
        ]
      },
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {},
      currentPage: 1,
      nextPage: 0,
      account_users: new Map(),
      updating: false,
    }
  },
  mounted() {
    this.refresh();
  },
  computed: {
    realColumns: {
      get() {
        if (this.layout == 'desktop') {
          return this.columns
        } else if (this.layout == 'tablet') {
          return [this.columns[0], this.columns[1], this.columns[2]]
        } else {
          return [this.columns[0], this.columns[1]]
        }
      }
    },
    rows() {
      let ret = [];
      this.account_users.forEach((value, key) => {
        let item = {
          account_id: value.account_id,
          user_id: value.user_id,
          name: value.name,
          profile: {
            name: this.$store.getters[
              "accountManagement/profiles/userProfileName"
              ](value, this.account),
            deleted: !this.$store.getters[
              "accountManagement/profiles/isProfileDefinedforAccount"
              ](this.account, value.profile.profile_definition_id),
            modified: this.$store.getters[
              "accountManagement/profiles/userProfileIsModified"
              ](value, this.account),
          },
          avatar: {
            image: getUserImage(value.user_metadata.picture),
            name: value.name,
            icon: value.default_user ? "icon icon-info" : null,
            svgicon: value.default_user ? "star" : null,
            tooltip: null,
          },
        };
        ret.push(item);
      });
      let arRet = Array.from(ret).sort((a, b) => {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
      return arRet;
    },
    userText() {
      return this.$store.getters['settings/isIOT'] ? this.t('Groups') : this.t('Users');
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value) {
          return { field: "user_id", value: this.query.field_value };
        } else {
          return { field: "user_id", value: "" };
        }
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
  },
  methods: {
    handleNew() {
      this.$emit('create', {type: 'users'})
    },
    handleEdit(data) {
      this.$emit('edit', {type: 'users', id: data.row.user_id})
    },
    handleRemove(data) {
        if (this.account_users.get(data.row.user_id).default_user) {
          this.$store.commit("setError", this.t("cannot_delete_default_user"));
          return;
        }
      this.$store
        .dispatch("accountManagement/users/removeAccountUser", {
          account_id: data.row.account_id,
          id: data.row.user_id })
        .then(res => {
          this.account_users = new Map(res);
          this.reload();
        })
        .catch(err => {
          this.$store.commit('setError', err, { root: true });
        });
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.nextPage = 1;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    refresh() {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("accountManagement/users/getAccountUsersByQuery", {
          id: this.account.account_id,
          query: this.query
        })
        .then(res => {
          this.$emit('update-entities', {type: 'users', values: this.account_users} );
          _vm.account_users = new Map(res.items);
          _vm.query = res.query;
          _vm.self_query = res.self;
          this.currentPage += this.nextPage;
          this.updating = false;
        })
        .catch(err => {
          this.$store.commit('setError', err, { root: true });
          this.updating = false;
        });
    },
    reload() {
      if (this.self_query) {
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
  },
}
</script>
