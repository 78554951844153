<template>
    <form @submit.prevent="">
        <b-container fuild style="width: 100%; padding: 0px;float:left" >
            <b-row  >
                <b-col cols="4" sm="6" class="has-label pt-2">  
                 <label >
                   {{ t('Router Protection') }}
                 </label>
                </b-col>
                <b-col sm="4" class="ml-1 float-left" >  
                  <n-switch :disabled="sdisabled"
                     v-model="routerProtection" />
                </b-col>
             </b-row> 

             <b-row>                        
                <b-col cols="4"  sm="6" class="has-label pt-2" >
                    <label style="white-space: nowrap; margin-left: 2em;">
                    {{ t('Router Allowed List Enforcement') }}
                    </label>
                </b-col>
                <b-col  sm="2" class="ml-1 float-left" v-if="device.product_data.RouterSecure.data.configuration">
                    <n-switch v-model="device.product_data.RouterSecure.data.configuration.enable_allowed_list_enforcement"  :disabled="subsEnabled" />
                </b-col>                          
             </b-row>

             <b-row>                        
                <b-col cols="4" sm="6" class="has-label pt-2" >
                    <label style="white-space: nowrap; margin-left: 2em;">
                    {{ t('Router DNS Enforcement') }}
                    </label>
                </b-col>
                <b-col  sm="2" class="ml-1 float-left" v-if="device.product_data.RouterSecure.data.configuration" >
                    <n-switch v-model="device.product_data.RouterSecure.data.configuration.enable_dns_settings_enforcement" :disabled="subsEnabled" />
                </b-col>                        
             </b-row>                   
        </b-container>
    </form>
</template>

<script>
import { Switch } from '@/components';


export default {
    name: 'routersecure-router-protection',      
    components: {              
        [Switch.name]: Switch        
    }, 
    props: {
        value: {
            type: Object,
            required: true 
        },
    },
    data() {
        return {  
            sdisabled : true,
            routerProtection: false,  
            device: {product_data: {RouterSecure: { data : {} }}},
        }
    },
    watch: {
        value(newValue) {
            if (newValue)              
                this.device = newValue;                          
        },
    },
    mounted () {
        if (this.value){            
            this.device = this.value

            let _vm = this
            this.$store.dispatch('routerSecure/devices/getRouterProtectionCaps')
            .then( res => {                
                _vm.routerProtection = res                
            })
            .catch(err => {})  
        }
    },
    computed: {
       
        subsEnabled:{
            get(){
                return !this.routerProtection;
            }
        }
    }

}

</script>