<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card>
          <b-container fuild style="max-width: 2560px" v-if="$store.getters['settings/isIOT']">
            <b-row>
              <b-col cols="12" class="entranceFromBottom row-eq-height">
                <keep-alive>
                  <managers type="ent">
                    <div slot="header">
                      {{
                        t(
                          "Search for a given Enterprise Manager by its id, email, name or user id"
                        )
                      }}.
                    </div>
                  </managers>
                </keep-alive>
              </b-col>
            </b-row>
          </b-container>
        
          <b-container fuild style="max-width: 2560px" v-else>
            <b-row>
              <b-col cols="12" class="entranceFromBottom row-eq-height">
                <keep-alive>
                  <managers type="res">
                    <div slot="header">
                      {{
                        t(
                          "Search for a given Residential Manager by its id, email, name or user id"
                        )
                      }}.
                    </div>
                  </managers>
                </keep-alive>
              </b-col>
            </b-row>
          </b-container>
        
    </b-card>
  </div>
</template>

<script>
import Managers from "./Managers";
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "managers-page",
  components: {    
    Managers,
    PageTitleCard
  },
  data() {
    return {
      type: "Enterprise",
    };
  },
};
</script>
