<template>
    <b-container fuild style="max-width: 2560px;">
        <b-row>
            <b-col class="row-eq-height">
                <account-title goto :id="account_id" @refresh="refreshAccount"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" lg="12" xl="12" class="row-eq-height">
              <device-form v-model="account_device_model2" :users="account_users" :saveEnabled="saveEnabled" :products="account ? account.products : {}" @input="handleDevice" @refresh="refresh">
                     <div slot="header">
                        <h5 class="title"><i class="mr-2">
                            <svgicon class="icon" icon="item-devices" />
                          </i>{{t('Device Info')}}</h5>
                    </div>
                </device-form>
            </b-col>
            <!--<b-col xs="12" sm="5" lg="4" xl="3" v-if="account_device" class="entranceFromRight row-eq-height">
                <toggle-list  oneCol v-model="products" :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                    <div slot="header">
                        <h5 class="title"><i class="now-ui-icons business_briefcase-24 mr-2"/>{{t('Products')}}</h5>
                        <div v-if="products">{{t('Select products for this device')}}*:</div>
                        <div v-else>
                            <empty-state :value="this.t('No products available from account')" />
                        </div>
                    </div>
                    <div slot="footer" v-if="products">* {{t('Only for products available from account')}}</div>
                </toggle-list>
            </b-col>-->
            
            <b-col cols="12" class="row-eq-height" v-if="saveOK || realid">
            <b-card>
                <tabs type="primary"
                    v-model="realtab"
                    :vertical="$store.state.settings.mobile"
                    centered
                >
                    <tab-pane :label="t('NetworkSecure')" v-if="isnetworksecure">
                        <span slot="label">
                            <i ><svgicon class="icon" icon="item-networksecure"/></i>
                        </span>

                        <networksecure-info v-model="account_device_model" @input="handleDevice"/>
                    </tab-pane>

                    <tab-pane :label="t('EndpointSecure')" v-if="isendpointsecure">
                        <span slot="label">
                            <i class="now-ui-icons ui-1_settings-gear-63"></i>
                        </span>
                        <endpointsecure-info v-model="account_device_model" />
                    </tab-pane>

                    <template v-if="isroutersecure">
                        <template v-if="account_device && account_device.type!='mng_router'">
                            <tab-pane :label="t('RouterSecure')" id="RouterSecure">
                                <span slot="label">
                                    <i ><svgicon class="icon" icon="item-homesecure"/></i>
                                </span>

                                <routersecure-info v-model="account_device_model" @input="handleDevice"/>
            
                            </tab-pane>
                        </template>

                        <template v-else>
                            <tab-pane :label="t('RouterSecure configuration')" id="RouterSecure">
                                <span slot="label">
                                    <i ><svgicon class="icon" icon="item-homesecure"/></i>
                                </span>
                                <routersecure-router-info v-model="account_device_model"
                                        :operational_mode="rs_operational_mode"
                                        @input="handleDevice" 
                                        @command="handleRouterDeviceCommands"
                                        @refresh="refresh"/>
                            </tab-pane>

                            <tab-pane :label="t('RouterSecure attributes')" id="RouterSecure_attributes">
                                <span slot="label">
                                    <i class="now-ui-icons design_bullet-list-67" style="font-size: 1rem;"/>
                                </span>
                                <routersecure-router-attributes v-model="account_device_model" @input="handleDevice"/>
                            </tab-pane>

                            <!-- Hidden because RouterSecure does not support to enable/disable Router Protection Service in ASM 30.1 -->
                            <tab-pane :label="t('RouterSecure router protection')" v-if="false">
                                <routersecure-router-protection  v-model="account_device_model" />
                            </tab-pane>

                            <tab-pane :label="t('RouterSecure router feature flags')" 
                                        id="RouterSecure_router_feature_flags" v-if="isValidAgentPackage">
                                <div slot="label" style="font-size: 1rem;display:block;line-height:60px;" >
                                    <i class="align-items-center iconify" data-icon="akar-icons:settings-vertical" style="margin-right: 0px;"/>
                                </div>
                                <routersecure-router-flags
                                        showMode="Features"
                                        :account="account_device.account_id"
                                        :device="account_device.device_id"/>                         
                            </tab-pane>

                            <tab-pane :label="t('RouterSecure router feature logs')" 
                                        id="RouterSecure_router_feature_logs" v-if="isValidAgentPackage">
                                <span slot="label">
                                    <i class="now-ui-icons files_paper" style="font-size: 1rem;"></i>
                                </span>
                                <routersecure-router-flags
                                        showMode="Logs"
                                        :account="account_device.account_id"
                                        :device="account_device.device_id"/>                    
                            </tab-pane>
                            
                        </template>
                    </template>
               
                </tabs>


                <template v-slot:footer v-if="account_device">
                    <div class="hr-grey mb-2"/>
                    <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
                        <i class="now-ui-icons arrows-1_refresh-69" />
                        {{ t('Update now') }}
                    </div>
                    <div v-else>
                        <i class="now-ui-icons loader_refresh spin"/>
                        {{ t('Updating...') }}
                    </div>
                </template>
            </b-card>
      </b-col>
        </b-row>
        

    </b-container>    
</template>


<script>
import AccountTitle from '../Accounts/AccountTitle'
import { ToggleList, EmptyState } from '@/components';
import DeviceForm from './DeviceForm'
import NetworkSecureInfo from './NetworkSecureInfo'
import RouterSecureInfo from './RouterSecureInfo'
import EndpointSecureInfo from './EndpointSecureInfo'
import { TabPane, Tabs } from '@/components';
import { mapGetters } from 'vuex';
import RouterSecureRouterInfo from './RouterSecureRouterInfo'
import RouterSecureRouterAttributes from './RouterSecureRouterAttributes.vue'
import RouterProtection from './components/RouterProtection'
import RouterSecureRouterFlags from './RouterSecureRouterFlags'

export default {
    name: 'account-device-edit',
    components: {
        AccountTitle,
        [ToggleList.name] : ToggleList,
        [EmptyState.name] : EmptyState,
        DeviceForm,
        TabPane, 
        Tabs,
        [NetworkSecureInfo.name] : NetworkSecureInfo,
        [RouterSecureInfo.name] : RouterSecureInfo,
        [EndpointSecureInfo.name] : EndpointSecureInfo,
        [RouterSecureRouterInfo.name] : RouterSecureRouterInfo,
        [RouterSecureRouterAttributes.name]: RouterSecureRouterAttributes,
        [RouterProtection.name] : RouterProtection,
        [RouterSecureRouterFlags.name] : RouterSecureRouterFlags
    },
    props: [ "account_id", "id"],
    data() {
        return {
            updating: false,
            newid: null,
            account: null,
            account_device: null,
            account_device_model: null,
            account_device_model2: null,
            account_users: null,
            tab: 'NetworkSecure',
            saveEnabled:true,
            saveOK:false,
            device_type : ''
        }
    },
    mounted() {
        this.refreshAccount(0);
        this.refresh()
    },
    watch:{
      newid(newValue){
        let _vm = this
        this.$store.dispatch('accountManagement/users/getAccountUsers', this.account_id)
            .then( res => _vm.account_users = res)
            .catch( err => this.$router.replace('/'))
        this.$store.dispatch('accountManagement/devices/getAccountDevice', { account_id: this.account_id, device_id: newValue})
            .then( res => _vm.account_device = res)
            .catch( err => this.$router.replace('/'))
      },
      account_device(newValue){
          this.account_device_model = _.cloneDeep(newValue)
          this.account_device_model2 = _.cloneDeep(newValue)
      }
    },
    methods:{
        refreshAccount(wait){
            let _vm = this
            this.updating = true
            if (this.account_id){
                this.$store.dispatch('accountManagement/accounts/getAccount', this.account_id)
                .then( res => {
                    _vm.account = res;

                    // Only for test RS
                    //_vm.account.products['RouterSecure'] = { product_id: 'RouterSecure', provisioned: true }
                    
                    this.$store.dispatch('accountManagement/users/getAccountUsers', this.account_id)
                    .then( res => {
                        _vm.account_users = res
                        this.updating = false
                    })
                    .catch( err => this.$router.replace('/'))
                    this.updating = false
                })
                .catch( err => {
                    this.updating = false
                    this.$router.replace('/')
                });
            }
            else{
                this.updating = false
                this.$router.replace('/')
            } 
        },
        refresh(wait){
            let _vm = this
            this.updating = true
            if (this.account_id && this.id){
                this.$store.dispatch('accountManagement/devices/getAccountDevice', { account_id: this.account_id, device_id: this.id})
                .then( res => {
                    _vm.account_device = res; 
                    _vm.device_type = _vm.account_device.type                    
                    this.updating = false
                })
                .catch( err => {
                    this.updating = false
                    this.$router.replace('/')
                });
            }
        },
        handleRouterDeviceCommands(data){
            if (this.realid && this.device_type == 'mng_router' ){
                this.updating = true
                switch(data.command){
                case 'reboot_loop':
                    this.$store.dispatch('routerSecure/devices/resetRouterDevice', 
                            {account:this.account_id, device : this.realid, mode: "reboot" })
                    .then( res => {
                        this.updating = false
                    })
                    .catch( err => {
                        this.updating = false
                        this.refresh()
                    })
                    break;
                case 'safe_mode':
                    this.$store.dispatch('routerSecure/devices/resetRouterDevice', 
                            {account:this.account_id, device : this.realid, mode: "safe" })
                    .then( res => {
                        this.updating = false
                    })
                    .catch( err => {
                        this.updating = false
                        this.refresh()
                    })
                    break;
                case 'update_target_agent':
                    this.$store.dispatch('routerSecure/devices/setDeviceTargetAgent', 
                            {account: this.account_id, device : this.realid, agent: data.value })
                    .then( res => {
                        this.updating = false
                    })
                    .catch( err => {
                        this.updating = false
                        this.refresh()
                    })
                    break;
                default:
                    console.log("Unkown command (" + data.command + ")")
                }
            }
        },
        handleDevice(data){
            this.saveEnabled = false;
            let user_id = data.user_id
            if ( !user_id)
                user_id = this.account_device.user_id
            if (_.get(this.account, 'products.CBEndpoint.provisioned') && 
                    !_.get(this.account, 'products.EndpointSecure.provisioned') &&
                    user_id && this.accountUser(user_id) && 
                    !_.get(this.accountUser(user_id), 'user_metadata.birthdate', null)){
                this.$store.commit("setError", this.t('no_assigned_birthdate'));
                this.saveEnabled = true
            } else {
                if (this.realid){  
                    let _vm =  this;
                    // recreates binded account device model to avoid blinking pages
                    this.account_device_model = this.account_device
                    this.account_device_model2 = this.account_device
                    
                    _vm.updating = true
                    this.$store.dispatch('accountManagement/devices/updateAccountDevice', 
                                        { account_id: this.account_id, id: this.realid, data})
                    .then(()=> {
                        _vm.saveEnabled = true;
                        _vm.$store.commit("setSuccess", this.t('Device updated successfully'));
                        
                        _vm.updating = true
                        _vm.$store.dispatch('accountManagement/devices/getAccountDevice', { account_id: this.account_id, device_id: this.realid})
                        .then( res => {
                            _vm.account_device = res;
                            _vm.device_type = _vm.account_device.type
                            _vm.updating = false
                        })
                        .catch( err => {
                            _vm.updating = false
                        });
                     })
                     .catch(err => {
                        _vm.saveEnabled = true;
                         _vm.updating = false
                     })
                }
                else{
                    if ( this.account){
                        let _vm = this
                        
                        this.$store.dispatch('accountManagement/devices/createAccountDevice', {account_id: this.account_id, data: data}).then( res => {                            
                            _vm.newid = res;                            
                            this.saveEnabled = true;  
                            this.saveOK = true;
                            this.$store.commit("setSuccess", this.t('Device created successfully'));                             
                        })
                        .catch( err => {
                            this.saveEnabled = true;  
                            this.saveOK = false                            
                        });
                    }
                }
            }            
        },
        isProductProvisioned(product){
            try{
                return this.account_device.product_data[product].provisioned
            }
            catch(err){
                return false
            }
        },
    },
    computed: {
        ...mapGetters('accountManagement/users',['accountUser']),
        realid(){
          return this.id || this.newid
        },
        products: {            
             get() {
                 let ret = {}
                 if ( this.account && this.account_device){
                     Object.keys(this.account.products).forEach( key => {
                         if ( this.account.products[key].provisioned){
                             ret[key] = { enabled: !!this.account_device.product_data[key] && this.account_device.product_data[key].provisioned}
                         }
                     })
                 }
                 if ( Object.keys(ret).length == 0)
                     return null
                 else
                     return ret        
             },
            //set(newValue){
            //   let update = {
            //        account_id: this.account_id,
            //        product_data: {}
            //    }
            //    Object.keys(this.account.products).forEach( key => {
            //        update.product_data[key] = { provisioned: false}
            //    });
            //    Object.keys(newValue).forEach( key => {
            //        update.product_data[key] = { provisioned: newValue[key].enabled}
            //    })
                
            //    this.$store.dispatch('accountManagement/devices/updateAccountDevice', {
            //      account_id: this.account_id,
            //      id: this.realid,
            //      data: update
            //    })
            //}
        },
        nproducts: {
            get() {
                let ret = 0
                if (!this.products)
                    return ret
                
                Object.keys(this.products).forEach ( key => {
                    if (key != 'HomeSecure' && this.products[key].enabled)
                        ret += 1
                });
                return ret
            }
        },
        isnetworksecure: {
            get() {
                return this.isProductProvisioned('NetworkSecure')
            }
        },
        isendpointsecure: {
            get() {
                return this.isProductProvisioned('EndpointSecure')
            }
        },
        isroutersecure: {
            get() {
                return this.isProductProvisioned('RouterSecure')
            }
        },
        isdnsecure: {
            get() {
                return this.isProductProvisioned('DNSecure')
            }
        },
        realtab: {
            get() {
                if (!this.account_device)
                    return this.tab
                if ( this.isProductProvisioned(this.tab.split('_')[0])){
                    return this.tab
                }

                [ 'NetworkSecure', 'EndpointSecure', 'RouterSecure', 'DNSecure'].forEach (product => {
                    if (this.isProductProvisioned(product))
                        this.tab = product
                })             
                return this.tab
            },
            set(newValue){
                this.tab = newValue
            }
        },
        isValidAgentPackage: {
            get() {                
                return (_.get(this.account_device.product_data.RouterSecure.data, "status.current_agent_package_id", "") != "");
            }
        },
        rs_operational_mode: {
            get() {
                return _.get(this.account, "products.RouterSecure.account_operational_mode", "security_mode")
            }
        }

    }
}
</script>


<style lang="scss" scoped>
    ::v-deep .el-collapse-item__wrap {
      border-bottom: unset;
    }
    
    ::v-deep .el-collapse-item__arrow {
      display: none;
    }
    ::v-deep div[role="tab"] {
      display: none;
    }
    
    ::v-deep .el-collapse-item__wrap {
      background-color: unset;
      border-bottom: unset;
    }
    ::v-deep .el-collapse {
      border-top: unset;
      border-bottom: unset;
    }
</style>