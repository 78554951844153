<template>
  <b-card no-body>
    <template v-slot:header>
      <slot name="header"></slot>

      <b-container fuild style="max-width: 2560px">
        <b-row no-gutters align-h="between">
          <b-col md="12" lg="8" xl="9" class="pt-2">
            {{
              t(
                "Groups hold a set of devices with a common security configuration given by their profile."
              )
            }}
          </b-col>
          <b-col sm=""></b-col>
        </b-row>
      </b-container>
    </template>

    <b-card-body style="padding: 0px">
      <paginated-table
        :rows="rows"
        :columns="realColumns"
        :search="[
          { value: 'group_id', key: 'group_id', label: t('group_id') },
          { value: 'name', key: 'name', label: t('Name') }
        ]"
        :serverpagination="search_pagination"
        @prev="handlePrevPage"
        @next="handleNextPage"
        @pagination="HandlePagination"
        v-model="search_field_value"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { PaginatedTable } from "@/components";

export default {
  name: "account-groups",
  components: {
    PaginatedTable,
  },
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    /*groups : {
      type: Array,
      default: () => [],
    },*/
    layout: {
      type: String,
      default: "desktop",
      validator: (value) => {
        return ["mobile", "tablet", "desktop"].includes(value);
      },
    },
  },
  data() {
    return {
      columns: [
        {
          prop: "group_id",
          label: "group_id",
          minWidth: 140,
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 120,
        },
        {
          prop: "total_group",
          label: "Total",
          minWidth: 100
        },
      ],
      query: {
        sort: "asc",
        limit: 10,
        include_totals: true
      },
      self_query: {},
      currentPage: 1,
      nextPage: 0,
      account_groups: new Map(),
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    realColumns: {
      get() {
        if (this.layout == "desktop") {
          return this.columns;
        } else if (this.layout == "tablet") {
          return [this.columns[0], this.columns[1], this.columns[2]];
        } else {
          return [this.columns[0], this.columns[1]];
        }
      },
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: "group_id", value: this.query.field_value };
        else return { field: "group_id", value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
    rows() {
      const ret = [];
      this.account_groups.forEach((value, key) => {
        const item = {
          name: value.name,
          group_id: value.group_id,
          total_group: value.total_group
        };

        ret.push(item);
      });
      /*const arRet = Array.from(ret).sort((a, b) => {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
      return arRet;*/
      return ret;
    },
  },
  methods: {
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.nextPage = 1;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        if (value.field == "group_id") this.query.field_name = "group_id";
        else if (value.field == "name") this.query.field_name = "name";
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
    refresh() {
      let _vm = this;
      this.$store
        .dispatch("groups/getGroups", {
          account_id: this.id,
          query: this.query,
        })
        .then((res) => {
          _vm.account_groups = res.items;
          _vm.query = res.query;
          _vm.self_query = res.query;
          _vm.currentPage += _vm.nextPage;
          _vm.updating = false;
        })
        .catch((err) => {
          console.log(err);
          _vm.updating = false;
        });
    },
    reload() {
      if (this.self_query) {
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
  },
};
</script>
