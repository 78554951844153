<template functional>
  <div v-if="typeof props.row[props.column.prop] === 'object' && props.row[props.column.prop] !== null">
      <div v-if="props.row[props.column.prop].mac && props.row[props.column.prop].mac.length > 0">
        <span class="text-primary">{{`MAC:`}}</span>
          <template v-for="(value,index) in props.row[props.column.prop].mac">
           <template v-if="index == 0">
               {{`${value}`}}
           </template>
           <template v-else>
             {{value}}
           </template>   
          </template>
      </div>
        <div v-if="props.row[props.column.prop].serial && props.row[props.column.prop].serial.length > 0 && props.row[props.column.prop].serial[0] !== '' ">
         <span class="text-primary">{{`SN:`}}</span>
         <template v-for="(value,index) in props.row[props.column.prop].serial">
           <template v-if="index == 0">
               {{`${value}`}}
           </template>
           <template v-else>
             {{value}}
           </template>   
          </template>
      </div>
  </div>
  <div v-else>
    {{props.row[props.column.prop]}}
  </div>  
</template>

<script>
  export default {
    name: 'product_id-formatter',
  }
 
</script>