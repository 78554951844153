<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="headerText"
    >
      <template #button>
        <n-button
          id="button-new"
          type="primary"
          size="md"
          round
          block
          v-b-modal.modal-new
          :visible_for_permissions="[
                { actions: ['POST'], path: '/accounts/.*' },
              ]"
        >
          <i slot="label">
            <svgicon class="icon" icon="icon-new" />
          </i>
          {{ t("New") }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px">
        <paginated-table
          id="account-table"
          :rows="rows"
          :columns="calculatedColumns"
          :actions="actions"
          :itemsPerPage="query.limit"
          :searchRestrictions="{ account_id: '^[a-zA-Z0-9\-\._@]+$' }"
          :search="['account_id']"
          v-model="search_field_value"
          :updating="updating"
          :serverpagination="search_pagination"
          :searchText="this.t('Account')"
          @prev="handlePrevPage"
          @next="handleNextPage"
          @pagination="HandlePagination"
          @item-edit="handleEdit"
          @item-remove="handleRemove"
        />
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="reload"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("Update now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("Updating...") }}
        </div>
      </template>

      <b-modal
        id="modal-new"
        ref="modal"
        :title="t('New Account')"
        scrollable
        @ok="handleOk"
        @show="resetModal"
        @cancel="resetModal" :ok-title="t('accept')" :cancel-title="t('cancel')">
        <form id="form-account" class="authpages" @submit.prevent="handleOk">
          <div class="form-group has-label">
            <label>{{ t("Account id") }}</label>
            <fg-input
              v-model="model.account_id"
              v-validate="{
              required: true,
              regex: /^[a-zA-Z0-9\-\._@]+$/,
              max: 79,
            }"
              name="Account ID"
              :error="getError('Account ID')"
              class="no-border no-height"
              :placeholder="t('Account id')"
              addon-left-icon="now-ui-icons users_single-02"
              autocapitalize="none"
              style="font-size: 1rem"
            />
          </div>
          <div class="form-group has-label">
            <label>{{ t("Account type") }}</label>
            <el-select
              class="select-primary"
              v-validate="{ required: true }"
              size="large"
              :placeholder="t('Account type')"
              v-model="model.account_type"
            >
              <el-option
                v-for="option in finalAccountTypes"
                class="select-primary"
                :value="option.key"
                :label="t(option.value)"
                :key="option.key"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form-group mt-4">
            <b-row>
              <b-col xs="12" sm="6" class="row-eq-height" v-if="showProducts">
                <products v-model="products" 
                  :rs_operational_mode="rs_operational_mode"
                  @change-operational-mode="changeOperationalMode"/>
              </b-col>
              <b-col xs="12" sm="6" class="row-eq-height" v-if="showServices">
                <b-card>
                  <h5 class="title">
                    <i class="now-ui-icons files_box mr-2" />{{ t("Services") }}
                  </h5>
                  <h5 v-if="services_with_only_rs_analytics" id="message-services-with-only-analytics" class="danger title">
                    {{ t('These settings will have no effect because analytics mode is active')}}
                  </h5>

                  <div>{{ t("Select services for account") }}:</div>
                  <b-container fluid style="padding: 0px">
                    <b-row no-gutters>
                      <b-col
                        :sm="12"
                        v-for="(value, name) in services"
                        :key="name"
                      >
                        <b-container fluid style="padding: 0px">
                          <b-row no-gutters>
                            <b-col
                              :cols="8"
                              class="form-group has-label pt-2"
                              align-self="center"
                            >
                              <h5
                                class="mx-auto my-auto"
                                style="white-space: nowrap"
                              >
                                {{ value.name }}
                              </h5>
                            </b-col>
                            <b-col cols="2" align-self="center">
                              <n-switch
                                v-model="value.enabled"
                                :disabled="!value.available || !available_services.includes(name)"
                                :service="name"
                                @input="toggle($event, name)"
                                color="allot"
                              />
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card>
              </b-col>
              <b-col md="12" lg="12" class="row-eq-height" v-if="showProfiles">
                <profiles
                  v-model="profiles"
                  :oneCol="!manyprofiles"
                  :twoCols="manyprofiles"
                  :content_around="!manyprofiles"
                  v-if="showProfiles"
                >
                  <div slot="header">
                    <h5 class="title">
                      <i class="now-ui-icons design_bullet-list-67 mr-2" />{{
                        t("Profiles")
                      }}
                    </h5>
                  </div>
                </profiles>
              </b-col>
              <b-col
                md="12"
                lg="12"
                class="row-eq-height"
                v-if="showBlockingPages"
              >
                <blocking-pages
                  v-model="blockingpages"
                  ref="validateMe"
                  :service="'-'"
                  :visible_for_permissions="[{ actions: ['PATCH'], path: '/accounts/.*' }]"
                />
              </b-col>
            </b-row>
          </div>
          <div>{{ t($options.warningtext) }}</div>
        </form>
      </b-modal>

      <b-modal
        id="confirm-create"
        ref="confirmCreate"
        @ok="handleSubmit"
        @cancel="resetModal"
        :title="t('create_account')" :ok-title="t('accept')" :cancel-title="t('cancel')">
        {{ t("changes_are_local") }}
      </b-modal>

      <b-modal
        ok-only
        id="no-profile-warning"
        ref="noProfileWarning"
        :title="t('create_account')"
      >
        {{ t("no_profile_warning") }}
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { PaginatedTable, ToggleList } from "@/components";
import {
  YesNoListFormatter,
  ListFormatter,
} from "@/components/Tables/Formatters";
import { Select, Option } from "element-ui";
import Profiles from "./Profiles.vue";
import Products from "./Products.vue";
import { Switch } from "@/components";
import BlockingPages from "@/components/BlockingPages/BlockingPages.vue";
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex"
import { ANALYTICS_MODE } from "./util.js";

export default {
  name: "accounts",
  components: {
    [Switch.name]: Switch,
    PaginatedTable,
    [Option.name]: Option,
    [Select.name]: Select,
    [ToggleList.name]: ToggleList,
    Profiles,
    Products,
    BlockingPages,
    PageTitleCard
  },
  warningtext: "changes_are_local",
  data() {
    return {
      available_services: [],
      model: {
        account_id: "",
        account_type: "enterprise_groups",
        products: {},
        services: {},
        profiles: {},
        blockingpages: null,
      },
      typeOptions: [
        {
          value: "Residential",
          key: "residential",
        },
        {
          value: "Enterprise groups",
          key: "enterprise_groups",
        },
        {
          value: "Business",
          key: "business",
        },
      ],
      finalAccountTypes : [],
      updating: false,
      accounts: new Map(),
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {},
      currentPage: 1,
      nextPage: 0,
      columns: [
        {
          prop: "account_id",
          label: this.t("Account"),
          minWidth: 175,
        },
        {
          prop: "products",
          label: this.t("Products"),
          minWidth: 150,
          formatter: YesNoListFormatter,
        },
        {
          prop: "services",
          label: this.t("Services"),
          minWidth: 170,
          formatter: YesNoListFormatter,
        },
        {
          prop: "profiles",
          label: this.t("Profiles"),
          minWidth: 150,
          formatter: ListFormatter,
        },
      ],
      actions: {
        minWidth: 100,
        label: this.t("Actions"),
        fixed: "right",
        items: [
          {
            type: "warning",
            icon: "now-ui-icons business_badge",
            event: "item-edit",
            action: this.t("Edit")
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: true,
            message: this.t(this.$options.warningtext),
            visible_for_permissions: [
              { actions: ["DELETE"], path: "/accounts/.*" },
            ],
          },
        ],
      },
      changingPage: false,
      asm_profiles: null,
      lastChangedProductName: null,
      invalid_servs : ["lan_protection", "router_protection"],
    };
  },
  created() {
    this.availableProducts.forEach((product) => {
      this.$set(this.model.products, product, {
        product_id: product,
        provisioned: true,
      });
    });

    this.availableServices.forEach((service) => {
      this.$set(this.model.services, service, {
        provisioned: true,
      });
    });
  },
  mounted() {    
    if( Object.keys(this.products).indexOf("RouterSecure") >=0 && this.products["RouterSecure"].enabled ) 
      this.getAdvancedConfiguration().then( (res) => {
        this.changeOperationalMode(this.advancedConfigValue("default_account_operational_mode"))
      })
    this.refresh(0);
  },
  computed: {
    ...mapGetters('routerSecure/troubleshooting', ['advancedConfigValue']),
    product_service_dict : {
      get(){
        let srvs = this.$store.getters["settings/getProductServiceDict"];
        if( _.get(srvs, "RouterSecure") )
          srvs.RouterSecure = srvs.RouterSecure.filter(item => !this.invalid_servs.includes(item) )
        return srvs
      }
    },
    products: {
      get() {
        const products = {};
        Object.keys(this.model.products).forEach((key) => {
          products[key] = {
            product_id: this.model.products[key].product_id,
            enabled: this.model.products[key].provisioned,
          };
        });

        return products;
      },
      set(val) {
        Object.keys(val).forEach((key) => {
          if (
            val[key] &&
            val[key].enabled !== this.model.products[key].provisioned
          ) {
            this.lastChangedProductName = key;
          }

        });
        this.updateAvailableServices();

        if( this.lastChangedProductName === "RouterSecure"  ){
            if( val.RouterSecure.enabled  !== this.model.products.RouterSecure.provisioned )
              this.finalAccountTypes = this.filteredTypeOptions()

            if( val.RouterSecure.enabled === false && this.model.products.RouterSecure.provisioned === true )
              this.model.account_type = this.finalAccountTypes[0].key;
        }

        Object.keys(val).forEach((key) => {
          let result = {
            ...this.model.products[key],
            product_id: val[key].product_id,
            provisioned: val[key].enabled
          }
          this.$set(this.model.products, key, result)
        });
      },
    },
    rs_operational_mode: {
        get() {
            if (_.get(this.model, "products.RouterSecure.provisioned", false)){
              let default_rs_operational_mode = this.advancedConfigValue("default_account_operational_mode")
              return _.get(this.model, "products.RouterSecure.account_operational_mode", default_rs_operational_mode)
            }
            else
              return null
        }
    },
    services_with_only_rs_analytics: {
      get() {
        // check RouterSecure product is installed
        if ( !!!this.product_service_dict.RouterSecure || this.product_service_dict.RouterSecure.length === 0)
          return false
        // check RouterSecure product is enabled for account
        if ( !!!this.products.RouterSecure || !!!this.products.RouterSecure.enabled)
          return false
        // check RouterSecure analytics mode is selected
        if (this.rs_operational_mode !== ANALYTICS_MODE)
          return false

        // search for RS services which are not provided for any other product available for account
        let analytics_services = this.product_service_dict.RouterSecure.reduce( (acc, service) => {
          if ( !!!this.services[service] || !!!this.services[service].available || !!!this.services[service].enabled)
            return acc

          let service_by_products = Object.entries(this.product_service_dict)
                .filter( item => item[0] != 'RouterSecure')
                .reduce( (acc_service, product) => {
                  if (!!!this.products[product[0]] || !!!this.products[product[0]].enabled )
                    return acc_service
                  return acc_service + (product[1].includes(service) ? 1 : 0)
                }, 0)
          return acc + (service_by_products > 0 ? 0 : 1)
        }, 0)

        return analytics_services > 0
      }
    },
    services: {
      get() {
        const services = {};

        Object.keys(this.model.services).forEach((key) => {
          services[key] = {
            enabled: this.model.services[key].provisioned,
            name: this.t(key),
            description: this.t(key + "_description"),
            available: this.checkProductsEnabled(key)
          };
        });

        return services;
      }
    },
    availableProducts() {
      return this.$store.getters["settings/getProducts"];
    },
    availableServices() {
      let srvs = this.$store.getters["settings/getServices"];
      srvs = srvs.filter(item => !this.invalid_servs.includes(item) )
      return srvs;
    },
    showProducts() {
      return !!Object.keys(this.model.products).length;
    },
    showServices() {
      return !!Object.keys(this.model.services).length;
    },
    showProfiles() {
      // console.log('showProfiles -> this.model', this.model);
      return this.model.account_type == "residential" || this.model.account_type == "business";
    },
    showBlockingPages() {
      return this.availableServices.includes("parental_control") || this.availableServices.includes("threat_protection")
    },
    rows() {
      let ret = [];
      this.accounts.forEach((value, key) => {
        // console.log('rows -> value', value);
        //   if (this.$store.getters.isIOT && value.account_type !== 'enterprise_groups') { return false; }
        if (!value) value = {};

        const accountProfiles =
          value.account_type !== "enterprise_groups"
            ? this.$store.getters[
                "accountManagement/profiles/profilesAccountName"
              ](value)
            : [];

        let item = {
          account_id: value.account_id,
          products: {},
          services: {},
          profiles: accountProfiles,
        };

        Object.keys(value.products).forEach((key) => {
          if (!this.availableProducts.includes(key)) return false;

          item.products[key] = {
            name: key,
            state: value.products[key].provisioned,
          };
        });

        Object.keys(value.services_provisioned).forEach((key) => {
          if (!this.availableServices.includes(key)) return false;

          item.services[key] = {
            name: this.t(key),
            state: value.services_provisioned[key].provisioned,
          };
        });

        ret.push(item);
      });

      let arRet = this.search_pagination
        ? ret
        : Array.from(ret).sort((a, b) => {
            return a.account_id.toUpperCase() > b.account_id.toUpperCase()
              ? 1
              : -1;
          });
      return arRet;
    },
    calculatedColumns() {
      const columns = this.columns.reduce((accumulator, column) => {
        let count = 0;

        this.rows.forEach((row) => {
          if (!!row[column.prop]) count += Object.keys(row[column.prop]).length;
        });

        if (!count) {
          return accumulator;
        }

        return [...accumulator, column];
      }, []);

      return columns;
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: "account_id", value: this.query.field_value };
        else return { field: "account_id", value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },

    headerText() {
      let text = this.$store.getters["settings/isIOT"]
        ? this.t("header_text_IOT")
        : this.t("header_text");

      let tserv = [];
      Object.keys(this.services).forEach(item=>{
        tserv.push(this.t(item))
      })

      return text
        .replace("{products}", Object.keys(this.products).join(", "))
        .replace("{services}", tserv.join(", "));
    },
    profiles: {
      get() {
        let ret = {};
        if (this.account && this.account.account_type === "enterprise_groups") {
          return {};
        }
        try {
          this.asm_profiles.forEach((profile) => {
            let enabled = true;
            let name = profile.profile_definition_id;
            let description = null;
            profile.profile_i18n.forEach((lang) => {
              let lng = lang.language_id.includes("-")
                ? lang.language_id.slice(0, lang.language_id.indexOf("-"))
                : lang.language_id;
              if (lng == this.$store.getters['settings/getLanguage']) {
                name = lang.profile_name;
                description = lang.profile_description;
              }
            });
            ret[profile.profile_definition_id] = {
              name: name,
              description: description,
              enabled: enabled,
            };
          });
          //console.log('get -> this.asm_profiles', this.asm_profiles);
          return {
            default: this.asm_profiles.keys().next().value,
            profiles: ret,
          };
        } catch (err) {
          // console.log("Error_profiles", err);
          return {};
        }
      },
      set(newValue) {
        // console.log("SetNewValue", newValue);
        let update = {
          profiles: {
            profile_id_default: newValue.default,
            profiles_definition: [],
          },
        };
        Object.keys(newValue.profiles).forEach((key) => {
          if (newValue.profiles[key].enabled)
            update.profiles.profiles_definition.push(key);
        });
        this.model.profiles = update.profiles;
      },
    },
    manyprofiles: {
      get() {
        if (!this.profiles.profiles) return false;

        return Object.keys(this.profiles.profiles).length > 3;
      },
    },
    blockingpages: {
      get() {
        return this.model.blockingpages;
      },
      set(newValue) {
        this.model.blockingpages = newValue;
      },
    },
  },
  methods: {
    ...mapActions('routerSecure/troubleshooting', ['getAdvancedConfiguration']),
    changeOperationalMode(event){
      if ( !!event){
        let result = {
          ...this.model.products.RouterSecure,
          account_operational_mode: event
        }
        this.$set(this.model.products, "RouterSecure", result)
      }
    },
    filteredTypeOptions() {
      return this.typeOptions.reduce((accumulator, type) => {
        if (!this.$store.getters["settings/isIOT"] && type.key === "enterprise_groups") {
          return accumulator;
        }

        if (this.$store.getters["settings/isIOT"] && type.key === "residential") {
          return accumulator;
        }

        var rs = Object.keys(this.products).indexOf("RouterSecure") >=0 && this.products["RouterSecure"].enabled

        if ((this.$store.getters["settings/isIOT"] || !rs) && type.key === "business"  ) {
          return accumulator;
        }

        return [...accumulator, type];
      }, []);
    },

    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    toggle(state, name) {
      const model = this.model;
      let services = model.services;
      services[name].provisioned = state;
      this.model = { ...model, services };
    },
    checkProductsEnabled(service) {
      let service_available = false;
      Object.values(this.products).forEach((product) => {
        if (product.enabled) {
          if (this.product_service_dict[product.product_id] && this.product_service_dict[product.product_id].includes(service)) {
            service_available = true;
          }
        }
      })
      return service_available;
    },
    updateAvailableServices() {
      this.available_services = [];

      Object.keys(this.products).forEach((key) => {
        const services = this.product_service_dict[this.products[key].product_id];
        if (services) {
          this.available_services.push(...services);
          if (this.lastChangedProductName === key) {
            services.forEach((service) => {
              this.$set(this.model.services, service, {
                ...this.model.services[service],
                provisioned: this.checkProductsEnabled(service)
              });
            });
          }
        }
      });
    },
    resetModal() {
      if (this.$refs["confirmCreate"].isShow) return false;
      this.model.account_id = "";
      this.model.account_type = this.finalAccountTypes[0].key;
      this.blockingpages = null;
      this.$validator.reset();
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let isValidForm = await this.$validator.validateAll()
      if( this.showBlockingPages ){
        isValidForm = isValidForm && await this.$refs.validateMe.validateAll();
      }
      if (!isValidForm)
        return false;

      if (
        this.model.account_type == "residential" &&
        (!this.asm_profiles || this.asm_profiles.size == 0)
      ) {
        this.$bvModal.show("no-profile-warning");
      } else {
        this.$bvModal.show("confirm-create");
      }

      this.$nextTick(() => {
        this.$bvModal.hide("modal-new");
      });
    },
    handleSubmit() {
      this.$store
        .dispatch("accountManagement/accounts/createAccount", this.model)
        .then((id) => {
          this.$store.commit(
            "setSuccess",
            `${this.t("account_created")}: ${id}`
          );
          this.$router.push({
            path: "/account_management/accounts/edit/" + encodeURIComponent(id),
          });
        })
        .catch((err) => {
          this.$store.commit("setError", this.t(err.type));
        });

      this.$nextTick(() => {
        this.$bvModal.hide("confirm-create");
      });
    },
    newRecord() {
      this.$refs.modal.show();
    },

    handleEdit(data) {
      this.$store.commit(
        "accountManagement/accounts/setAccountActiveTab",
        "Managers"
      );
      this.$router.push({
        path:
          "/account_management/accounts/edit/" +
          encodeURIComponent(data.row.account_id),
        query: { type: this.accounts.get(data.row.account_id).account_type },
      });
    },
    handleRemove(data) {
      this.$store
        .dispatch("accountManagement/accounts/removeAccount", data.row.account_id)
        .then((res) => this.reload());
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.nextPage = 1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.nextPage = 0;
      this.changingPage = false;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
    reload() {
      if (this.self_query) {
        this.changingPage = false;
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
    refresh(wait) {
      this.updateAvailableServices();
      let _vm = this;
      this.updating = true;
      this.finalAccountTypes = this.filteredTypeOptions();

      this.$store.dispatch("accountManagement/profiles/getProfiles").then((res) => {
        // console.log('refresh -> res', res);
        this.asm_profiles = res;
        //console.log("Available_profiles", this.asm_profiles);
        // console.log('refresh -> this.asm_profiles 2', this.asm_profiles);
        let keys = Array.from(this.asm_profiles.keys());
        let update = {
          profiles: {
            profile_id_default: this.asm_profiles.keys().next().value,
            profiles_definition: keys,
          },
        };
        this.model.profiles = update.profiles;
      });
      this.$store
        .dispatch("accountManagement/accounts/getAccounts", {
          wait: wait,
          query: this.query,
        })
        .then((res) => {
          // console.log('refresh -> res', res);
          _vm.accounts = new Map(res.items);
          // console.log('refresh -> _vm.accounts', _vm.accounts);
          _vm.query = res.query;
          _vm.self_query = res.self;
          if (this.changingPage) {
            this.currentPage += this.nextPage;
          }
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });

      //Getting blocking pages
    },
  },
};
</script>

<style lang="scss" scoped>
.authpages {
  padding: 15px;
}
</style>

<style lang="scss">
#modal-new .modal-dialog {
  max-width: 800px !important;
  max-height: 90%;
}

#modal-new .modal-body {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>
