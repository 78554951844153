<template>
    <b-card no-body style="width: 100%;padding-right:0px">
        <template v-slot:header >
            <slot name="header" >
                <b-container fuild style="width: 100%; padding: 0px;">
                <b-row class="align-items-center">
                    <b-col class="ml-3">
                        <h4 class="mb-0 mr-4" style="display: flex;">
                            <span style="font-size: 0.85rem;">
                                {{ t('Add attributes to the given router to allow creating searches and perform bulk troubleshooting actions for them')}}
                            </span>                 
                        </h4>
                    </b-col>
                    <b-col cols="4" md="4" lg="3" xl="2"  class="ml-auto mt-2">
                        <n-button native-type="submit" type="primary" id="btn_submit" 
                                    round block @click.native="save" 
                                    :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                            {{ t('Save') }}
                        </n-button>

                    </b-col>
                </b-row>
                </b-container>
            </slot>

            <slot>
                <form @submit.prevent="">
                    <b-container fuild style="width: 100%;padding:0px">
    
                        <b-row class="align-items-center" v-if="device.product_data.RouterSecure.data.configuration">
                            <b-col  sm="12" style="width: 100%;padding:0px" >                         
                                <attributes-list v-model="attrs" />
                            </b-col>
                        </b-row>                   
                    </b-container>
                </form>
            </slot>

        </template>
<!--
        <b-card-body style="width: 100%;padding:0px">
            <form @submit.prevent="">
                <b-container fuild style="width: 100%;padding:0px">

                    <b-row v-if="device.product_data.RouterSecure.data.configuration">
                        <b-col  sm="12" >                         
                            <attributes-list v-model="attrs" />
                        </b-col>
                    </b-row>                   
                </b-container>
            </form>
        </b-card-body>-->

    </b-card>
</template>


<script>
import _ from 'lodash';
import AttributesList from './components/AttributesList.vue';

export default {
    name: 'routersecure-router-attributes',      
    components: {      
        AttributesList
    }, 
    props: {
        value: {
            type: Object,
            required: true 
        },
    },
    data() {
        return {   
            device: {
              product_data: {
                RouterSecure: {
                  data : {}
                }
              }
            },
        }
    },
    
    watch: {
        value(newValue) {             
            if (newValue){
                this.device = newValue
                this.refresh();
            }
        },
    },
    mounted () {        
        if (this.value){         
            this.device = this.value           
            this.refresh()
        }
    },
    methods: {
        refresh(){
            let _vm = this;

            if( this.device.device_id ){
                if(!this.device.product_data.RouterSecure.data.configuration )
                    this.device.product_data.RouterSecure.data["configuration"] = {
                        attributes : []
                    }
            }
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        changeStatus(){
            this.device.product_data.RouterSecure.data.configuration.enabled = !this.device.product_data.RouterSecure.data.configuration.enabled
        },
        async save(){            
            let attributes = [];
            
            this.attrs.forEach (item => {
                item.data.forEach(mvalue => {
                    attributes.push({name: item.type, value:mvalue})
                })
            })               

            let newRSDevice = {
                device_id: this.device.device_id,
                account_id: this.device.account_id,
                product_data: {
                    RouterSecure : {
                        data : {
                            configuration : {
                                attributes: attributes
                            }
                        }
                    }
                }
            };

            this.$emit('input', newRSDevice)

            setTimeout(() => {
                this.$validator.reset();
            }, 100)            
        },
        
    },
    computed: {
        attrs : {
            get(){
                return _.get(this.device, 'product_data.RouterSecure.data.configuration.attributes', [])
            },
            set(data){                
                this.device.product_data.RouterSecure.data.configuration.attributes = data
            }
        },  
      
    }
}
</script>

<style lang="scss" scoped>
    .prefix {
      padding-right: 10px; 
      margin-left: 15px;     
    }
    .space_left {
        padding-left: 4em;  
    }
    .button-router{
        min-width: 120px; 
        max-width: 20%;float:left;
    }
    #modal-update .modal-dialog {
        max-width: 800px !important;
        max-height: 90%;
    }
    .space_between {
        padding-top: 20px;
    }

    .space_between_buttons {
        padding-top: 13px;
    }

    .text-allot {
        color: #f96332 !important;
    }
    .separator {
        height: 1px;
        background-color: #e7e7e7;
        margin: 5px 20px;
        margin-bottom: 10px;
    }
    

#modal-update .modal-body {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }    
}
</style>   
