<template>
  <b-card no-body>
    <template v-slot:header>
      <slot name="header">
        <b-container fuild style="max-width: 2560px">
          <b-row no-gutters align-h="between">
            <b-col class="pt-2">
              {{
                t(
                  "Search for a given manager by its id, email, name or user id"
                )
              }}.
            </b-col>
          </b-row>
        </b-container>
      </slot>
    </template>

    <b-card-body style="padding: 0px">
      <paginated-table
        :rows="rows"
        id="managers-table"
        :columns="realColumns"
        :actions="actions"
        :search="[
          { value: 'manager_id', key: 'manager_id', label: t('manager_id') },
          { value: 'email', key: 'email', label: t('email') },
          { value: 'name', key: 'name', label: t('full_name') },
          { value: 'user_id', key: 'user_id', label: t('username') },
        ]"
        :searchRestrictions="{
          manager_id: '^[a-zA-Z0-9\-\._@]+$',
          email: '^[a-zA-Z0-9!#$%&\'*+\-/=?^_`{|}~\.(),:;<>@[\\]]+$',
        }"
        v-model="search_field_value"
        :itemsPerPage="query.limit"
        :updating="updating"
        :serverpagination="search_pagination"
        :buttonDisabled="buttonDisabled.bind(this)"
        @prev="handlePrevPage"
        @next="handleNextPage"
        @pagination="HandlePagination"
        @item-edit="handleEdit"
        @item-remove="handleRemove"
        @item-logas="handleLogAs"
      />
    </b-card-body>

    <template v-slot:footer>
      <div class="hr-grey mb-2" />
      <div
        @click="reload"
        v-if="!updating"
        style="cursor: pointer; display: inline"
      >
        <i class="now-ui-icons arrows-1_refresh-69" />
        {{ t("Update now") }}
      </div>
      <div v-else>
        <i class="now-ui-icons loader_refresh spin" />
        {{ t("Updating...") }}
      </div>
    </template>
  </b-card>
</template>

<script>
import { PaginatedTable } from "@/components";
import {
  ListFormatter,
  AvatarWordsFormatter,
  DateTimeFormatter,
} from "@/components/Tables/Formatters";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "managers",
  components: {
    PaginatedTable,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ["ent", "res"].includes(value.toLowerCase());
      },
    },
  },
  data() {
    return {
      updating: false,
      managers: new Map(),
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {},
      currentPage: 1,
      nextPage: 0,
      columns: [
        {
          prop: "account_id",
          label: this.t("Account"),
          minWidth: 175,
        },
        {
          prop: "manager_id",
          label: this.t("manager_id"),
          minWidth: 125,
        },
        {
          prop: "name",
          label: "",
          width: 85,
          formatter: AvatarWordsFormatter,
        },
        {
          prop: "name",
          label: this.t("Full Name"),
          minWidth: 185,
        },
        {
          prop: "email",
          label: this.t("Email"),
          minWidth: 185,
        },
        {
          prop: "user_id",
          label: this.t("Username"),
          minWidth: 185,
        },
      ],
      changingPage: false,
    };
  },
  mounted() {
    this.refresh(0);
  },
  computed: {
    ...mapState("accountManagement/accounts", ["account"]),
    ...mapGetters("accountManagement/managers", ["manager"]),
    realColumns: {
      get() {
        if (this.$store.getters["settings/layout"] == "desktop") {
          return this.columns;
        } else if (this.$store.getters["settings/layout"] == "tablet") {
          return [
            this.columns[0],
            this.columns[2],
            this.columns[3],
            this.columns[4],
          ];
        } else {
          return [this.columns[0], this.columns[3], this.columns[4]];
        }
      },
    },
    actions: {
      get() {
        return {
          minWidth: 135,
          label: this.t("Actions"),
          fixed: "right",
          items: [
            {
              type: "info",
              icon: "now-ui-icons users_circle-08",
              event: "item-logas",
              action: this.t("Log As"),
              disabledIf: !this.systemSsoProviderEnabled,
              visible_for_permissions: [
                {
                  actions: ["GET"],
                  path: "/customer-support/log-as/sso/jwt-link/.*",
                },
                {
                  actions: ["PATCH"],
                  path: "/accounts.*",
                },
              ],
            },
            {
              type: "warning",
              icon: "now-ui-icons business_badge",
              event: "item-edit",
              action: this.t("Edit")
            },
            {
              type: "danger",
              size: "sm",
              icon: "now-ui-icons ui-1_simple-remove",
              event: "item-remove",
              action: this.t("Remove"),
              confirm: true,
              confirm: true,
              visible_for_permissions: [
                { actions: ["DELETE"], path: "/accounts/.*/managers/.*" },
              ],
            },
          ],
        };
      },
    },
    systemSsoProviderEnabled: {
      get() {
        return this.$store.getters[
          `providers/${this.type}SystemSsoProviderEnabled`
        ];
      },
    },
    rows() {
      let ret = [];
      let vm = this;
      this.managers.forEach((value, key) => {
        let item = {
          account_id: value.account_id,
          manager_id: value.manager_id,
          name: value.identity.name,
          email: value.identity.email,
          user_id: value.identity.identity_instances.user_id,
          created_at: this.toLocaleString(
            this.datetimeFromISO(value.identity.created_at)
          ),
        };
        ret.push(item);
      });
      let arRet = this.search_pagination
        ? ret
        : Array.from(ret).sort((a, b) => {
            return a.user_id.toUpperCase() > b.user_id.toUpperCase() ? 1 : -1;
          });
      return arRet;
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: "manager_id", value: this.query.field_value };
        else return { field: "manager_id", value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async getSSOProvider(data) {
      await this.$store.dispatch("providers/getProviderDetails", {
        type: data.type,
        id: "IdentityProviderSSO",
      });
      return this.$store.getters[`providers/${data.type}ProviderDetails`];
    },
    async handleLogAs(data) {
      let idPrvSSOConf = await this.getSSOProvider({ type: this.type });
      this.$store
        .dispatch("accountManagement/accounts/getAccount", data.row.account_id)
        .then((res) => {
          try {
            if (idPrvSSOConf.configuration.log_as_in_frame) {
              let routeData = this.$router.resolve({
                name: "Account Management / Accounts/ Manager / Impersonate",
                params: {
                  account_id: data.row.account_id,
                  manager_id: data.row.manager_id,
                  entity: "managers",
                  account_type: account.account_type,
                },
              });
              window.open(routeData.href, "_blank");
            } else {
              const _vm = this;
              this.$store
                .dispatch("accountManagement/managers/getSSOAccountManager", {
                  entity: "managers",
                  id: data.row.manager_id,
                  type: this.type,
                })
                .then((res) => window.open(res))
                .catch();
            }
          } catch (err) {
            const _vm = this;
            this.$store
              .dispatch("accountManagement/managers/getSSOAccountManager", {
                entity: "managers",
                id: data.row.manager_id,
                type: this.type,
              })
              .then((res) => window.open(res))
              .catch();
          }
        })
        .catch(() => {
          this.refresh(0);
        });
    },
    handleEdit(data) {
      this.$router.push({
        path:
          "/account_management/accounts/edit/" +
          encodeURIComponent(data.row.account_id) +
          "/managers/edit/" +
          encodeURIComponent(data.row.manager_id),
      });
    },
    handleRemove(data) {
      this.$store
        .dispatch(
          "accountManagement/managers/getAccountManagers",
          data.row.account_id
        )
        .then((res) => {
          if (res.size <= 1) {
            this.$store.commit(
              "setError",
              this.t(
                "The manager cannot be removed: The account must have at least one manager"
              )
            );
          } else {
            this.$store
              .dispatch("accountManagement/managers/removeManager", {
                account_id: data.row.account_id,
                id: data.row.manager_id,
              })
              .then((res) => {
                this.managers = new Map(res);
                this.reload();
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {});
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.nextPage = 1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.nextPage = 0;
      this.changingPage = false;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
    reload() {
      if (this.self_query) {
        this.changingPage = false;
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("accountManagement/managers/getManagers", {
          wait: wait,
          query: this.query,
          type: this.type,
        })
        .then((res) => {
          _vm.managers = new Map(res.items);
          _vm.query = res.query;
          _vm.self_query = res.self;
          if (this.changingPage) {
            this.currentPage += this.nextPage;
          }
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });
    },
    getImageembedded(imgData) {
      if (!imgData) return null;
      if (imgData.storage_method == "embedded")
        return (
          "data:image/" + imgData.format + ";base64," + imgData.picture_base64
        );
      else return null;
    },
    getImageurl(imgData) {
      if (!imgData) return null;
      if (imgData.storage_method == "external") return imgData.picture_url;
      else return null;
    },
    getImage(imgData) {
      let img = this.getImageembedded(imgData);
      if (img) return img;
      else return this.getImageurl(imgData);
    },
    getIsSystemSsoProviderEnabled() {
      try {
        this.$store.dispatch(
          "providers/getIsSystemSsoProviderEnabled",
          this.type
        );
      } catch (e) {
        console.error("Error getting rows data--> ", e);
      }
    },
    buttonDisabled(row, event) {
      let res; 
      (!this.manager(row.manager_id).identity.identity_instances.user_id && (event == 'item-logas' || event == 'item-edit')) ? res = true : res = false;
      return res;
    }    
  },
  created() {
    this.getIsSystemSsoProviderEnabled();
  },
};
</script>

