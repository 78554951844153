var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("header",function(){return [_c('b-container',{staticStyle:{"max-width":"2560px"},attrs:{"fuild":""}},[_c('b-row',{attrs:{"no-gutters":"","align-h":"between"}},[_c('b-col',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.t( "Search for a given manager by its id, email, name or user id" ))+". ")])],1)],1)]})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"hr-grey mb-2"}),(!_vm.updating)?_c('div',{staticStyle:{"cursor":"pointer","display":"inline"},on:{"click":_vm.reload}},[_c('i',{staticClass:"now-ui-icons arrows-1_refresh-69"}),_vm._v(" "+_vm._s(_vm.t("Update now"))+" ")]):_c('div',[_c('i',{staticClass:"now-ui-icons loader_refresh spin"}),_vm._v(" "+_vm._s(_vm.t("Updating..."))+" ")])]},proxy:true}],null,true)},[_c('b-card-body',{staticStyle:{"padding":"0px"}},[_c('paginated-table',{attrs:{"rows":_vm.rows,"id":"managers-table","columns":_vm.realColumns,"actions":_vm.actions,"search":[
        { value: 'manager_id', key: 'manager_id', label: _vm.t('manager_id') },
        { value: 'email', key: 'email', label: _vm.t('email') },
        { value: 'name', key: 'name', label: _vm.t('full_name') },
        { value: 'user_id', key: 'user_id', label: _vm.t('username') } ],"searchRestrictions":{
        manager_id: '^[a-zA-Z0-9\-\._@]+$',
        email: '^[a-zA-Z0-9!#$%&\'*+\-/=?^_`{|}~\.(),:;<>@[\\]]+$',
      },"itemsPerPage":_vm.query.limit,"updating":_vm.updating,"serverpagination":_vm.search_pagination,"buttonDisabled":_vm.buttonDisabled.bind(this)},on:{"prev":_vm.handlePrevPage,"next":_vm.handleNextPage,"pagination":_vm.HandlePagination,"item-edit":_vm.handleEdit,"item-remove":_vm.handleRemove,"item-logas":_vm.handleLogAs},model:{value:(_vm.search_field_value),callback:function ($$v) {_vm.search_field_value=$$v},expression:"search_field_value"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }