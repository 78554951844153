<template>
            <form @submit.prevent="">
                <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
                    <b-row align-h="center" >
                        <ip-networks ref="ipNetworks" v-model="ips"/>
                    </b-row>
                </b-container>
            </form>
</template>


<script>
import IPNetworks from './IPNetworks';
import _ from 'lodash';


export default {
    name: 'dnsecure-info',
    components: {
        [IPNetworks.name] : IPNetworks
    },
    props: {
        value: {
            type: Object,
            required: true 
        },
    },
    data() {
        return {
            device: {product_data: {DNSecure: { subscriber_info : { } }}},
            ds_id_saved: null
        }
    },
    watch: {
        value(newValue) {
            if (newValue){
                this.device = _.cloneDeep(newValue);
                try{
                    if(!this.device.product_data.DNSecure.subscriber_info )
                        this.device.product_data.DNSecure["subscriber_info"] = {}

                    if (this.device.product_data.DNSecure.subscriber_info.subscriber_id )
                        this.ds_id_saved = this.device.product_data.DNSecure.subscriber_info.subscriber_id
                }
                catch(err){}
            }
        },
    },
    mounted () {
        if (this.value){
            this.device = _.cloneDeep(this.value);
            try{
                if (!this.device.product_data.DNSecure.subscriber_info )
                        this.device.product_data.DNSecure["subscriber_info"] = {}

                if (this.device.product_data.DNSecure.subscriber_info.subscriber_id )
                    this.ds_id_saved = this.device.product_data.DNSecure.subscriber_info.subscriber_id
            }
            catch(err){}
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save(){
            let isValid = await this.$validator.validateAll() 
                && await this.$refs.ipNetworks.$validator.validateAll();
            if (isValid){
                if (!this.newDSid){
                    try{ 
                        if( !this.device.product_data.DNSecure.subscriber_info )
                            this.device.product_data.DNSecure["subscriber_info"] = {}

                        if (this.device.product_data.DNSecure.subscriber_info.subscriber_id ) {
                            this.ds_id_saved = this.device.product_data.DNSecure.subscriber_info.subscriber_id
                            delete this.device.product_data.DNSecure.subscriber_info.subscriber_id
                        }
                    }
                    catch(err){}
                }

                this.$emit('input', this.device)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        },
        handleNewIp() {
            this.$refs.ipNetworks.handleNew();
        }
    },
    computed: {
        newDSid: {
            get(){
                return this.ds_id_saved == null
            }
        },
        ips: {
            get() {
                try{
                    if( !this.device.product_data.DNSecure.subscriber_info )
                        this.device.product_data.DNSecure["subscriber_info"] = {}

                    let _ips = this.device.product_data.DNSecure.subscriber_info.ips
                    if ( _ips)
                        return _ips
                    else
                        return []
                }
                catch(err){
                    return []
                }
            },
            set(newValue){
                if( this.device.product_data && this.device.product_data.DNSecure && !this.device.product_data.DNSecure.subscriber_info )
                        this.device.product_data.DNSecure["subscriber_info"] = {}

                this.$emit('ipChange', newValue);
            }
        }
    }
}
</script>
