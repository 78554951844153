<template>
    <toggle-list :oneCol="one" :colsNumber="6" :twoCols="twoCols" :disabled="toggleDisabled" :content_around="content_around" v-model="vprofiles" :visible_for_permissions="[{ actions: ['PATCH'], path: '/accounts/.*' }]">
        <template v-slot:header>
            <h5 class="title"><i class="now-ui-icons design_bullet-list-67 mr-2"/>{{t('Profiles')}}</h5>
            <div v-if="isprofiles">{{t('Select profiles available and default one')}}:</div>
            <div v-else>
                <empty-state value='No profiles defined yet!' />
            </div>
        </template>
        <template v-slot:footer v-if="isprofilesenabled">
            <div class="mt-1"><label>{{t('Default profile')}}</label></div>
            <el-select class="select-primary"
                size="large"
                placeholder="Default profile"
                :disabled="!permission_granted"
                v-model="vdefault">
                <template v-for="(option, key) in profiles" >
                    <el-option :key="key" v-if="option.enabled"
                        class="select-primary"
                        :value="key"
                        :label="option.name">
                    </el-option>
                </template>
            </el-select>
        </template>
    </toggle-list>
</template>

<script>
import { ToggleList } from '@/components';
import { Select, Option } from 'element-ui';
import { EmptyState } from '@/components';
import permissions from '@/mixins/permissions'

import _ from 'lodash';

export default {
    name: 'profiles',
    mixins: [permissions],
    components: {
        [ToggleList.name] : ToggleList,
        [Option.name]: Option,
        [Select.name]: Select,
        [EmptyState.name] : EmptyState
    },
    props: {
        content_around: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => { return {} }
        },
        oneCol: {
            type: Boolean,
            default: false
        },
        twoCols: {
            type: Boolean,
            default: false
        },
        toggleDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            default: '',
            profiles: {}
        }
    },
    computed: {
        one: {
            get(){
                return this.oneCol && this.$store.state.settings.desktop
            }
        },
        isprofiles(){
            return this.vprofiles && Object.keys(this.vprofiles).length > 0
        },
        isprofilesenabled(){
            return this.isprofiles && (0 < Object.keys(this.vprofiles).reduce((a,b) => this.vprofiles[b].enabled ? a + 1 : a, 0))
        },
        vprofiles: {
            get() {
                return this.profiles ? this.profiles : {}
            },
            set(newValue, oldValue){
                if ( Object.keys(newValue).length > 0 ){
                    let enables = Object.keys(newValue).reduce((a,b) => newValue[b].enabled ? a + 1 : a, 0)
                    if ( enables == 0)
                        newValue[Object.keys(newValue)[0]].enabled = true

                    if (!newValue[this.default] || !newValue[this.default].enabled){
                        Object.keys(newValue).forEach( key => {
                            if (newValue[key].enabled)
                                this.default = key
                        });
                    }
                    this.profiles = newValue
                    this.$emit('input', {default: this.default, profiles: newValue})
                }
            }
        },
        vdefault: {
            get(){
                return this.default
            },
            set(newValue){
                this.default = newValue
                this.$emit('input', {default: newValue, profiles: this.profiles})
            }
        }
    },
    watch: {
        value(newValue, oldValue) {
            if ( !!newValue){
                this.default = newValue.default;
                this.profiles = newValue.profiles;
            }
        },
    },
    mounted () {
        if ( !!this.value){
            this.default = this.value.default;
            this.profiles = this.value.profiles;
        }
    },
}
</script>
