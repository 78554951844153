<template>
  <span 
    class="label" 
    :class="isDeviceActive ? 'active-label' : 'inactive-label'"
  >
    {{ isDeviceActive ? t('Active') : t('Inactive') }}
  </span>
</template>

<script>
export default {
  name: 'device-status-label',
  props: {
    device: Object,
  },
  computed: {
    isDeviceActive() {
      return _.get(this.device, 'product_data.RouterSecure.data.status.active', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  padding: 5px 20px;
  border-radius: 20px;
}

.inactive-label {
  background-color: #fdedeb;
  color:#99190b;
}

.active-label {
  background-color: #edf8ea;
  color: #205b0b;
}    
</style>