<template>
    <b-card no-body>
        <template v-slot:header>
            <slot name="header"></slot>

            <b-container fuild style="max-width: 2560px;" >
            <b-row no-gutters align-h="between">
                <b-col md="12" lg="8" xl="9" class="pt-2">
                    {{t('Managers have access rights to the account to create users, associate devices and change security settings.')}}
                </b-col>
                <b-col sm=""></b-col>
                <b-col cols="6" md="4" lg="3" xl="2" class="ml-auto">
                    <n-button @click.native="handleNew"
                            type="primary"
                            size="md" round block :visible_for_permissions='[{actions: ["PATCH"], path: "/accounts/.*"}]'>
                            <i slot="label">
                                <svgicon class="icon" icon="icon-new" />
                            </i>
                        {{t('New')}}
                    </n-button>
                </b-col>
            </b-row>
            </b-container>
        </template>

        <b-card-body style="padding: 0px;">
            <paginated-table :rows='managers'
                            :columns='realColumns'
                            :actions="actions"
                            :buttonDisabled="buttonDisabled.bind(this)"
                            :search="[
                                {value: 'name', key: 'name', label : t('full_name')},
                                {value: 'user_id', key: 'user_id', label : t('username')}
                            ]"
                            @item-edit="handleEdit" @item-remove="handleRemove" @item-logas="handleLogAs"/>
        </b-card-body>

    </b-card>
</template>

<script>
import { PaginatedTable } from '@/components';
import { ListFormatter, AvatarWordsFormatter, DateTimeFormatter } from '@/components/Tables/Formatters'

export default {
    name: 'account-managers',
    components: {
        PaginatedTable
    },
    props: {
        id: {
            type: String,
            default: null,
            required: true
        },
        managers : {
            type: Array,
            default: () => []
        },
        layout: {
            type: String,
            default: 'desktop',
            validator: value => {
                return [ 'mobile', 'tablet', 'desktop'].includes(value);
            }
        },
        type: {
            type: String,
            default: 'res',
            validator: value => {
                return [ 'res', 'ent'].includes(value);
            }
        }
    },
    data() {
        return {
            columns: [
                {
                    prop: 'name',
                    label: '',
                    width: 85,
                    formatter: AvatarWordsFormatter
                },
                {
                    prop: 'name',
                    label: this.t('Full Name'),
                    minWidth: 185
                },
                {
                    prop: 'user_id',
                    label: this.t('Username'),
                    minWidth: 185
                },
                {
                    prop: 'created_at',
                    label: this.t('Created At'),
                    minWidth: 195,
                    formatter: DateTimeFormatter
                },
            ],

        }
    },
    computed: {
        realColumns: {
            get(){
                if (this.layout == 'desktop'){
                    return this.columns
                }
                else if ( this.layout == 'tablet'){
                    return [ this.columns[0], this.columns[1], this.columns[2]]
                }
                else{
                    return [ this.columns[0], this.columns[1]]
                }
            }
        },
        actions: {
            get() {
                return {
                    minWidth: 135,
                    label: this.t('Actions'),
                    fixed: 'right',
                    items: [
                        {
                            type: 'info', icon: 'now-ui-icons users_circle-08', event: 'item-logas', action: this.t('Log As'),
                            visible_for_permissions: [{actions: ["GET"], path: "/customer-support/log-as/sso/jwt-link/.*"},{actions: ["PATCH"], path: "/accounts.*"}], 
                            /*visibleIf: (row) => { return row['hasInstance']},*/ disabledIf: !this.systemSsoProviderEnabled
                        },
                        {type: 'warning', icon: 'now-ui-icons business_badge', event: 'item-edit', action: this.t('Edit')},
                        {type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'item-remove', action: this.t('Remove'), confirm: true, visible_for_permissions: [{actions: ["DELETE"], path: "/accounts/.*/managers"}]},
                    ]
                }
            }
        },
        systemSsoProviderEnabled: {
            get(){
                return this.$store.getters[`providers/${this.type}SystemSsoProviderEnabled`];
            }
        }
    },
    methods: {
        handleNew(){
            this.$emit('create', { type: 'managers'})
        },
        handleEdit(data){
            this.$emit('edit', { type: 'managers', id: data.row.manager_id})
        },
        handleRemove(data){
            this.$emit('remove', { type: 'managers', id: data.row.manager_id})
        },
        handleLogAs(data){
            this.$emit('logas', { entity: 'managers', id: data.row.manager_id})
        },
        buttonDisabled(row, event) {
            let res; 
            (!row.hasInstance && (event == 'item-logas' || event == 'item-edit')) ? res = true : res = false;
            return res;
        } 

    }
}
</script>
