<template>
    <b-card no-body>
        <template v-slot:header>
            <slot name="header">
                <h5 class="title"><i class="tim-icons icon-double-right mr-2"/>{{t('IP address(es) configuration')}}</h5>
                <b-container fuild style="max-width: 2560px;" >
                <b-row no-gutters align-h="between">
                    <!-- <b-col md="12" lg="8" xl="9" class="pt-2">
                        Devices are securized by the settings of the user they belong to.
                    </b-col> -->
                    <b-col sm=""></b-col>
                </b-row>
                </b-container>
            </slot>
        </template>

        <b-card-body >

            <form @submit.prevent="">
                <b-container fuild style="width: 100%; padding: 0px;" class="form-group">

                    <b-row align-h="center" >
                        <b-col xl="8" lg="10">
                            <fg-input v-if="!newNsid">
                                <label class="col-form-label">{{t('NetworkSecure ID')}}:</label>
                                <span class="form-control-static">{{ ns_id_saved }}</span>
                            </fg-input>
                            <fg-input v-else 
                                v-model="device.product_data.NetworkSecure.ns_id"
                                id="ns_id"
                                :label="this.t('NetworkSecure ID')"
                                name="NetworkSecureID"
                                :placeholder="this.t('NetworkSecure ID')"
                                v-validate="'required'"
                                :error="getError('NetworkSecureID')">
                            </fg-input>
                        </b-col>
                    </b-row>

                    <b-row align-h="center" >
                        <ip-ranges ref="ipRanges" v-model="ips"/>
                    </b-row>

                    <b-row>
                        <b-col md="4" xs="12" sm="6" class="ml-auto">
                            <n-button v-if="newNsid" native-type="submit" type="primary" id="btn_submit" v-b-modal.modal-setnsid round block :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                {{ t('Save') }}
                            </n-button>
                            <n-button v-else native-type="submit" type="primary" id="btn_submit" round block @click.native="save" :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                                {{ t('Save') }}
                            </n-button>
                        </b-col>
                    </b-row>

                </b-container>
            </form>

        </b-card-body>


        <b-modal id="modal-setnsid" ref="modal" 
            @ok="save()" :title="t('Set NetworkSecure ID')" :ok-title="t('accept')" :cancel-title="t('cancel')">
            {{ t('Are you sure? This action can not be undone') }}
        </b-modal>

    </b-card>
</template>


<script>
import IPRanges from './IPRanges';
import _ from 'lodash';


export default {
    name: 'networksecure-info',
    components: {
        [IPRanges.name] : IPRanges
    },
    props: {
        value: { 
            type: Object, 
            required: true 
        },
    },
    data() {
        return {
            device: {product_data: {NetworkSecure: {}}},
            ns_id_saved: null
        }
    },
    watch: {
        value(newValue) {
            if (newValue){
                this.device = newValue;
                this.device = _.cloneDeep(newValue);
                try{
                    if (this.device.product_data.NetworkSecure.ns_id)
                        this.ns_id_saved = this.device.product_data.NetworkSecure.ns_id
                }
                catch(err){}
            }
        },
    },
    mounted () {
        if (this.value){
            this.device = _.cloneDeep(this.value);
            try{
                if (this.device.product_data.NetworkSecure.ns_id)
                    this.ns_id_saved = this.device.product_data.NetworkSecure.ns_id
            }
            catch(err){}
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save(){
            let isValid = await this.$validator.validateAll() 
                && await this.$refs.ipRanges.$validator.validateAll();
            if (isValid){
                if (!this.newNsid){
                    // avoid patching ns_id, is read only
                    try{
                        if (this.device.product_data.NetworkSecure.ns_id){
                            this.ns_id_saved = this.device.product_data.NetworkSecure.ns_id
                            delete this.device.product_data.NetworkSecure.ns_id
                        }
                    }
                    catch(err){}
                }

                this.$emit('input', this.device)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        }
    },
    computed: {
        newNsid: {
            get(){
                return this.ns_id_saved == null
            }
        },
        ips: {
            get() {
                try{
                    let _ips = this.device.product_data.NetworkSecure.ips
                    if ( _ips)
                        return _ips
                    else
                        return []
                }
                catch(err){
                    return []
                }
            },
            set(newValue){
                this.device.product_data.NetworkSecure.ips = newValue
            }
        }
    }
}
</script>
