<template>
    <b-container fuild style="max-width: 2560px;">
        <b-row>
            <b-col class="row-eq-height">
                <account-title goto :id="account_id" :type='getAccountType' @refresh="refreshAccount"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="row-eq-height" xl="12" :class="{ 'col-xxl-6': user_devices}">
                <user-form v-model="account_user" @input="handleUser" @refresh="refresh" :profiles="profiles" :saveEnabled="saveEnabled" :defaultprofile="defaultprofile" :account_id="account_id">
                    <div slot="header">
                        <h5 class="title"><i class="now-ui-icons users_single-02 mr-2"/>{{t('User Profile')}}</h5>
                    </div>
                </user-form>
            </b-col>

            <b-col v-if="user_devices" xl="12" class="row-eq-height col-xxl-6">
                <account-devices 
                    :account="account"
                    :account_user="account_user"
                    :layout="layout" 
                    @edit="handleEdit" 
                    @remove="handleRemoveEntity"
                >
                    <div slot="header">
                        <h5 class="title"><i class="mr-2"><svgicon class="icon" icon="item-devices" /></i>{{t('Devices')}}</h5>
                    </div>
                </account-devices>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import AccountTitle from '../Accounts/AccountTitle'
import UserForm from './UserForm';
import AccountDevices from '../Devices/AccountDevices'
import _ from "lodash";

export default {
    name: 'account-user-edit',
    components: {
        AccountTitle,
        UserForm,
        AccountDevices
    },
    props: [ "account_id", "id"],
    data() {
        return {
            updating: false,
            newid: null,
            account: null,
            account_user: null,
            user_devices: null,
            saveEnabled:true
        }
    },
    mounted() {
        this.refreshAccount(0);
        this.refresh()
    },
    watch:{
      newid(newValue){
        let _vm = this
            this.$store.dispatch('accountManagement/users/getAccountUser', { account_id: this.account_id, user_id: newValue})
              .then( res => _vm.account_user = res)
              .catch( err => this.$router.replace('/'))

            this.$store.dispatch('accountManagement/devices/getAccountUserDevices', { account_id: this.account_id, user_id: newValue})
              .then( res => _vm.user_devices = res)
              .catch( err => this.$router.replace('/'))

      }
    },
    methods:{
        refreshAccount(wait){
            let _vm = this
            this.updating = true
            this.$store.dispatch('accountManagement/profiles/getProfiles').then()
            if (this.account_id){
                this.$store.dispatch('accountManagement/accounts/getAccount', this.account_id)
                .then( res => {
                    _vm.account = res; 
                    this.updating = false
                })
                .catch( err => {
                    this.updating = false
                    this.$router.replace('/')
                });
            }
            else{
                this.updating = false
                this.$router.replace('/')
            } 
        },
        refresh(wait){
            let _vm = this
            this.updating = true
            if (this.account_id && this.id){
                this.$store.dispatch('accountManagement/users/getAccountUser', { account_id: this.account_id, user_id: this.id})
                .then( res => {
                    _vm.account_user = res; 
                    _vm.$store.dispatch('accountManagement/devices/getAccountUserDevices', { account_id: _vm.account_id, user_id: _vm.id})
                    .then( res => {
                        _vm.user_devices = res
                        this.updating = false
                    })
                    .catch( err => this.$router.replace('/'))
                })
                .catch( err => {
                    this.updating = false
                    this.$router.replace('/')
                });
            }
        },
        handleUser(data) {
            this.saveEnabled = false;
            if(this.realid) {
                this.$store.dispatch('accountManagement/users/updateAccountUser', { account_id: this.account_id, id: this.realid, data: data}).then(() => {
                    this.saveEnabled = true;
                    this.$store.commit("setSuccess", t("User updated successfully"));
                })
            }
            else {
                const _vm = this;
                this.$store.dispatch('accountManagement/users/createAccountUser', {account_id: this.account_id, data: data}).then( res => {
                    _vm.newid = res 
                    this.saveEnabled = true;
                    this.$store.commit("setSuccess", t("User created successfully"));
                });
            }
        },
        handleEdit(data){
            this.$router.push( {path: '/account_management/accounts/edit/' + encodeURIComponent(this.account_id) + "/" + data.type + "/edit/" + encodeURIComponent(data.id)})
        },
        handleRemoveEntity(data){
            let method = null
            let field = null
            if (data.type == 'devices'){
                method = 'accountManagement/devices/removeAccountDeviceUser'
                field = 'user_devices'
            }
            else {
                console.log('handleRemoveEntity: unknown type: ' + data.type)
            }
            this.$store.dispatch(method, {account_id: this.account_id, id: data.id, user_id: this.id})
                .then (res => this.$data[field] = res)
                .catch( err => {})
        },
        deviceIsOriginalIcon(type){
            // const noorig = ['pc', 'mobile']
            // return !noorig.includes(type)
            return false
        },
        getImageembedded(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'embedded')
                return 'data:image/' + imgData.format + ';base64,' + imgData.picture_base64
            else
                return null
        },
        getImageurl(imgData) {
            if ( !imgData)
                return null
            if (imgData.storage_method == 'external')
                return imgData.picture_url
            else
                return null
        },
        getImage(imgData){
            let img = this.getImageembedded(imgData)
            if ( img)
                return img;
            else
                return this.getImageurl(imgData)
        },
        productIconClass (type) {
            switch (type){ 
                case 'NetworkSecure': return 'item-networksecure';
                case 'RouterSecure': return 'item-homesecure';
                default: return 'device-other';
            }
        },
    },
    computed: {
        realid(){
          return this.id || this.newid
        },
        profiles(){
            if ( this.account && this.$store.getters['accountManagement/profiles/profiles'].size > 0)
                return this.$store.getters['accountManagement/profiles/profilesAccount'](this.account)
            else
                return new Map()
        },
        defaultprofile() {
            if ( this.account)
                return this.$store.getters['accountManagement/accounts/accountDefaultProfile'](this.account)
            else
                return ''
        },
        layout(){
            return this.$store.getters["settings/layout"];
        },
        getAccountType() {
            return this.account && this.account.account_type ? this.account.account_type : '';
        }
    }
}
</script>