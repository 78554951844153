<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card no-body>
      <template v-slot:header>
        <b-container fuild style="max-width: 2560px">
          <b-row no-gutters align-h="between">
            <b-col class="pt-2">
              {{ t("Search for a given user by its id or name") }}.
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body style="padding: 0px">
        <paginated-table
          :rows="rows"
          :columns="realColumns"
          :actions="actions"
          :search="[
          { value: 'user_id', key: 'user_id', label: t('user_id') },
          { value: 'name', key: 'name', label: t('name') },
        ]"
          :searchRestrictions="{ user_id: '^[a-zA-Z0-9\-\._@]+$' }"
          v-model="search_field_value"
          :itemsPerPage="query.limit"
          :updating="updating"
          :serverpagination="search_pagination"
          @prev="handlePrevPage"
          @next="handleNextPage"
          @pagination="HandlePagination"
          @item-edit="handleEdit"
          @item-remove="handleRemove"
        />
      </b-card-body>
      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="reload"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("Update now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("Updating...") }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { PaginatedTable } from "@/components";
import {
  AvatarImageFormatter,
  NameWithIconFormatter,
  ProfileFormatter,
} from "@/components/Tables/Formatters";
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";
import { mapState } from "vuex";

import _ from "lodash";

export default {
  name: "users",
  components: {
    PaginatedTable,
    PageTitleCard
  },
  data() {
    return {
      updating: false,
      users: new Map(),
      self_query: {},
      currentPage: 1,
      nextPage: 0,
      query: {
        sort: "asc",
        limit: 10,
      },
      columns: [
        {
          prop: "account_id",
          label: this.t("Account"),
          minWidth: 175,
        },
        {
          prop: "user_id",
          label: this.t("User ID"),
          minWidth: 125,
        },
        {
          prop: "avatar",
          label: "",
          width: 85,
          formatter: AvatarImageFormatter,
        },
        {
          prop: "name",
          label: this.t("Name"),
          minWidth: 185,
        },
        {
          prop: "profile",
          label: this.t("Profile"),
          minWidth: 185,
          formatter: ProfileFormatter,
        },
      ],
      actions: {
        minWidth: 100,
        label: this.t("Actions"),
        fixed: "right",
        items: [
          {
            type: "warning",
            icon: "now-ui-icons business_badge",
            event: "item-edit",
            action: this.t("Edit")
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: true,
            visible_for_permissions: [
              { actions: ["DELETE"], path: "/users/.*" },
            ],
          },
        ],
      },
    };
  },
  async mounted() {
    this.updating = true;
    await this.$store.dispatch("accountManagement/accounts/getAccounts", {
      query: this.query,
    });
    this.$store
      .dispatch("accountManagement/profiles/getProfiles")
      .then((res) => (this.users = new Map(this.users)));
    this.refresh(0);
  },
  computed: {
    ...mapState("accountManagement/accounts", ["accounts"]),
    realColumns: {
      get() {
        if (this.$store.getters['settings/layout'] == "desktop") {
          return this.columns;
        } else if (this.$store.getters['settings/layout'] == "tablet") {
          return [this.columns[0], this.columns[2], this.columns[3]];
        } else {
          return [this.columns[0], this.columns[3]];
        }
      },
    },
    rows() {
      let ret = [];
      let vm = this;
      let account;
      this.users.forEach((value, key) => {
        account = this.accounts.get(value.account_id);
        let item = {
          account_id: value.account_id,
          user_id: value.user_id,
          name: value.name,
          profile: {
            name: this.$store.getters[
                "accountManagement/profiles/userProfileName"
              ](value, this.account),
            deleted: false,
            modified: false,
          },
          avatar: {
            image: this.getImage(value.user_metadata.picture),
            name: value.name,
            icon: value.default_user ? "icon icon-info" : null,
            svgicon: value.default_user ? "star" : null,
            tooltip: null,
          },
        };
        ret.push(item);
      });
      let arRet = this.search_pagination
        ? ret
        : Array.from(ret).sort((a, b) => {
            return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
          });
      return arRet;
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: "user_id", value: this.query.field_value };
        else return { field: "user_id", value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    handleEdit(data) {
      this.$router.push({
        path:
          "/account_management/accounts/edit/" +
          encodeURIComponent(data.row.account_id) +
          "/users/edit/" +
          encodeURIComponent(data.row.user_id),
      });
    },
    handleRemove(data) {
      if (this.users.get(data.row.user_id).default_user) {
        this.$store.commit("setError", this.t("cannot_delete_default_user"));
        return;
      }
      this.$store
        .dispatch("accountManagement/users/removeUser", {
          account_id: data.row.account_id,
          id: data.row.user_id,
        })
        .then((res) => {
          this.users = new Map(res);
          this.reload();
        })
        .catch((err) => {});
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.nextPage = 1;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
    reload() {
      if (this.self_query) {
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("accountManagement/users/getUsers", {
          wait: wait,
          query: this.query,
        })
        .then((res) => {
          _vm.users = new Map(res.items);
          _vm.query = res.query;
          _vm.self_query = res.self;
          this.currentPage += this.nextPage;
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });
    },
    getImageembedded(imgData) {
      if (!imgData) return null;
      if (imgData.storage_method == "embedded")
        return (
          "data:image/" + imgData.format + ";base64," + imgData.picture_base64
        );
      else return null;
    },
    getImageurl(imgData) {
      if (!imgData) return null;
      if (imgData.storage_method == "external") return imgData.picture_url;
      else return null;
    },
    getImage(imgData) {
      let img = this.getImageembedded(imgData);
      if (img) return img;
      else return this.getImageurl(imgData);
    },
  },
};
</script>

