<template>
    <toggle-list
        oneCol
        v-model="vproducts"
        :disabled="toggleDisabled"
        :visible_for_permissions="visible_for_permissions">
        <template v-slot:header>
            <h5 class="title">
                <i class="now-ui-icons business_briefcase-24 mr-2" />{{
                t("Products")
                }}
            </h5>
            <div>{{ t("Select products for account") }}:</div>
        </template>

         <template v-slot:footer v-if="!!rs_operational_mode">
            <div class="mt-1">
                <label class="ml-3">{{t('Router operation mode')}}</label>
                <el-radio-group class="ml-3" :disabled="!permission_granted" v-model="cmp_rs_operational_mode">
                    <template v-for="option in $options.rs_operational_modes" >
                        <el-radio-button :id="'option-' + option" :key="option" :label="option">{{ t(option) }}</el-radio-button>
                    </template>
                </el-radio-group>
            </div>
        </template>
    </toggle-list>
</template>

<script>
import { ToggleList } from '@/components';
import { RadioGroup, Radio } from 'element-ui';
import permissions from '@/mixins/permissions'

export default {
    name: 'products',
    mixins: [permissions],
    components: {
        [ToggleList.name] : ToggleList,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio
    },
    rs_operational_modes: ['security_mode', 'analytics_mode'],
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },
        rs_operational_mode: {
            type: String,
            validator: rs_operational_mode => ['security_mode', 'analytics_mode'].includes(rs_operational_mode)
        },
        toggleDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            products: {},
            operational_mode: null
        }
    },
    watch: {
        value(newValue) {
            if ( !!newValue){
                this.products = newValue;
            }
        },
        rs_operational_mode(newValue){
            this.operational_mode = newValue
        }
    },
    mounted () {
        if ( !!this.value){
            this.products = this.value;
        }
        this.operational_mode = this.rs_operational_mode
    },
    computed:{
        vproducts: {
            get(){
                return !!this.products ? this.products : {}
            },
            set(newValue){
                this.$emit('input', newValue)
            }
        },
        cmp_rs_operational_mode: {
            get() {
                return this.operational_mode
            },
            set(newValue, oldValue){
                if ( !!newValue && newValue !== oldValue){
                    this.$emit('change-operational-mode', newValue)
                }
            }
        }
    }
}
</script>