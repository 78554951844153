<template>
  <b-container fuild style="max-width: 2560px;">
      <b-row>
      <b-col lg="12" class="row-eq-height" :class="{'col-xxl-6': realid}">
          <account-title goto :id="account_id" @refresh="refreshAccount"/>
      </b-col>
      <b-col v-if="realid && canLogAs" lg="12" class="col-xxl-6 row-eq-height">
          <b-card :id="id">
            <div slot="header">
                  <h5 class="title"><i class="mr-1"><svgicon class="icon mr-1 mb-1" icon="rule-actions" width="13" height="13" /></i>{{t('Impersonate')}}</h5>
                  <b-container fluid>
                      <b-row no-gutters>
                          <b-col lg="9" md="8" sm="7">
                              {{t('By impersonating, you can manage the account with the same grants the manager')}} <b>{{ identity.user_id }}</b> {{t('has.')}}
                          </b-col>
                          <b-col>
                              <n-button class="mt-3" style="min-width: 120px; max-width: 20%;float:right;"
                                  id="log-as-account-manager"
                                  type="primary"
                                  size="md" round block
                                  :disabled='!systemSsoProviderEnabled'
                                  :visible_for_permissions='[{actions: ["GET"], path: "/customer-support/log-as/sso/jwt-link/.*"}]'
                                  @click.native="handleLogAs({entity: 'managers', id: realid, type: account_type})">
                                  <i slot="label"><svgicon class="icon mr-1 mb-1" icon="rule-actions" width="13" height="13" /></i>
                                      {{t('Log As')}}
                              </n-button>
                          </b-col>
                      </b-row>
                      <b-row no-gutters>
                          <b-col lg="12" md="12" sm="12" v-if="!systemSsoProviderEnabled">
                              {{t('disableLogAsMessage')}}
                          </b-col>
                      </b-row>
                  </b-container>
            </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col :md="account_manager ? '5' : '12'" class="row-eq-height">
        <identity-card
          v-model="identity"
          v-if="isMounted"
          change-password
          :isOperationsAdmin="isOperationsAdmin"
          :account_type="account_type"
          :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/managers"}, {actions: ["PATCH"], path: "/accounts/.*/managers/.*"}]'
        >
          <div slot="header">
            <h5 class="title">
              <i class="now-ui-icons users_circle-08 mr-2" />
              <template v-if="account_manager">
                {{ t("Authentication") }}
              </template>
              <template v-else>
                {{ t("New Manager") }}
              </template>
            </h5>
          </div>
        </identity-card>
      </b-col>
      <b-col
        md="7"
        v-if="account_manager"
        class="entranceFromRight row-eq-height"
      >
        <edit-profile-form v-model="profile" editable :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/managers"}, {actions: ["PATCH"], path: "/accounts/.*/managers/.*"}]' :isIspAdmin='isIspAdmin'>
          <div slot="header">
            <h5 class="title">
              <i class="now-ui-icons business_badge mr-2" />{{ t("Profile") }}
            </h5>
          </div>
        </edit-profile-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AccountTitle from "../Accounts/AccountTitle";
import EditProfileForm from "./EditProfileForm";
import IdentityCard from "./IdentityCard";
import isIspAccount from '@/util/util';
import { mapState, mapGetters } from "vuex";

export default {
    name: 'account-manager-edit',
    components : {
        AccountTitle,
        EditProfileForm,
        IdentityCard
    },
    environments: {
        'residential': 'res',
        'enterprise_groups': 'ent',
        'business' : 'res'
    },
    props: [ "account_id", "id"],
    data() {
        return {
            updating: false,
            newid: null,
            account: null,
            account_manager: null,
            isMounted: false,
            canLogAs: false,
            saved_account_manager: null
        }
    },
    mounted() {
        this.refreshAccount(0);
        this.refresh()
    },
    watch:{
      newid(newValue){
        let _vm = this
            this.$store.dispatch("accountManagement/managers/getAccountManager", { account_id: this.account_id, manager_id: newValue})
              .then( res => _vm.account_manager = res)
              .catch( err => this.$router.replace('/'))
      },
      account(newValue, oldValue){
        this.getIsSystemSsoProviderEnabled();
      },
      privileges: function(newValue, oldValue){
        if (newValue && newValue.length){
            this.updateManager(this.retry_data)
        }
      },
      account_manager(newValue){
        this.saved_account_manager = newValue
      }
    },
    methods:{
        updateManager(data_to_retry){
            this.$store.dispatch("accountManagement/managers/updateAccountManager", {
                account_id: this.account_id,
                id: this.realid,
                data: data_to_retry})
            .then( res => {
                this.$store.commit('setSuccess', this.t('save_success'), { root: true })
                this.account_manager = _.merge(this.account_manager, data_to_retry)
            })
            .catch (error => {
                if (error.response && error.response.data){
                let data = error.response.data
                if ( data.type.endsWith('NEEDS_AUTHORIZATION')){
                    // open re-authentication dialog, result will be provided by change in privileges
                    this.$store.commit('auth/toggleReauthenticationModal', {
                            toggle: true, 
                            privileges_requested: data.title.split(','),
                            retry_data: data_to_retry
                    });
                }
                else{
                    if (data.type === 'IDENTITY_MANAGER.BAD_REQUEST' && data.title.includes('duplicate key error collection'))
                    {
                        if (data.title.includes('identity.email')){
                            data.title = 'Given email address already exists';
                        }
                        else if (data.title.includes('identity.phone_number')){
                            error.response.data.title = 'Given phone number already exists';
                        }
                        this.$store.commit('setError', error, { root: true });
                    }
                    else{
                        this.$store.commit('setError', this.t(data.type), { root: true })
                    }
                }
                }
                else{
                    this.$store.commit('setError', error, { root: true });
                }
            })
        },
        refreshAccount(wait){
            let _vm = this
            this.updating = true
            if (this.account_id){
                this.$store.dispatch("accountManagement/accounts/getAccount", this.account_id)
                .then( res => {
                    _vm.account = res;
                    this.updating = false
                    this.isMounted = true
                })
                .catch( err => {
                    this.updating = false
                    this.$router.replace('/')
                });
            }
            else{
                this.updating = false
                this.$router.replace('/')
            }
        },
        refresh(wait){
            let _vm = this
            this.updating = true
            if (this.account_id && this.id){
                this.$store.dispatch("accountManagement/managers/getAccountManager", { account_id: this.account_id, manager_id: this.id})
                .then( res => {
                    _vm.account_manager = res;
                    this.updating = false
                })
                .catch( err => {
                    this.updating = false
                    this.$router.replace('/')
                });
            }
        },
        async getSSOProvider(data){
            let env = this.$options.environments[data.type]
            await this.$store.dispatch('providers/getProviderDetails', { type: env, id: 'IdentityProviderSSO' })
            return this.$store.getters[`providers/${env}ProviderDetails`];
        },
        async handleLogAs(data){
            let idPrvSSOConf = await this.getSSOProvider(data)
            try{
                if ( idPrvSSOConf.configuration.log_as_in_frame){
                    let routeData = this.$router.resolve( {name: 'Account Management / Accounts/ Manager / Impersonate',
                                params: { account_id: this.account_id, manager_id: data.id, entity: data.entity, account_type: data.type}});
                    window.open(routeData.href, '_blank');
                }
                else{
                    const _vm = this
                    this.$store.dispatch("accountManagement/managers/getSSOAccountManager", data)
                        .then( res => window.open(res))
                        .catch()
                }
            }catch (err){
                const _vm = this
                this.$store.dispatch("accountManagement/managers/getSSOAccountManager", data)
                    .then( res => window.open(res))
                    .catch()
            }
        },
        getIsSystemSsoProviderEnabled() {
            try {
                let env = this.$options.environments[this.account_type]
                this.$store.dispatch('providers/getIsSystemSsoProviderEnabled', env);
            } catch(e) {
                console.error('Error getting rows data--> ', e)
            }
        }
    },
    computed: {
        ...mapGetters('auth', ['privileges', 'retry_data']),
        realid(){
          return this.id || this.newid
        },
        identity: {
            get() {
              let ret = null
              if(this.account_manager){
                  ret = { blocked: this.account_manager.identity.blocked,
                          identity_instance_type: this.account_manager.identity.identity_instances.identity_instance_type,
                          identity_provider_type: this.account_manager.identity.identity_instances.identity_provider_type,
                          user_id: this.account_manager.identity.identity_instances.user_id || this.account_manager.manager_id,
                          last_ip: this.account_manager.identity.last_ip,
                          last_login: this.account_manager.identity.last_login,
                          last_password_reset: this.account_manager.identity.last_password_reset,
                          IdentityInstanceDatabase: {
                              password: ''
                          },
                          name: this.account_manager.identity.name,
                  }
                  this.canLogAs = this.account_manager.identity.identity_instances.identity_instance_type !== null ? true : false;
              }
              else{
                  ret = { blocked: false,
                          identity_instance_type: 'IdentityInstanceDatabase',
                          identity_provider_type: 'IdentityProviderDatabase',
                          user_id: null,
                          last_ip: null,
                          last_login: null,
                          last_password_reset: null,
                          IdentityInstanceDatabase: {
                              password: ''
                          },
                          name: null
                  }
              }
              return ret
            },
            set(newValue){
                let data = {
                    identity: {
                        blocked: newValue.blocked,
                    },
                }
                if ( !!newValue[newValue.identity_instance_type].password){
                    let password = newValue[newValue.identity_instance_type].password;
                    data.identity.identity_instances = {
                        user_id: newValue.user_id,
                        identity_instance_type: newValue.identity_instance_type,
                        identity_provider_type: newValue.identity_provider_type,
                        password : password
                    }
                }

                if (this.account_manager){
                    this.updateManager(data)
                }
                else{
                    data.identity.identity_instances = [data.identity.identity_instances];

                    let _vm = this
                    this.$store.dispatch("accountManagement/managers/createAccountManager", {account_id: this.account_id, data: data}).then(
                        res => _vm.newid = res,
                        error => {
                            if (error.type && error.type === 'MANAGER.LIMIT_EXCEEDED') {
                                this.$store.commit('setError', this.t('MAX_IDENTITY_MANAGERS_ALLOWED'), {root: true})
                            }
                            else {
                                this.$store.commit('setError', this.t(error.type));
                            }
                        }
                    );
                }
            },
        },
        profile: {
            get() {
              let ret = null
              if(this.account_manager){
                  ret = { created_at : this.account_manager.identity.created_at,
                          user_id: this.account_manager.identity.identity_instances.user_id,
                          email: this.account_manager.identity.email,
                          name: this.account_manager.identity.name,
                          phone_number: this.account_manager.identity.phone_number,
                          updated_at: this.account_manager.identity.updated_at,
                          language_id: this.account_manager.manager_metadata.language_id,
                          time_zone: this.account_manager.manager_metadata.time_zone
                  }
              }
              else{
                  ret = { created_at : null, email: '', name: '', phone_number: null, updated_at: null, user_id: null,
                          language_id: 'en-US', time_zone: 'America/New_York'
                  }
              }
              return ret
            },
            set(newValue){
                let data = {
                    identity: {
                    },
                    manager_metadata: {
                        language_id: newValue.language_id,
                        time_zone: newValue.time_zone
                    }
                }

                if (!!newValue.name)
                    data.identity.name = newValue.name
                else if (!!this.saved_account_manager.identity.name)
                    data.identity.name = ''

                if (!!newValue.phone_number){
                    if (newValue.phone_number !== this.saved_account_manager.identity.phone_number)
                        data.identity.phone_number = newValue.phone_number
                }
                else if (!!this.saved_account_manager.identity.phone_number)
                    data.identity.phone_number = null

                if (!!newValue.email){
                    if (newValue.email !== this.saved_account_manager.identity.email)
                        data.identity.email = newValue.email
                }
                else if (!!this.saved_account_manager.identity.email)
                    data.identity.email = null

                if (this.account_manager){
                    this.updateManager(data)
                }
                else{
                    data.identity.identity_instances = [{ user_id: newValue.user_id, identity_instance_type: 'IdentityInstanceDatabase'}]
                    let _vm = this
                    this.$store.dispatch("accountManagement/managers/createAccountManager", {account_id: this.account_id, data: data}).then( res => _vm.newid = res )
                }
            }
        },
        account_type: {
            get(){
                return !!this.account ? this.account.account_type : null
            }
        },
        systemSsoProviderEnabled() {
            return this.$store.getters[`providers/${this.$options.environments[this.account_type]}SystemSsoProviderEnabled`];
        },
        isOperationsAdmin() {
            return this.$store.getters['auth/userRoles'].length > 0
            && this.$store.getters['auth/userRoles'][0] == 'Operations Administrator';
        },
        isIspAdmin() {
            return isIspAccount(this.account_id);
        }
    }
}

</script>
