<template>
  <b-overlay :show="updating" opacity="0" rounded spinner-variant="primary">
    <template #overlay>
      <b-spinner
        variant="primary"
        class="page-loader-position"
      ></b-spinner>
    </template>
    <b-container v-if="!updating" fuild style="max-width: 2560px">
      <b-row>
        <b-col
          lg="12"
          class="row-eq-height"
          :class="{ 'col-xxl-6': account_managers.size === 0 }"
        >
          <account-title
            :id="id"
            remove
            @remove="handleRemove"
            @refresh="refreshAccount"
            :visible_for_permissions_remove_account="[
          { actions: ['DELETE'], path: '/accounts/.*' },
        ]"
            :visible_for_permissions_go_to_account="[
          { actions: ['POST'], path: '/accounts/.*' },
        ]"
          />
        </b-col>
        <b-col
          v-if="account_managers.size === 0"
          lg="12"
          class="col-xxl-6 row-eq-height"
        >
          <b-card :id="id">
            <div slot="header">
              <h5 class="title">
                <i class="mr-1"
                ><svgicon
                  class="icon mr-1 mb-1"
                  icon="rule-actions"
                  width="13"
                  height="13" /></i
              >{{ t("Impersonate") }}
            </h5>
            <b-container fluid>
              <b-row no-gutters>
                <b-col lg="9" md="8" sm="7">
                  {{
                    t(
                      "By impersonating, you can manage the account with the same grants the manager of"
                    )
                  }}
                  <b>{{ id }}</b> {{ t("account has.") }}
                </b-col>
                <b-col>
                  <n-button
                    class="mt-3"
                    style="min-width: 120px; max-width: 20%; float: right"
                    id="log-as-account"
                    type="primary"
                    size="md"
                    round
                    block
                    :disabled="!systemSsoProviderEnabled"
                    @click.native="
                      handleLogAs({
                        entity: 'accounts',
                        id: id,
                        type: account_type,
                      })
                    "
                    :visible_for_permissions="[
                      {
                        actions: ['GET'],
                        path: '/customer-support/log-as/sso/jwt-link/.*',
                      },
                    ]"
                  >
                    <i slot="label"
                      ><svgicon
                        class="icon mr-1 mb-1"
                        icon="rule-actions"
                        width="13"
                        height="13"
                    /></i>
                    {{ t("Log As") }}
                  </n-button>
                </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col lg="12" md="12" sm="12" v-if="!systemSsoProviderEnabled">
                  {{ t("disableLogAsMessage") }}
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xs="12"
        sm="6"
        :xl="productWidth"
        class="row-eq-height"
        v-if="showProducts"
      >
        <products v-model="products" 
            :rs_operational_mode="rs_operational_mode"
            @change-operational-mode="changeOperationalMode"
            :disabled="toggleDisabled"
            :visible_for_permissions="[ {actions: ['PATCH'], path: '/accounts/.*' }]"
        />
      </b-col>

      <b-col
        xs="12"
        sm="6"
        :xl="serviceWidth"
        class="row-eq-height"
        v-if="showServices"
      >
        <b-card>
          <h5 class="title">
            <i class="now-ui-icons files_box mr-2" />{{ t("Services") }}
          </h5>
          <h5 v-if="services_with_only_rs_analytics" class="danger title">
            {{ t('These settings will have no effect because analytics mode is active')}}
          </h5>

          <div>{{ t("Select services for account") }}:</div>
          <b-container fluid style="padding: 0px">
            <b-row no-gutters>
              <b-col :sm="12" v-for="(value, name) in services" :key="name">
                <b-container fluid style="padding: 0px">
                  <b-row no-gutters>
                    <b-col
                      :cols="8"
                      class="form-group has-label pt-2"
                      align-self="center"
                    >
                      <h5
                        class="mx-auto my-auto"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ value.name }}
                      </h5>
                    </b-col>
                    <b-col cols="2" align-self="center">
                      <n-switch
                        v-model="value.enabled"
                        :disabled="
                          !available_services.includes(name) || toggleDisabled
                        "
                        :service="name"
                        :visible_for_permissions="[
                          { actions: ['PATCH'], path: '/accounts/.*' },
                        ]"
                        @input="toggle($event, name)"
                        color="allot"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <b-col
        md="12"
        lg="12"
        :xl="profileWidth"
        class="row-eq-height"
        v-if="showProfiles"
      >
        <profiles
          v-model="profiles"
          :toggleDisabled="toggleDisabled"
          :oneCol="!manyprofiles"
          :visible_for_permissions="[{ actions: ['POST'], path: '/accounts/.*' }]"
        >
          <div slot="header">
            <h5 class="title">
              <i class="now-ui-icons design_bullet-list-67 mr-2" />{{
                t("Profiles")
              }}
            </h5>
          </div>
        </profiles>
      </b-col>

      <b-col
        md="12"
        lg="12"
        :xl="blockingpagesWidth"
        class="row-eq-height"
        v-if="showBlockingPages"
      >
        <blocking-pages
          v-model="blockingpages"
          ref="validateMe"
          :service="'-'"
          :visible_for_permissions="[{ actions: ['POST'], path: '/accounts/.*' }]"
        />
      </b-col>

      <b-col cols="12" class="row-eq-height">
        <b-card>
          <tabs
            type="primary"
            v-model="active_tab"
            :activeTab="active_tab"
            :vertical="$store.state.settings.mobile"
            centered
          >
            <tab-pane
              id="Managers"
              :label="this.t('Managers')"
            >
              <span slot="label">
                <i class="now-ui-icons users_circle-08"></i>
              </span>
              <account-managers
                :id="this.id"
                :managers="managers"
                :layout="layout"
                :type="type"
                @create="handleCreate"
                @edit="handleEdit"
                @remove="handleRemoveEntity"
                @logas="handleLogAs"
              />
            </tab-pane>

            <tab-pane
              id="Users"
              :label="this.t('Users')"
              v-if="isResidentialType || isBusinessType"
            >
              <span slot="label">
                <i class="now-ui-icons users_single-02"></i>
              </span>
              <account-users
                ref="accountUsers"
                :account="account"
                :layout="layout"
                @create="handleCreate"
                @edit="handleEdit"
                @update-entities="handleUpdateEntities"
              />
            </tab-pane>

            <tab-pane
              id="Groups"
              :label="this.t('Groups')"
              v-if="isEnterpriseGroupType"
            >
              <span slot="label">
                <i><svgicon class="icon" icon="icon-groups" /></i>
              </span>
              <account-groups
                ref="accountGroups"
                :id="this.id"
                :layout="layout"
                @create="handleCreate"
                @edit="handleEdit"
                @remove="handleRemoveEntity"
              />
            </tab-pane>

            <tab-pane
              id="Devices"
              :label="this.t('Devices')"
              v-if="!$store.getters['settings/isIOT']"
            >
              <span slot="label">
                <i><svgicon class="icon" icon="item-devices" /></i>
              </span>
              <account-devices
                ref="accountDevices"
                :account="account"
                :account_users="account_users"
                :layout="layout"
                @create="handleCreate"
                @edit="handleEdit"
                @update-entities="handleUpdateEntities"
                @remove="handleRemoveEntity"
              />
            </tab-pane>
          </tabs>

            <template v-slot:footer>
              <div class="hr-grey mb-2" />
              <div
                @click="refresh"
                v-if="!updating"
                style="cursor: pointer; display: inline"
              >
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t("Update now") }}
              </div>
              <div v-else>
                <i class="now-ui-icons loader_refresh spin" />
                {{ t("Updating...") }}
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { TabPane, Tabs } from "@/components";
import { Switch } from "@/components";
import { mapState, mapGetters, mapActions } from "vuex";
import Profiles from "./Profiles.vue";
import Products from "./Products.vue";
import AccountTitle from "./AccountTitle.vue";
import AccountManagers from "../Managers/AccountManagers.vue";
import AccountUsers from "../Users/AccountUsers.vue";
import AccountGroups from "./AccountGroups.vue";
import AccountDevices from "../Devices/AccountDevices.vue";
import BlockingPages from "@/components/BlockingPages/BlockingPages.vue";

import _ from "lodash";
import { ANALYTICS_MODE } from "./util.js";

export default {
  name: "account-edit",
  components: {
    [Switch.name]: Switch,
    Profiles,
    Products,
    AccountTitle,
    AccountManagers,
    AccountUsers,
    AccountGroups,
    AccountDevices,
    TabPane,
    Tabs,
    BlockingPages,
  },
  environments: {
    residential: "res",
    enterprise_groups: "ent",
    business: "res"
  },
  props: ["id"],
  mounted() {
    this.active_tab = this.$store.getters[
      "accountManagement/accounts/accountActiveTab"
    ];
    this.refreshAccount();    
  },
  data() {
    return {
      available_services: [],
      account: null,
      asm_profiles: null,
      account_managers: new Map(),
      account_users: new Map(),
      account_devices: new Map(),
      active_tab: null,
      updating: false,
      lastChangedProductName: null,
      query_count: 0,
      invalid_servs : ["lan_protection", "router_protection"]
    };
  },
  watch: {
    active_tab(newValue, oldValue) {
      if (newValue != oldValue && oldValue) {
        this.$store.commit(
          "accountManagement/accounts/setAccountActiveTab",
          newValue
        );
        this.refresh();
      }
    },
    account(newValue, oldValue) {      
      if( Object.keys(this.account.products).indexOf("RouterSecure") >=0 && this.account.products["RouterSecure"].provisioned ) this.getAdvancedConfiguration()
      this.getIsSystemSsoProviderEnabled();
    },
  },
  methods: {
    ...mapActions('routerSecure/troubleshooting', ['getAdvancedConfiguration']),
    toggle(event, name) {
      const services = _.cloneDeep(this.services);
      services[name].enabled = event;
      this.services = services;
    },
    updateAvailableServices() {
      this.available_services = [];
      const products = this.account.products;

      // Get all services available of products enabled
      Object.keys(products).forEach((product_name) => {
        const services = this.product_service_dict[products[product_name].product_id];
        if (services) {
          if (products[product_name].provisioned) {
            this.available_services.push(...services);
          }
        }
      });

      // a product has changed it's availability
      if (this.lastChangedProductName) {
        if (products[this.lastChangedProductName].provisioned) {
          // change to 'ON' for those services whose product is enabled
          this.available_services && this.available_services.map(service_name => {
             if(this.services[service_name] && this.account.services_provisioned[service_name]) {
              this.services[service_name].enabled = true;
              this.account.services_provisioned[service_name].provisioned = true
            }
          })
        } else {
         const product_changed = this.lastChangedProductName;
         const services_of_product = this.product_service_dict[products[product_changed].product_id];
         services_of_product && services_of_product.map(service_name => {
           if (!this.available_services.includes(service_name)) {
            // change to 'OFF' for those services whose product is not enabled and not included
            // in other enabled products
            if(this.services[service_name] && this.account.services_provisioned[service_name]) {
              this.services[service_name].enabled = false;
              this.account.services_provisioned[service_name].provisioned = false
            }
           }
         })
        }
      }
    },
    refreshAccount() {
      this.updating = true;
      this.$store
        .dispatch("accountManagement/profiles/getProfiles")
        .then((res) => (this.asm_profiles = res));
      if (this.id) {
        this.$store
          .dispatch("accountManagement/accounts/getAccount", this.id)
          .then((res) => {
            this.account = res;
            this.updating = false;
            this.refresh();
          })
          .catch((err) => {
            this.updating = false;
            this.$router.replace("/");
          });
      } else {
        this.updating = false;
        this.$router.replace("/");
      }
    },
    refresh() {
      this.updateAvailableServices();
      let _vm = this;
      this.updating = true;
      if (this.id) {
        if (this.active_tab == "Managers") {
          this.$store
            .dispatch("accountManagement/managers/getAccountManagers", this.id)
            .then((res) => {
              _vm.account_managers = res;
              this.updating = false;
            })
            .catch((err) => {
              this.updating = false;
              this.$router.replace("/");
            });
        } else if (this.active_tab == "Users") {
          if (this.$refs.accountUsers) {
            this.$refs.accountUsers.self_query = {sort: "asc", limit: 10};
            this.$refs.accountUsers.currentPage = 1;
            this.$refs.accountUsers.reload();
          }
        } else if (this.active_tab == "Groups") {
          this.$refs.accountGroups.self_query = {sort: "asc", limit: 10, include_totals: true};
          this.$refs.accountGroups.currentPage = 1;
          this.$refs.accountGroups.reload();
          this.updating = false;
        } else {
          if (this.$refs.accountDevices) {
            this.$refs.accountDevices.self_query = {sort: "asc", limit: 10};
            this.$refs.accountDevices.currentPage = 1;
            this.$refs.accountDevices.reload();
          }
        }
      } else {
        this.updating = false;
        this.$router.replace("/");
      }
      this.updating = false;
    },
    handleRemove(id) {
      if (id == this.id) {
        this.$store
          .dispatch("accountManagement/accounts/removeAccount", id)
          .then((res) => this.$router.replace("/accounts"));
      }
    },
    handleCreate(data) {
      if (data.type == 'devices' && this.query_count >= 2000000) {
        this.$store.dispatch('setError', this.t('max_device_limit_account'), { root: true });
      } else {
        this.$router.push({
          path:
            "/account_management/accounts/edit/" +
            encodeURIComponent(this.id) +
            "/" +
            data.type +
            "/new",
        });
      }
    },
    handleEdit(data) {
      this.$router.push({
        path:
          "/account_management/accounts/edit/" +
          encodeURIComponent(this.id) +
          "/" +
          data.type +
          "/edit/" +
          encodeURIComponent(data.id),
      });
    },
    handleUpdateEntities(data) {
      if (data.type === 'users') {
        this.account_users = data.values;
      } else if (data.type === 'devices') {
        this.account_devices = data.values
      }
    },
    handleRemoveEntity(data) {
      let method = null;
      let field = null;
      if (data.type == "managers") {
        if (this.account_managers.size <= 1) {
          this.$store.commit("setError", this.t("cannot_delete_last_manager"));
          return;
        }
        method = "accountManagement/managers/removeAccountManager";
        field = "account_managers";
      } else {
        console.log("handleRemoveEntity: unknown type: " + data.type);
      }
      this.$store
        .dispatch(method, { account_id: this.id, id: data.id })
        .then((res) => (this.$data[field] = res))
        .catch((err) => {});
    },
    async getSSOProvider(data) {
      let env = this.$options.environments[data.type];
      await this.$store.dispatch("providers/getProviderDetails", {
        type: env,
        id: "IdentityProviderSSO",
      });
      return this.$store.getters[`providers/${env}ProviderDetails`];
    },
    async handleLogAs(data) {
      if (!data.type) data.type = this.account_type;

      let idPrvSSOConf = await this.getSSOProvider(data);
      try {
        if (idPrvSSOConf.configuration.log_as_in_frame) {
          let routeData = null;
          if (data.entity == "accounts") {
            routeData = this.$router.resolve({
              name: "Account Management / Accounts / Impersonate",
              params: {
                account_id: data.id,
                entity: data.entity,
                account_type: data.type,
              },
            });
          } else {
            routeData = this.$router.resolve({
              name: "Account Management / Accounts/ Manager / Impersonate",
              params: {
                account_id: this.id,
                manager_id: data.id,
                entity: data.entity,
                account_type: data.type,
              },
            });
          }
          window.open(routeData.href, "_blank");
        } else {
          const _vm = this;
          this.$store
            .dispatch("accountManagement/managers/getSSOAccountManager", data)
            .then((res) => window.open(res))
            .catch();
        }
      } catch (err) {
        const _vm = this;
        this.$store
          .dispatch("accountManagement/managers/getSSOAccountManager", data)
          .then((res) => window.open(res))
          .catch();
      }
    },
    getIsSystemSsoProviderEnabled() {
      try {
        let env = this.$options.environments[this.account_type];
        this.$store.dispatch("providers/getIsSystemSsoProviderEnabled", env);
      } catch (e) {
        console.error("Error getting rows data--> ", e);
      }
    },
    showRSWarningMessage(products_value) {
        this.$bvModal.msgBoxConfirm(this.t('remove the product?'), {
                okTitle: this.$t('accept'),
                cancelTitle: this.$t('cancel')
            })
        .then(confirmed => {
            if(confirmed) {
              this.updateProductsAndServices(products_value)
            } else
              this.handleCancel()
        })
        .catch(() => {
        })
    },
    updateProductsAndServices(products_value) {
      this.updating = true;
      const updateData = {
         account_id: this.id,
         products: products_value,
      };
      _.merge(this.account.products, updateData.products)
      this.updateAvailableServices();
      updateData.services_provisioned = this.account.services_provisioned
      this.$store
        .dispatch("accountManagement/accounts/updateAccount", {
          id: this.id,
          data: updateData,
        })
        .then(() => {
          this.refreshAccount();
        })
        .catch((err) => {
          this.refreshAccount();
        });
      this.updating = false;
    },
    handleCancel() {
      this.refreshAccount()
    },
    changeOperationalMode(event){
      if ( !!event && event !== this.rs_operational_mode) {
        const update = {
          account_id: this.id,
          products: {
            RouterSecure : {
              account_operational_mode: event
            }
          }
        };
        this.$store
          .dispatch("accountManagement/accounts/updateAccount", {
            id: this.id,
            data: update,
          })
          .then((res) => {
            _.merge(this.account, update)
          })
          .catch((err) => {
            this.refreshAccount();
          });
      }      
    }
  },
  computed: {    
    ...mapState("accountManagement/accounts", ["toggleDisabled"]),
    ...mapGetters('routerSecure/troubleshooting', ['advancedConfigValue']),
    product_service_dict() {      
      let srvs = this.$store.getters["settings/getProductServiceDict"];
      if( _.get(srvs, "RouterSecure") )
         srvs.RouterSecure = srvs.RouterSecure.filter(item => !this.invalid_servs.includes(item) )                
      return srvs      
    },
    layout() {
      return this.$store.getters["settings/layout"];
    },
    isResidentialType() {
      return this.account && this.account.account_type === "residential";
    },
    isEnterpriseGroupType() {
      return this.account && this.account.account_type === "enterprise_groups";
    },
    isBusinessType() {
      return this.account && this.account.account_type === "business";
    },
    showProducts() {
      return !!Object.keys(this.products).length;
    },
    showServices() {
      return !!Object.keys(this.services).length;
    },
    showProfiles() {
      return this.isResidentialType || this.isBusinessType;
    },
    showBlockingPages() {
      return (this.availableServices.includes("parental_control") ||  this.availableServices.includes("threat_protection") )
    },
    productWidth() {
      if (!this.showServices && !this.showProfiles && !this.showBlockingPages)
        return 12;
      if (this.showServices && this.showProfiles && this.showBlockingPages)
        return 6;

      if (this.showServices && this.showProfiles)
        if (this.manyprofiles) return 3;
        else return 4;

      if (this.showServices && this.showBlockingPages) return 3;

      if (this.showProfiles && this.showBlockingPages)
        if (this.manyprofiles) return 6;
        else return 3;

      if (this.showServices || this.showProfiles) return 6;

      if (this.showBlockingPages) return 4;
    },
    serviceWidth() {
      if (!this.showProducts && !this.showProfiles && !this.showBlockingPages)
        return 12;
      if (this.showProducts && this.showProfiles && this.showBlockingPages)
        return 6;

      if (this.showProducts && this.showProfiles)
        if (this.manyprofiles) return 3;
        else return 4;

      if (this.showProducts && this.showBlockingPages) return 3;

      if (this.showProfiles && this.showBlockingPages)
        if (this.manyprofiles) return 6;
        else return 3;

      if (this.showProducts || this.showProfiles) return 6;

      if (this.showBlockingPages) return 4;
    },
    profileWidth() {
      if (!this.showProducts && !this.showServices && !this.showBlockingPages)
        return 12;
      if (this.showProducts && this.showServices && this.showBlockingPages)
        return 6;

      if (this.showProducts && this.showServices)
        if (this.manyprofiles) return 6;
        else return 4;

      if (this.showProducts && this.showBlockingPages)
        if (this.manyprofiles) return 6;
        else return 4;

      if (this.showServices && this.showBlockingPages)
        if (this.manyprofiles) return 6;
        else return 4;

      if (this.showProducts || this.showBlockingPages || this.showServices)
        return 6;
    },
    blockingpagesWidth() {
      if (!this.showProducts && !this.showServices && !this.showProfiles)
        return 12;
      if (this.showProducts && this.showServices && this.showProfiles) return 6;

      if (this.showProducts && this.showServices) return 6;

      if (this.showProducts && this.showProfiles)
        if (this.manyprofiles) return 12;
        else return 5;

      if (this.showServices && this.showProfiles)
        if (this.manyprofiles) return 12;
        else return 5;

      if (this.showProducts || this.showServices) return 8;

      if (this.showProfiles) return 6;
    },
    profiles: {
      get() {
        let ret = {};
        if (this.account && this.account.account_type === "enterprise_groups") {
          return {};
        }
        try {
          //if ( this.account && this.asm_profiles){
          this.asm_profiles.forEach((profile) => {
            let enabled = false;
            if (
              this.account.profiles.profiles_definition.includes(
                profile.profile_definition_id
              )
            )
              enabled = true;
            let name = profile.profile_definition_id;
            let description = null;
            profile.profile_i18n.forEach((lang) => {
              let lng = lang.language_id.includes("-")
                ? lang.language_id.slice(0, lang.language_id.indexOf("-"))
                : lang.language_id;
              if (lng == this.$store.getters["settings/getLanguage"]) {
                name = lang.profile_name;
                description = lang.profile_description;
              }
            });
            ret[profile.profile_definition_id] = {
              name: name,
              description: description,
              enabled: enabled,
            };
          });
          return {
            default: this.account.profiles.profile_id_default,
            profiles: ret,
          };
        } catch (err) {
          return {};
        }
      },
      set(newValue) {
        let update = {
          account_id: this.id,
          profiles: {
            profile_id_default: newValue.default,
            profiles_definition: [],
          },
        };
        Object.keys(newValue.profiles).forEach((key) => {
          if (newValue.profiles[key].enabled)
            update.profiles.profiles_definition.push(key);
        });
        this.account.profiles = update.profiles;
        this.$store
          .dispatch("accountManagement/accounts/updateAccount", {
            id: this.id,
            data: update,
          })
          .then()
          .catch((err) => {
            this.refreshAccount();
          });
      },
    },
    manyprofiles: {
      get() {
        if (!this.profiles.profiles) return false;

        return Object.keys(this.profiles.profiles).length > 3;
      },
    },
    availableProducts() {
      return this.$store.getters["settings/getProducts"];
    },
    availableServices() {
      let srvs = this.$store.getters["settings/getServices"];
      srvs = srvs.filter(item => !this.invalid_servs.includes(item) )       
      return srvs;
    },
    blockingpages: {
      get() {
        if (this.account && this.account.service_configuration)
          return this.account.service_configuration;
        else return {};
      },
      async set(newValue) {
        if (_.isEqual(newValue, this.blockingpages) === false) {
            const update = {
              account_id: this.id,
              service_configuration: newValue,
            };
            this.$store
              .dispatch("accountManagement/accounts/updateAccount", {
                id: this.id,
                data: update,
              })
              .then((res) => {})
              .catch((err) => {
                this.refreshAccount();
              });
        }
      },
    },
    rs_operational_mode: {
        get() {
            if (_.get(this.account, "products.RouterSecure.provisioned", false)){
              let default_rs_operational_mode = this.advancedConfigValue("default_account_operational_mode")
              return _.get(this.account, "products.RouterSecure.account_operational_mode", default_rs_operational_mode)
            }
            else
              return null
        }
    },
    services_with_only_rs_analytics: {
      get() {
        // check RouterSecure product is installed
        if ( !!!this.product_service_dict.RouterSecure || this.product_service_dict.RouterSecure.length === 0)
          return false
        // check RouterSecure product is enabled for account
        if ( !!!this.products.RouterSecure || !!!this.products.RouterSecure.enabled)
          return false
        // check RouterSecure analytics mode is selected
        if (this.rs_operational_mode !== ANALYTICS_MODE)
          return false

        // search for RS services which are not provided for any other product available for account
        let analytics_services = this.product_service_dict.RouterSecure.reduce( (acc, service) => {
          if ( !!!this.services[service] || !!!this.services[service].enabled)
            return acc

          let service_by_products = Object.entries(this.product_service_dict)
                .filter( item => item[0] != 'RouterSecure')
                .reduce( (acc_service, product) => {
                  if (!!!this.products[product[0]] || !!!this.products[product[0]].enabled )
                    return acc_service
                  return acc_service + (product[1].includes(service) ? 1 : 0)
                }, 0)
          return acc + (service_by_products > 0 ? 0 : 1)
        }, 0)

        return analytics_services > 0
      }
    },
    products: {
      get() {
        const products = {};
        if (this.account && this.account.products) {
          this.availableProducts.forEach(productName => {
            if (productName in this.account.products) {
              products[productName] = {
                enabled: this.account.products[productName].provisioned,
              };
            } else {
              products[productName] = { enabled: false };
            }
          });
        };
        return products;
      },
      async set(newValue) {
        const update = {
          account_id: this.id,
          products: {},
        };
        Object.keys(newValue).forEach((key) => {
          update.products[key] = {
            product_id: key,
            provisioned: newValue[key].enabled,
          };
          const productProvisioningChanged = newValue[key] && this.account.products[key] &&
            newValue[key].enabled !== this.account.products[key].provisioned;
          const newProductProvisioningEnabled = newValue[key] && newValue[key].enabled && !this.account.products[key];
          if (productProvisioningChanged || newProductProvisioningEnabled) {
            this.lastChangedProductName = key;
          }
        });
        if ((newValue[this.lastChangedProductName]
          && newValue[this.lastChangedProductName].enabled)
        ) {
          _.merge(this.account.products, update.products)
          this.updateAvailableServices();
          update.services_provisioned = this.account.services_provisioned
          this.$store
            .dispatch("accountManagement/accounts/updateAccount", {
              id: this.id,
              data: update,
            })
            .then()
            .catch((err) => {
              this.refreshAccount();
            });
        } else if (newValue[this.lastChangedProductName]
          && !newValue[this.lastChangedProductName].enabled)
        {
          this.showRSWarningMessage(update.products)
        }
      },
    },
    services: {
      get() {
        const services = {};
        if (this.account) {
          Object.keys(this.account.services_provisioned).forEach((key) => {
            if (this.availableServices.includes(key)) {
              services[key] = {
                name: this.t(key),
                description: this.t(key + "_description"),
                enabled: this.account.services_provisioned[key].provisioned,
              };
            }
          });
        }
        return services;
      },
      set(newValue) {
        const update = {
          account_id: this.id,
          services_provisioned: {},
        };
        Object.keys(newValue).forEach((key) => {
          update.services_provisioned[key] = {
            provisioned: newValue[key].enabled,
          };
        });
        this.account.services_provisioned = update.services_provisioned;
        this.$store
          .dispatch("accountManagement/accounts/updateAccount", {
            id: this.id,
            data: update,
          })
          .then()
          .catch((err) => {
            this.refreshAccount();
          });
      },
    },
    managers: {
      get() {
        let ret = [];
        let vm = this;
        this.account_managers.forEach((value, key) => {
          let item = {
            manager_id: value.manager_id,
            name: value.identity.name,
            user_id:
              value.identity.identity_instances.user_id || value.manager_id,
            hasInstance: value.identity.identity_instances
              .identity_instance_type
              ? true
              : false,
            created_at: this.toLocaleString(
              this.datetimeFromISO(value.identity.created_at)
            ),
          };
          ret.push(item);
        });
        let arRet = Array.from(ret).sort((a, b) => {
          return a.user_id.toUpperCase() > b.user_id.toUpperCase() ? 1 : -1;
        });
        return arRet;
      },
    },
    account_type: {
      get() {
        return !!this.account ? this.account.account_type : null;
      },
    },
    type() {
      return this.$options.environments[this.$route.query.type];
    },
    systemSsoProviderEnabled() {
      return this.$store.getters[
        `providers/${
          this.$options.environments[this.account_type]
        }SystemSsoProviderEnabled`
      ];
    },
  },
};
</script>
