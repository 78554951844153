

  export const getDeviceInfo = (type) =>  {

    const deviceInfo = {
      'health_monitor':  { 'icon':  'healthicons:blood-pressure-outline', 'labelName': 'Health Monitor'},
      'ipcam':  { 'icon':  'fluent:camera-dome-20-regular', 'labelName': 'IP Camera'},
      'ip_phone':  { 'icon':  'carbon:phone-ip', 'labelName': 'IP Phone'},
      'sensor':  { 'icon':  'ic:sharp-sensors', 'labelName': 'Sensor'},
      'weather':  { 'icon':  'fluent:weather-partly-cloudy-day-20-regular', 'labelName': 'Weather'},
      'audio':  { 'icon':  'oi:audio-spectrum', 'labelName': 'Audio'},
      'av':  { 'icon':  'mdi:theater', 'labelName': 'AV'},
      'mediacenter':  { 'icon':  'fluent:device-meeting-room-remote-16-regular', 'labelName': 'Media Center'},
      'media_player':  { 'icon':  'mdi:disc-player', 'labelName': 'Media Player'},
      'mic':  { 'icon':  'mdi:microphone', 'labelName': 'Microphone'},
      'radio':  { 'icon':  'mdi:radio', 'labelName': 'Radio'},
      'speaker':  { 'icon':  'fluent:speaker-1-28-regular', 'labelName': 'Speaker'},
      'streaming':  { 'icon':  'fluent:video-clip-20-filled', 'labelName': 'Streaming Audio'},
      'ebook_reader':  { 'icon':  'fluent:immersive-reader-24-regular', 'labelName': 'Ebook Reader'},
      'laptop':  { 'icon':  'ic:baseline-laptop-mac', 'labelName': 'Laptop'},
      'console':  { 'icon':  'la:gamepad', 'labelName': 'Game Console'},
      'pc':  { 'icon':  'mono-icons:computer', 'labelName': 'PC'},
      'printer':  { 'icon':  'ph:printer', 'labelName': 'Printer'},
      'projector':  { 'icon':  'ph:projector-screen', 'labelName': 'Projector'},
      'mobile':  { 'icon':  'zmdi:smartphone-iphone', 'labelName': 'Smartphone'},
      'watch':  { 'icon':  'mdi:watch', 'labelName': 'Smart watch'},
      'tablet':  { 'icon':  'mdi:tablet-android', 'labelName': 'Tablet'},
      'television':  { 'icon':  'zmdi:tv', 'labelName': 'Television'},
      'alarm':  { 'icon':  'ic:outline-alarm-on', 'labelName': 'Alarm'},
      'baby_monitor':  { 'icon':  'mdi:baby-face-outline', 'labelName': 'Baby Monitor'},
      'doorbell':  { 'icon':  'mdi:doorbell', 'labelName': 'Doorbell'},
      'electric':  { 'icon':  'icons8:electrical', 'labelName': 'Electric'},
      'fire_alarm':  { 'icon':  'ant-design:fire-outlined', 'labelName': 'Fire Alarm'},
      'light_bulb':  { 'icon':  'teenyicons:bulb-off-outline', 'labelName': 'Light Bulb'},
      'scale':  { 'icon':  'ion:scale-sharp', 'labelName': 'Scale'},
      'scanner':  { 'icon':  'ic:round-scanner', 'labelName': 'Scanner'},
      'sleep_tech':  { 'icon':  'mdi:chat-sleep-outline', 'labelName': 'Sleep Tech'},
      'smart_appliance':  { 'icon':  'ic:outline-widgets', 'labelName': 'Smart Appliance'},
      'smart_cleaner':  { 'icon':  'mdi:robot-vacuum', 'labelName': 'Smart Cleaner'},
      'smart_fridge':  { 'icon':  'mdi:fridge-outline', 'labelName': 'Smart Fridge'},
      'smart_lock':  { 'icon':  'fluent:lock-closed-24-regular', 'labelName': 'Smart Lock'},
      'smart_washer':  { 'icon':  'mdi:dishwasher', 'labelName': 'Smart Washer'},
      'sprinkler':  { 'icon':  'mdi:sprinkler-variant', 'labelName': 'Sprinkler'},
      'touch_panel':  { 'icon':  'fluent:panel-bottom-contract-20-filled', 'labelName': 'Touch Panel'},
      'raspberry':  { 'icon':  'la:raspberry-pi', 'labelName': 'Raspberry Pi'},
      'toy':  { 'icon':  'whh:teddybear', 'labelName': 'Toy'},
      'cable_box':  { 'icon':  'iconoir:cable-rounded', 'labelName': 'Cable Box'},
      'circuit_board':  { 'icon':  'mdi:raspberry-pi', 'labelName': 'Circuit Board'},
      'controller':  { 'icon':  'teenyicons:game-controller-retro-outline', 'labelName': 'Controller'},
      'gateway':  { 'icon':  'fluent:router-24-regular', 'labelName': 'Gateway'},
      'modem':  { 'icon':  'whh:routeralt', 'labelName': 'Modem'},
      'nas':  { 'icon':  'mdi:nas', 'labelName': 'NAS'},
      'network':  { 'icon':  'iconoir:network-alt', 'labelName': 'Network'},
      'router':  { 'icon':  'tabler:router', 'labelName': 'Router'},
      'switch':  { 'icon':  'clarity:network-switch-line', 'labelName': 'Switch'},
      'remote':  { 'icon':  'mdi:remote-tv', 'labelName': 'Remote'},
      'robot':  { 'icon':  'mdi:robot-excited-outline', 'labelName': 'Robot'},
      'other':  { 'icon':  'tabler:devices', 'labelName': 'Other'},      
      'pos':  { 'icon': 'fontisto:shopping-pos-machine', 'labelName': 'Point of Sale'},
      'mng_router':  { 'icon':  'mdi:router-wireless', 'labelName': 'Manageable Router'}
    }
    
    return (deviceInfo[type] ? deviceInfo[type] : deviceInfo['other'])
}

export const  getProductId = (value, product)  => {
  let name = ''
  switch (product) {
    case 'NetworkSecure':
      name = value.product_data[product].ns_id
    break;
    case 'DNSecure':
      name = value.product_data[product].subscriber_info ? value.product_data[product].subscriber_info.subscriber_id : ''
    break;
    case 'RouterSecure':      
        const configuration = value.product_data[product] && value.product_data[product].data  && value.product_data[product].data.configuration
        const identifiers = configuration && configuration.identifiers   
        if (identifiers && identifiers.length > 0)
          name =  getRouterSecureIdentifiers(identifiers)        
    break;
    default:
      name = ''
    break;
  }
  return name
}


export const getRouterSecureIdentifiers = (identifiers) => {
  let mac = []
  let serial = []
identifiers.map(element => {
  if (element.type === 'mac_address') {
    mac.push(element.value)
  } else {
    if(element.type === 'serial_number' && element.value !== "") {
      serial.push(element.value)
    }
  }
 
})
return ({'mac': mac, 'serial':  serial } 
)
}

