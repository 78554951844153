<template>
    <b-container fuild style="width: 100%;" class="form-group">

        <b-row no-gutters align-h="between">
            <b-col md="8" lg="8" xl="8" class="pt-2">
                <slot name="header">
                    {{t('ipRangeTitle')}}.
                </slot>
            </b-col>
            <b-col sm=""></b-col>
            <b-col md="3" xs="12" sm="5" class="ml-auto mr-2">
                <n-button @click.native="handleNew"
                        type="primary"
                        size="md" round block
                        :visible_for_permissions='[{actions: ["POST"], path: "/accounts/.*/devices"}]'>
                    <i slot="label"><svgicon class="icon" icon="icon-new" /></i>
                    {{t('New range')}}
                </n-button>
            </b-col>
        </b-row>

        <b-row align-h="center" v-for="(range, index) in ranges" :key="index">
            <b-col :md="isIPv6[index] ? 9: 4">
                <fg-input v-model="range.ip_begin" @input="debounceInput"
                            :id="'ip_begin_' + index"
                            :label="t('Initial IPv4 Address / IPv6 Range')"
                            labelClasses="smalllabel"
                            :name="getName('Initial IP Address / IPv6 Range', index)"
                            :placeholder="t('e.g. 10.0.0.0 or 2001:db8:a::/64')"
                            v-validate="{ beginIPNotDuplicate: true, required: true, regex: $options.ipv4v6regex}"
                            :error="getError(getName('Initial IP Address / IPv6 Range', index))">
                </fg-input>
            </b-col>
            <b-col v-if="!isIPv6[index]" cols="1" class="mt-4">
                -
            </b-col>
            <b-col v-if="!isIPv6[index]"  md="4">
                <fg-input v-model="range.ip_end" @input="debounceInput"
                            :id="'ip_end_' + index"
                            :label="t('Ending IPv4 Address')"
                            labelClasses="smalllabel"
                            :name="getName('Ending IPv4 Address', index)"
                            :placeholder="t('e.g. 10.0.0.255')"
                            v-validate="{ endIPNotDuplicate: true, required: true, regex: $options.ipv4regex}"
                            :error="getError(getName('Ending IPv4 Address', index))">
                </fg-input>
            </b-col>
            <b-col class="mt-3">
                <n-button type="primary" round icon @click.native="handleRemove(index)" :visible_for_permissions='[{actions: ["PATCH"], path: "/accounts/.*/devices/.*"}]'>
                    <i class="isp-icon bin icon-danger" style="font-size: 1.2rem;"></i>
                </n-button>
            </b-col>
        </b-row>
        <b-modal id="modal-remove-ip"
            ref="modal-remove-ip"
            :title="t('warning')"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            @ok="removeRange.ok"
            @cancel="removeRange.cancel"
            :ok-title="t('accept')" :cancel-title="t('cancel')"
        >
            {{removeRange.message}}
        </b-modal>
    </b-container>
</template>

<script>
import _ from 'lodash';
import { ipv4RegExp, ipv6RegExp, ipv4v6RegExp }  from "@/util/regexConsts";

export default {
    name: 'ip-ranges',
    props: {
        value: {
            type: Array,
            required: true
        },
    },
    ipv4regex: ipv4RegExp,
    ipv6regex: ipv6RegExp,
    ipv4v6regex: ipv4v6RegExp,
    data() {
        return {
            ranges: [],
            isIPv6: [],
            removeRange: {
                index: undefined,
                ok: () => {},
                message: '',
                cancel: () => {}
            }
        }
    },
    watch: {
        value(newValue) {
            if ( newValue){
                this.ranges = newValue
            }
        },
        ranges:{
            deep: true,
            handler(){
                let ret = []
                let _vm = this
                this.ranges.forEach( (item) => {
                    ret.push( item && _vm.$options.ipv6regex.test(item.ip_begin))
                })
                this.isIPv6 = ret
            }
        }
    },
    mounted () {
        if ( !!this.value){
            this.ranges = this.value
        }

        this.$validator.extend(
            'beginIPNotDuplicate',{
                getMessage: field => {
                    return this.$t('Duplicate IP')
                },
                validate: (value) => {
                    return !!!value || this.arrayBeginIps.indexOf(value) === this.arrayBeginIps.lastIndexOf(value)
                }
        });       
        this.$validator.extend(
            'endIPNotDuplicate',{
                getMessage: field => {
                    return this.$t('Duplicate IP')
                },
                validate: (value) => {
                    return !!!value || this.arrayEndIps.indexOf(value) === this.arrayEndIps.lastIndexOf(value)
                }
        });            
        
    },
    computed: {
        arrayBeginIps() {
            return this.ranges.map(item => item.ip_begin)
        },
        arrayEndIps() {
            return this.ranges.map(item => item.ip_end)
        }
    },
    methods: {
        getName(name, index){
            let idx = index+1
            return name + ' ' + idx
        },
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        handleNew(){
            this.ranges.push( { ip_begin:'', ip_end: ''})
        },
        handleRemove(index){
            this.removeRange = {
                index: index,
                message: this.t('are_you_sure_to_delete_device_ip'),
                ok: this.emitRemove,
                cancel: this.resetRemoveIp
            };
            this.$refs['modal-remove-ip'].show();
        },
        emitRemove() {
            this.ranges.splice(this.removeRange.index ,1)
            this.$emit('input', this.ranges)
        },
        resetRemoveIp() {
            this.removeRange = {
                index: undefined,
                ok: () => {},
                message: '',
                cancel: () => {}
            };
        },
        debounceInput: _.debounce(function (e) {
            this.$validator.validateAll()
            .then( isValid => {
                if (isValid){
                    this.$emit('input', this.ranges)
                    setTimeout(() => {
                        this.$validator.reset();
                    }, 100)
                }
            })
            .catch( err => {
                console.error('Validation error-> ', err)
            })
            let _vm = this
            this.$nextTick( () => {
                this.isIPv6.forEach( (item, index) => {
                    if ( item){
                        _vm.ranges[index].ip_end = ''
                    }
                });
            })
        }, 500),
        async handleInput(){
            let isValid = await this.$validator.validateAll();
            if (isValid){
                this.$emit('input', this.ranges)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        }
    }
}

</script>

